import "../../../node_modules/react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect, useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import "./Collapsible.css";
import Modal from "react-modal";
import arrow from "../../Assets/Images/arrow.svg";
import edit from "../../Assets/Images/document.svg";
import arrowUp from "../../Assets/Images/arrowUp.svg";
import Botton from "../Button/Button";
import { shreeRamProposalAction } from "../../redux/Actions/shreeRamApiAction";
import { shreeRamProposalReducer } from "../../redux/Reducers/shreeRamApiReducer";
import { digitCreateQuoteAction } from "../../redux/Actions/digitApiAction";
import { digitCreateQuoteReducer } from "../../redux/Reducers/digitApiReducer";
import { zunoFullQuoteAction, zunoInsertBodydAction } from "../../redux/Actions/zunoApiAction";
import { transactionURLAction } from "../../redux/Actions/TransactionAction";
import { ackopdfDownloadAction } from "../../redux/Actions/ackoApiAction";
import { resethdfcProposalAction } from "../../redux/Reducers/hdfcApiReducer";
import { resethdfcChecksum } from "../../redux/Reducers/hdfcApiReducer";
import {
  iciciProposalAction,
  iciciCreateTreansactionAction,
} from "../../redux/Actions/iciciApiAction";
import {
  ackoProposalAction,
  getVehicleCashbackApiAction
} from "../../redux/Actions/ackoApiAction";
import { zunoFullQuoteReducer } from "../../redux/Reducers/zunoApiReducer";
import {
  generateUnique16DigitNumber,
  generateCutomerId,
  generateCheckSum
} from "../../utils/commonUtils";
import Razorpayment from "../Payment/Razorpay";
import useRazorpay from "react-razorpay";
import { razorCreateOrderAction, razorAckoCreateOrderAction } from "../../redux/Actions/razorCreateOrderApiAction";
import {
  paymentWebhookAction,
  paymentSendURLAction,
} from "../../redux/Actions/paymentWebhookAction";
import {
  sbiPolicyGenerateAction,
  sbiPdfDownloadAction,
  sbiFullQuoteAction,
  sbiKyc,
  sbiKycAuto,
} from "../../redux/Actions/sbiApiAction";
import {
  tataAutoKycReducer,
  tataProposalReducer,
  tataManualKycReducer,
  tataOnlinePaymentReducer
} from "../../redux/Reducers/tataApiReducer";
import {
  tataAutoKycAction,
  tataManualKycAction,
  tataProposalAction,
  tataOnlinePaymentAction
} from "../../redux/Actions/tataApiAction";
import {
  hdfcProposalReducer,
  hdfcChecksumReducer,
  hdfcProposalDocumentReducer
} from "../../redux/Reducers/hdfcApiReducer";
import {
  hdfcProposalAction,
  hdfcChecksumAction,
  hdfcProposalDocument
} from "../../redux/Actions/hdfcApiAction";
// united
import {
  unitedQuickQuoteAction,
  unitedProposalAction
} from "../../redux/Actions/unitedApiAction";
import {
  unitedQuickQuoteReducer,
  unitedProposalReducer
} from "../../redux/Reducers/unitedApiReducer";
// national
import {
  nationalFullQuoteAction
} from "../../redux/Actions/nationalApiAction";
import {
  nationalProposalReducer
} from "../../redux/Reducers/nationalApiReducer";
import Loader from "../../components/Pages/Loader/Loader";
import loader2 from "../../Assets/Loader/Loader.gif";
import "../RegNumberForm/loaderMain.css";
import sbiImage from "../../Assets/Partners_logo/SBI.png";
import BMILogo from "../../Assets/Partners_logo/BOOKMYINSURANCE/acko.jpg";
import { allBodyDataAction } from "../../redux/Reducers/bodyDataReducer";
import { useNavigate, useLocation, json } from "react-router-dom";
import { iciciProposalReducer } from "../../redux/Reducers/iciciApiReducer";
import { ackoProposalReducer } from "../../redux/Reducers/ackoApiReducer";
import { carDetailsforProposal, bikeDetailsforProposal } from "../../utils/commonUtils";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import hdfctermsicon from "../../Assets/Images/hdfctermsicon.png";
import CheckBox from "../Checkbox/CheckBox";
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader";


const Collapsible = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Razorpay] = useRazorpay();
  const search = useLocation().search;
  const transactionId = new URLSearchParams(search).get("transaction_id");
  const transaction_url_id = new URLSearchParams(search).get("id");
  const zunoKycId = new URLSearchParams(search).get("kId");
  let shreeramPolicyid = null;
  let shreeramPremium = null;
  let shreeramProdCode = null;

  // Zuno payment request variable
  let zunoPayAmount = null;
  let zunoCustomerId = null;
  let zunoAddInfo1 = null;
  let ZunoCheckSum = null;

  const {
    data,
    openModal,
    closeModal,
    setFormData,
    companyName,
    setEdit,
    Edit,
    tataOtpValue,
    handleBackBtn,
    sbiAutoKycData,
    mappingAllData,
    collapsibleTitle,
    headerCollapsible,
    setAutoKYCrejected,
    HandleEditProposalDetails,
    hdfcKycDate,
    setShowForm,
    hdfcKycData,
    sethdfcKycData,
  } = props;
  console.log(data.dob, "datadatadata");


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '90%', // Adjust width for better responsiveness
      maxWidth: window.innerWidth > 610 ? '28%' : '90%',
      padding: '20px',
      borderRadius: '10px',
    },
  };


  let ishdfcKycVariefied = hdfcKycData?.data?.iskycVerified;

  useEffect(() => {
    if (setEdit) {
      setEdit(false)
    }
  }, []);
  const hdfcProposalDownload = useSelector((state) => {
    return state.hdfcProposalDownload
  })

  const hdfcChecksumData = useSelector((state) => {
    return state.hdfcChecksumData
  })

  const [loading, setLoading] = useState(false);
  const [agreeCheck, setagreeCheck] = useState(true)

  let formattedDate = "";
  let formattedDt = "";

  if (data?.dob instanceof Date) {
    formattedDt = new Date(data?.dob);
    formattedDate = formattedDt.toLocaleDateString();
  } else if (typeof data?.dob === "string") {
    formattedDt = new Date(data?.dob);
    formattedDate = formattedDt.toLocaleDateString();
  }
  console.log(formattedDate, "formattedDateformattedDate");


  const [showDetailsOf, setshowDetailsOf] = useState("ownerDetails");
  const [flagbutton, setflagbuttonof] = useState(false);
  const [hdfcTermsPdf, sethdfcTermsPdf] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);


  const toggleColapsible = (menuName) => {
    if (menuName === showDetailsOf) {
      setshowDetailsOf("");
      setflagbuttonof(true)
    } else {
      setshowDetailsOf(menuName);
      setflagbuttonof(false)
    }
  };

  // inspectDetails

  // inspectDetails
  const getBodyData = useSelector((state) => {
    return state?.bodyData;
  });

  let vehicleType = getBodyData?.data?.vehicleType

  const shreeRamQuote = useSelector((state) => {
    return state?.shreeRamQuickQuote;
  });

  const digitQuote = useSelector((state) => {
    return state?.digitQuickQuote;
  });

  const iciciQuote = useSelector((state) => {
    return state?.iciciQuickQuote;
  });

  const ackoQuote = useSelector((state) => {
    return state?.ackoQuickQuote;
  });

  const digitProposalRes = useSelector((state) => {
    return state?.digitProposal;
  });

  const iciciProposalResp = useSelector((state) => {
    return state?.iciciProposal;
  });

  const ackoProposalResp = useSelector((state) => {
    return state?.ackoProposal;
  });

  let iciciKycAutoData = useSelector((state) => {
    return state.iciciKycAutoData;
  });

  let iciciKycManualData = useSelector((state) => {
    return state.iciciKycData;
  });

  const shreeRamProposalRes = useSelector((state) => {
    return state?.shreeRamProposal;
  });

  const sbiQuickQuoteData = useSelector((state) => {
    return state?.sbiQuickQuote;
  });

  // HDFC Calls
  const hdfcQuote = useSelector((state) => {
    return state?.hdfcQuickQuote;
  });

  let hdfcKycAutoData = useSelector((state) => {
    return state.hdfcKycAutoData;
  });

  const hdfcProposalRes = useSelector((state) => {
    return state?.hdfcProposalQuote;
  });

  const hdfcChecksumRes = useSelector((state) => {
    return state?.hdfcChecksumData;
  });

  // get zuno QQ
  const zunoQuickQuote = useSelector((state) => {
    return state?.zunoQuickQuote;
  });

  // get zuno FQ
  const zunoProposalRes = useSelector((state) => {
    return state?.zunoFullQuote;
  });

  // get zuno Auto Kyc
  const zunoAutoKyc = useSelector((state) => {
    return state?.zunoKyc;
  });

  // tata apis
  const tataQuickQuote = useSelector((state) => {
    return state?.tataQuickQuote;
  });

  const tataProposalRes = useSelector((state) => {
    return state?.tataProposalQuote;
  });

  const tataAutoKyc = useSelector((state) => {
    return state?.tataAutoKyc;
  });

  const tatamanualKyc = useSelector((state) => {
    return state?.tataManualKyc;
  });

  // united
  const unitedQuickQuote = useSelector((state) => {
    return state?.unitedQuickQuote;
  });

  const unitedProposalRes = useSelector((state) => {
    return state?.unitedProposalQuote;
  });

  // national
  const nationalQuickQuote = useSelector((state) => {
    return state?.nationalQuickQuote;
  });

  const nationalProposalRes = useSelector((state) => {
    return state?.nationalProposalQuote;
  });

  const nationalAutoKyc = useSelector((state) => {
    return state?.nationalKycAutoData;
  });

  const nationalmanualKyc = useSelector((state) => {
    return state?.nationalKycData;
  });
  // console.log(nationalQuickQuote?.data?.data?.quote?.PolicyObject?.DuePremium,nationalAutoKyc,nationalmanualKyc,nationalProposalRes?.data?.data?.quote?.PolicyObject?.DuePremium,"nationalapissssssssssssssssssss");


  function HandleCancelhdfcTermsPdf() {
    sethdfcTermsPdf(false);
    dispatch(hdfcProposalReducer.actions.resethdfcProposal());
    dispatch(hdfcChecksumReducer.actions.resethdfcChecksum());
  }

  //handle after proposal action icici
  useEffect(() => {
    (async function () {
      if (iciciProposalResp?.data?.data?.quote) {
        let iciciProposalResponse = iciciProposalResp?.data?.data?.quote;
        if (
          iciciProposalResponse.status == "Success"
          && !iciciProposalResponse.isApprovalRequired
          && !iciciProposalResponse.isQuoteDeviation
          && !iciciProposalResponse.breakingFlag
        ) {

          //1. create transaction then redirect to payment gateway
          let transactionData = {
            data: {
              amount: iciciProposalResponse.finalPremium,
              returnUrl: process.env.REACT_APP_ICICI_PAYMENT_WEBHOOK_URL
            }
          };
          let txnResp = await dispatch(iciciCreateTreansactionAction(transactionData));
          let txnId = txnResp?.payload?.data?.transactionId;

          //2. store response in db
          let bodyData = {
            companyname: companyName,
            transactionid: txnId,
            paymentTxnCode: transaction_url_id,
            bodydata: {
              icici: iciciProposalResponse,
            },
            type: "initiate",
          };
          await dispatch(paymentWebhookAction(bodyData));

          setLoading(false);
          dispatch(iciciProposalReducer.actions.reseticiciProposal());
          let paymentUrl = process.env.REACT_APP_ICICI_PAYMENT_URL + `/pgi/payment/PaymentMethod?transactionid=${txnId}`
          window.location.replace(paymentUrl);
        } else if (
          iciciProposalResponse.status == "Success"
          && iciciProposalResponse.isApprovalRequired
          && !iciciProposalResponse.isQuoteDeviation
          && iciciProposalResponse.breakingFlag
        ) {
          //break in case
          alert("Follow break in flow !");
          setLoading(false);
        } else {
          setLoading(false);
          dispatch(iciciProposalReducer.actions.reseticiciProposal());
          toast.error(iciciProposalResponse.message
            ? iciciProposalResponse.message : "Something went Wrong, try after sometime!", {
            position: "top-center",
            autoClose: 25000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    })();
  }, [iciciProposalResp])


  async function updateHdfcPayment() {

    let hdfcProposalResponse = hdfcProposalRes?.data?.data?.quote;
    let bodyData = {
      amount: hdfcProposalResponse?.Policy_Details?.TotalPremium,
      appID: 10189,
      subID: "S000000236",
      sUrl: "https://6htjwsmvb3.execute-api.us-east-1.amazonaws.com/dev/hdfc_submitPaymentDetails",
      fUrl: "https://6htjwsmvb3.execute-api.us-east-1.amazonaws.com/dev/hdfc_submitPaymentDetails",
      src: "POST"
    }

    let cehcksumHdfcRes = await dispatch(hdfcChecksumAction(bodyData));

    let hdfcTransactionId = cehcksumHdfcRes?.payload?.data?.body?.TransactionNo;

    //2. store response in db
    let insertData = {
      companyname: companyName,
      transactionid: hdfcTransactionId,
      bodydata: {
        hdfc: hdfcProposalResponse,
        proposalBodyData: getBodyData?.data
      },
      type: "initiate",
      paymentTxnCode: transaction_url_id,
    };
    setLoading(true)
    await dispatch(paymentWebhookAction(insertData));
    sethdfcTermsPdf(false);

    // 3.click payment
    document.getElementById("invisibleSubmitButtonhdfc").click(); // Programmatically click the invisible button
    setLoading(false);
    dispatch(resethdfcProposalAction());
    dispatch(resethdfcChecksum());
  }

  useEffect(() => {
    (async function () {
      if (companyName?.toLowerCase() == "shreeram") {
        let shreeramProposalResponse = shreeRamProposalRes?.data?.data?.quote;
        if (
          shreeramProposalResponse?.MessageResult?.Result?.toLowerCase() ==
          "success"
        ) {
          //initate entry in db for payment
          let bodyData = {
            companyname: companyName?.toLowerCase(),
            transactionid: shreeramProposalResponse?.GenerateProposalResult?.POL_SYS_ID,
            bodydata: {
              shreeram: shreeramProposalResponse,
            },
            type: "initiate",
            paymentTxnCode: transaction_url_id,
          };
          await dispatch(paymentWebhookAction(bodyData));

          shreeramPolicyid =
            shreeramProposalResponse?.GenerateProposalResult?.POL_SYS_ID;
          shreeramPremium =
            shreeramProposalResponse?.GenerateProposalResult?.CoverDtlList[
            shreeramProposalResponse?.GenerateProposalResult?.CoverDtlList
              .length - 1
            ]["Premium"];
          shreeramProdCode =
            shreeRamProposalRes?.data?.data?.body?.objPolicyEntryETT?.ProdCode;
          document.getElementsByName("prodCode")[0].value = shreeramProdCode;
          document.getElementsByName("amount")[0].value = shreeramPremium;
          document.getElementsByName("QuoteId")[0].value = shreeramPolicyid;
          document.getElementsByName(
            "sourceUrl"
          )[0].value = `${process.env.REACT_APP_SHREERAM_GET_PAYMENT_URL}?QuoteID=${shreeramPolicyid}&companyName=${companyName?.toLowerCase()}`;

          setLoading(false);
          // Dispatch the action to reset the state
          document.getElementById("invisibleSubmitButton").click(); // Programmatically click the invisible button
          dispatch(shreeRamProposalReducer.actions.resetShreeRamProposal());
        } else if (
          shreeramProposalResponse?.MessageResult?.Result?.toLowerCase() ==
          "failure" ||
          shreeramProposalResponse?.GenerateProposalResult?.ERROR_DESC
        ) {
          //here show errors
          // Dispatch the action to reset the state
          dispatch(shreeRamProposalReducer.actions.resetShreeRamProposal());
          setLoading(false);
          toast.error(
            shreeramProposalResponse?.GenerateProposalResult?.ERROR_DESC ||
            shreeramProposalResponse?.MessageResult?.ErrorMessage,
            {
              position: "top-center",
              autoClose: 25000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      } else if (companyName == "digit" && !digitProposalRes.isLoading) {
        if (digitProposalRes?.data?.data?.payment?.[
          "Motor-Motor payment link generation Api"
        ]?.dispatcherResponse) {
          //initate entry in db for payment
          let bodyData = {
            companyname: companyName,
            transactionid: digitProposalRes?.data?.data?.quote?.["Motor-Create Quote"].policyNumber,
            bodydata: {
              digit: digitProposalRes?.data?.data,
            },
            type: "initiate",
            paymentTxnCode: transaction_url_id,
          };
          await dispatch(paymentWebhookAction(bodyData));
          //////////////////////////////////////
          setLoading(false);
          let paymentUrl =
            digitProposalRes?.data?.data?.payment?.[
              "Motor-Motor payment link generation Api"
            ]?.dispatcherResponse;

          dispatch(digitCreateQuoteReducer.actions.resetDigitProposal());
          window.location.replace(paymentUrl);
        } else if (digitProposalRes?.data?.msg == "Error") {
          setLoading(false);
          dispatch(digitCreateQuoteReducer.actions.resetDigitProposal());
          toast.error("Something went wrong!", {
            position: "top-center",
            autoClose: 25000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }

      } else if (companyName == "zuno") {
        let zunoProposalResponse = zunoProposalRes?.data?.data?.quote;
        let DataZK
        if (zunoAutoKyc?.data?.data) {
          DataZK = zunoAutoKyc?.data?.data?.quote?.data
        } else {
          let zunoData = {
            trackId: transaction_url_id,
            type: "get",
          };
          let kycDataRes = await dispatch(zunoInsertBodydAction(zunoData));
          if (kycDataRes?.payload?.data?.paymentinfo[0]?.Data) {
            DataZK = JSON.parse(kycDataRes?.payload?.data?.paymentinfo[0]?.Data)
          }
        }
        if (zunoProposalResponse?.premiumDetails?.grossTotalPremium > 0) {

          // 1. body data for payment
          zunoPayAmount = String((zunoProposalResponse?.premiumDetails?.grossTotalPremium).toFixed(2))
          let customerCompanyName = getBodyData?.data?.carOwner == "individualOwner" ? getBodyData?.data?.firstName + getBodyData?.data?.lastName : getBodyData?.data?.companyName;
          zunoCustomerId = generateCutomerId(customerCompanyName);
          zunoAddInfo1 = generateUnique16DigitNumber();
          let checkSumBody = `EDGENBKAGR|${zunoCustomerId}|NA|${zunoPayAmount}|NA|NA|NA|INR|NA|R|EDGENBMIIB-NA|NA|NA|F|${zunoAddInfo1}|BMI|NA|NA|NA|NA|NA|${process.env.REACT_APP_ZUNO_PAYMENT_WEBHOOK_URL}`
          ZunoCheckSum = generateCheckSum(checkSumBody, process.env.REACT_APP_ZUNO_CHECKSUM);
          document.getElementsByName(
            "msg"
          )[0].value = checkSumBody + "|" + ZunoCheckSum;

          //2. store response in db
          let bodyData = {
            companyname: companyName,
            transactionid: zunoCustomerId,
            bodydata: {
              zuno: zunoProposalResponse,
              kyc: DataZK,
            },
            type: "initiate",
            paymentTxnCode: transaction_url_id,
          };
          await dispatch(paymentWebhookAction(bodyData));

          // 3.click payment
          dispatch(zunoFullQuoteReducer.actions.resetzunofullQuote());
          document.getElementById("invisibleSubmitButtonZuno").click(); // Programmatically click the invisible button
          // setLoading(false);
        } else if (zunoProposalResponse?.msg) {
          dispatch(zunoFullQuoteReducer.actions.resetzunofullQuote());
          setLoading(false);
          toast.error((zunoProposalResponse?.msg), {
            position: "top-center",
            autoClose: 25000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else if (companyName == "hdfc") {
        let hdfcProposalResponse = hdfcProposalRes?.data?.data?.quote;
        if (hdfcProposalResponse?.Policy_Details?.TotalPremium > 0) {

          // proposal download
          let proposalDocument = {
            proposal: getBodyData.data,
            proposalResponse: hdfcProposalResponse
          };
          let proposalDocumentDowload = await dispatch(hdfcProposalDocument(proposalDocument));
          if (proposalDocumentDowload?.payload?.data?.quote?.Resp_Policy_Document?.PDF_BYTES) {
            setLoading(false);
            sethdfcTermsPdf(true);
          } else {
            dispatch(resethdfcProposalAction())
            setLoading(false);
            toast.error("Something went wrong!", {
              position: "top-center",
              autoClose: 25000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }

          // 1. body data for payment

        }
        else if (hdfcProposalResponse?.msg) {
          // dispatch(hdfcProposalReducer.actions.hdfcProposal());

          setLoading(false);
          toast.error((hdfcProposalResponse?.msg), {
            position: "top-center",
            autoClose: 25000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }

      } else if (companyName == "tata") {
        let quotePremium
        let proposalPremium
        if (getBodyData?.data?.vehicleType) {
          quotePremium = tataQuickQuote?.data?.data?.quote?.data?.premium_break_up?.final_premium
          proposalPremium = tataProposalRes?.data?.data?.quote.data[0]?.premium_value
        } else {
          quotePremium = tataQuickQuote?.data?.data?.quote?.data[0]?.data?.premium_break_up?.net_premium
          proposalPremium = tataProposalRes?.data?.data?.quote.data[0]?.premium_value
        }

        if (quotePremium == proposalPremium) {
          setLoading(true);
          // call auto kyc
          let manualKyc
          let autoKyc = await dispatch(tataAutoKycAction({
            data: {
              ...getBodyData?.data,
              ...{
                proposalNo:
                  tataProposalRes?.data?.data?.quote?.data[0]?.proposal_no,
              },
            }
          }));

          if ((getBodyData?.data?.kycAadharNumber || getBodyData?.data?.CertificateOfIncorporationNumber)) {
            autoKyc = await dispatch(tataAutoKycAction({
              data: {
                ...getBodyData?.data,
                ...{
                  proposalNo:
                    tataProposalRes?.data?.data?.quote?.data[0]?.proposal_no,
                  autoKycRsponseReqId:
                    autoKyc?.payload?.data?.quote?.data?.req_id ? autoKyc?.payload?.data?.quote?.data?.req_id : "",
                },
              }
            }));
          }

          if (autoKyc?.payload?.data?.quote?.message_txt == "OTP Sent") {
            setLoading(false);
            openModal();
          }

          if (getBodyData?.data?.kycAadharImg || getBodyData?.data?.CertificateOfIncorporationFile) {
            manualKyc = await dispatch(tataManualKycAction({
              data: {
                ...getBodyData.data,
                ...{
                  proposalNo:
                    tataProposalRes?.data?.data?.quote?.data[0]?.proposal_id,
                  autoKycRsponseReqId:
                    autoKyc?.payload?.data?.quote?.data?.req_id,
                },
              }
            }));
          }

          // payment gateway
          if (autoKyc?.payload?.data?.quote?.data?.result?.ckyc_number || manualKyc?.payload?.data?.quote?.message_txt == "successfully uploaded") {
            // insert db
            let bodyData = {
              companyname: companyName,
              transactionid: tataProposalRes.data.data.quote.data[0].proposal_no,
              bodydata: {
                tata: tataProposalRes?.data,
              },
              type: "initiate",
              paymentTxnCode: transaction_url_id,
            };
            await dispatch(paymentWebhookAction(bodyData));

            // start payment
            let onlinePayment = await dispatch(tataOnlinePaymentAction({
              data: {
                ...getBodyData.data,
                ...{
                  paymentId:
                    tataProposalRes.data.data.quote.data[0].payment_id,
                },
              }
            }));
            if (onlinePayment?.payload?.data?.quote?.message_txt == "Success") {
              let paymentLink = JSON.parse(onlinePayment?.payload?.data?.quote?.data)
              document.getElementById("myForm").action = paymentLink?.url;
              document.getElementsByName("pgiRequest")[0].value = paymentLink?.pgiRequest;
              dispatch(tataProposalReducer.actions.resettataProposalAction());
              document.getElementById("invisibleSubmitButtonTata").click(); // Programmatically click the invisible button
            } else {
              setLoading(false);
              toast.error(onlinePayment?.payload?.data?.quote?.message_txt ? onlinePayment?.payload?.data?.quote?.message_txt : "Something went wrong !", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          } else if (manualKyc) {
            setLoading(false);
            await toast.error(manualKyc?.payload?.data?.quote?.message_txt ? manualKyc?.payload?.data?.quote?.message_txt : "Something went wrong !", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            dispatch(tataAutoKycReducer.actions.resettataAutoKycAction());
            dispatch(tataProposalReducer.actions.resettataProposalAction());
            setFormData((prevItems) => ({
              ...prevItems,
              kycAadharNumber: '',
              kycAadharImg: '',
              CertificateOfIncorporationFile: '',
              CertificateOfIncorporationNumber: '',
            }))
          } else if (autoKyc?.payload?.data?.quote?.message_txt != "OTP Sent") {
            setLoading(false);
            if (autoKyc?.payload?.data?.quote?.data?.req_id && autoKyc?.payload?.data?.quote?.message_txt != "successfully completed") {
              setShowForm("kycDetails");
              if ((getBodyData?.data?.kycAadharNumber && !getBodyData?.data?.kycAadharImg) || (getBodyData?.data?.CertificateOfIncorporationNumber && !getBodyData?.data?.CertificateOfIncorporationFile)) {
                setAutoKYCrejected(true)
                toast.error(autoKyc?.payload?.data?.quote?.message_txt ? autoKyc?.payload?.data?.quote?.message_txt : "Something went wrong !", {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                dispatch(tataAutoKycReducer.actions.resettataAutoKycAction());
                dispatch(tataProposalReducer.actions.resettataProposalAction());
              } else {
                dispatch(tataAutoKycReducer.actions.resettataAutoKycAction());
                dispatch(tataProposalReducer.actions.resettataProposalAction());
                toast.error("Details are not matching Please proceed with other document !", {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            } else {
              await toast.error(autoKyc?.payload?.data?.quote?.message_txt ? autoKyc?.payload?.data?.quote?.message_txt : "Something went wrong !", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              dispatch(tataAutoKycReducer.actions.resettataAutoKycAction());
              dispatch(tataProposalReducer.actions.resettataProposalAction());
              setFormData((prevItems) => ({
                ...prevItems,
                kycAadharNumber: '',
                kycAadharImg: '',
                CertificateOfIncorporationFile: '',
                CertificateOfIncorporationNumber: '',
              }))
            }
          }

        } else if (tataProposalRes?.data || tataProposalRes?.body) {
          dispatch(tataProposalReducer.actions.resettataProposalAction());
          setLoading(false);
          toast.error(tataProposalRes?.data?.data?.quote?.message_txt ? tataProposalRes?.data?.data?.quote?.message_txt : tataProposalRes?.data?.msg || "Something went wrong !", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else if (companyName == "united") {
        if (unitedQuickQuote?.data?.data?.quote?.ROOT?.HEADER?.CUR_FINAL_TOTAL_PREMIUM == unitedProposalRes?.data?.data?.quote?.ROOT?.HEADER?.CUR_FINAL_TOTAL_PREMIUM) {
          setLoading(false);
        }
      } else if (companyName == "national") {
        if (nationalQuickQuote?.data?.data?.quote?.PolicyObject?.DuePremium == nationalProposalRes?.data?.data?.quote?.PolicyObject?.DuePremium) {
          // setLoading(false);
          // 1. body data for payment
          let nationalPayAmount = String(nationalProposalRes?.data?.data?.quote?.PolicyObject?.DuePremium)
          let customerCompanyName = getBodyData?.data?.carOwner == "individualOwner" ? getBodyData?.data?.firstName + getBodyData?.data?.lastName : getBodyData?.data?.companyName;
          let nationalCustomerId = generateCutomerId(customerCompanyName);
          let nationalAddInfo1 = generateUnique16DigitNumber();
          let checkSumBody = `NTLINBKAGR|${nationalCustomerId}|NA|${nationalPayAmount}|NA|NA|NA|INR|NA|R|NTLINVANSH-NA|NA|NA|F|${nationalAddInfo1}|VANSH|NA|NA|NA|NA|NA|${process.env.REACT_APP_NATIONAL_PAYMENT_WEBHOOK_URL}`
          let nationalCheckSum = generateCheckSum(checkSumBody, process.env.REACT_APP_NATIONAL_CHECKSUM);
          document.getElementById("msgNational").value = checkSumBody + "|" + nationalCheckSum;
          // console.log(checkSumBody + "|" + nationalCheckSum,"national check sum");

          //2. store response in db
          let bodyData = {
            companyname: companyName,
            transactionid: nationalCustomerId,
            bodydata: {
              national: nationalProposalRes
            },
            type: "initiate",
            paymentTxnCode: transaction_url_id,
          };
          await dispatch(paymentWebhookAction(bodyData));

          // 3.click payment
          dispatch(nationalProposalReducer.actions.resetnationalFullQuoteAction());
          document.getElementById("invisibleSubmitButtonNational").click(); // Programmatically click the invisible button
        } else if (nationalProposalRes?.data?.error) {
          dispatch(nationalProposalReducer.actions.resetnationalFullQuoteAction());
          setLoading(false);
          toast.error((nationalProposalRes?.data?.error?.messages ? nationalProposalRes?.data?.error?.messages[0]?.message : "Something Went Wrong"), {
            position: "top-center",
            autoClose: 25000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    })();
  }, [digitProposalRes, shreeRamProposalRes, zunoProposalRes, ackoProposalResp, tataProposalRes, hdfcProposalRes, unitedProposalRes, nationalProposalRes]);

  const payNowHandle = async () => {
    if (companyName?.toLowerCase() == "shreeram") {
      let proposalData = {
        proposal: getBodyData.data,
        bodyData: shreeRamQuote?.data?.data?.body?.objPolicyEntryETT,
        qouteResPayLoad: shreeRamQuote?.data?.data?.quote?.GetQuotResult,
      };
      dispatch(shreeRamProposalAction(proposalData));
      setLoading(true);
    } else if (companyName?.toLowerCase() == "digit") {
      let proposalData = {
        proposal: getBodyData.data,
        bodyData: digitQuote?.data?.data?.body?.["motorQuickQuote"],
        qouteResPayLoad: digitQuote?.data?.data?.quote?.["Motor-Quick Quote"],
      };
      dispatch(digitCreateQuoteAction(proposalData));
      setLoading(true);
    } else if (companyName?.toLowerCase() == "zuno") {
      setLoading(true);
      const asyncFn = async () => {
        let formZunoData = getBodyData.data
        // if (zunoKycId && (!getBodyData.data.pincode || !getBodyData.data.dob)) {
        //   let zunoData = {
        //     kycId: zunoKycId,
        //     type: "get",
        //   };
        //   let kycDataRes = await dispatch(zunoInsertBodydAction(zunoData));
        //   let bodyDataAllRes = JSON.parse(kycDataRes?.payload?.data?.paymentinfo[0].bodyData)
        //   let bodyDataRes = bodyDataAllRes?.zunoBody
        //   formZunoData = bodyDataRes.data
        // }
        let proposalData = {
          proposal: formZunoData,
          bodyData: zunoQuickQuote?.data?.data?.body,
          qouteResPayLoad: zunoQuickQuote?.data?.data?.quote,
        };
        await dispatch(zunoFullQuoteAction(proposalData));
      };
      asyncFn();
    } else if (companyName?.toLowerCase() == "icici") {
      setLoading(true);
      let proposalData = {
        proposal: getBodyData.data,
        bodyData: iciciQuote?.data?.data?.body,
        qouteResPayLoad: iciciQuote?.data?.data?.quote,
        kycData:
          iciciKycAutoData?.data?.body?.data?.statusMessage == "Failed"
            ? iciciKycManualData?.data?.body?.data?.kyc_details
            : iciciKycAutoData?.data?.body?.data?.kyc_details,
      };
      dispatch(iciciProposalAction(proposalData));
    } else if (companyName?.toLowerCase() == "acko") {
      let addOnDetails = getBodyData?.data?.vehicleType == "2W" ?
        bikeDetailsforProposal(companyName, ackoQuote, getBodyData) :
        carDetailsforProposal(companyName, ackoQuote, getBodyData)
        ;
      setLoading(true);
      let proposalData = {
        proposal: getBodyData.data,
        bodyData: ackoQuote?.data?.data?.body,
        qouteResPayLoad: ackoQuote?.data?.data?.quote,
        planDetails: addOnDetails
      };
      dispatch(ackoProposalAction(proposalData));
    } else if (companyName?.toLowerCase() == "tata") {
      setLoading(true);
      let proposalData = {
        proposal: getBodyData.data,
        bodyData: tataQuickQuote?.data?.data?.body,
        qouteResPayLoad: tataQuickQuote?.data?.data?.quote
      };
      dispatch(tataProposalAction(proposalData));

    } else if (companyName?.toLowerCase() == "hdfc") {
      setLoading(true);
      const asyncFn = async () => {
        let hdfcKycData
        const id = new URLSearchParams(search).get("id");
        let data = {
          uuid: id,
        };
        let resp = await dispatch(transactionURLAction(data));
        // alert("fonr")
        if (resp?.payload?.data?.txninfo[0].kyc_manual_data) {
          // sethdfcTermsPdf(true)
          // sethdfcManualKycData(JSON.parse(resp?.payload?.data?.txninfo[0].kyc_manual_data));
          hdfcKycData = (JSON.parse(resp?.payload?.data?.txninfo[0].kyc_manual_data));
        } else {
          // sethdfcTermsPdf(true)
          // sethdfcAutoKycData(JSON.parse(resp?.payload?.data?.txninfo[0].kyc_auto_data));
          if (resp?.payload?.data) {
            hdfcKycData = (JSON.parse(resp?.payload?.data?.txninfo[0].kyc_auto_data));
          }
        }

        let proposalData = {
          proposal: getBodyData.data,
          bodyData: hdfcQuote?.data?.data?.body,
          qouteResPayLoad: hdfcQuote?.data?.data?.quote,
          kycData: hdfcKycData?.data ? hdfcKycData : { data: { ...hdfcKycData } }
        };
        let res = await dispatch(hdfcProposalAction(proposalData));
        if (res?.payload?.msg == "Error") {
          setLoading(false)
          toast.error("Something went Wrong, try after sometime!", {
            position: "top-center",
            autoClose: 25000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }

      }; asyncFn();
    } else if (companyName?.toLowerCase() == "united") {
      setLoading(true);
      const asyncFn = async () => {
        let proposalData = {
          proposal: getBodyData?.data,
          bodyData: unitedQuickQuote?.data?.data?.body,
          qouteResPayLoad: unitedQuickQuote?.data?.data?.quote,
        };
        await dispatch(unitedProposalAction(proposalData));
      };
      asyncFn();
    } else if (companyName?.toLowerCase() == "national") {
      setLoading(true);
      let proposalData = {
        proposal: getBodyData.data,
        bodyData: nationalQuickQuote?.data?.data?.body,
        qouteResPayLoad: nationalQuickQuote?.data?.data?.quote,
        kycResponse:
          nationalAutoKyc?.data?.data?.quote?.signzyAppId
            ? nationalAutoKyc?.data?.data?.quote
            : nationalmanualKyc?.data?.data?.quote,
      };
      dispatch(nationalFullQuoteAction(proposalData));
    }
  };

  const [IsEdited, setIsEdited] = useState(false);

  //this is to be segregated as per company name
  const getBtnLoadingStatus = useSelector((store) => {
    return store?.sbiFullQuote?.isLoading;
  });

  const sbiBodyData = useSelector((store) => {
    return store?.bodyData;
  });

  const sbiFullQuoteData = useSelector((store) => {
    return store?.sbiFullQuote?.data?.body?.data?.quote?.PolicyObject;
  });

  const getFingerprint = async () => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    return result.visitorId;
  };

  const getIpAddress = async () => {
    let res = await fetch("https://api.ipify.org/?format=json");
    res = await res.json();
    return res.ip;
  };

  const handlePaymentLink = useCallback(async () => {
    try {
      function showToast() {
        toast("Link Copied to clipboard!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setLoading(true);
      let quoteData;
      if (companyName == "sbi") {
        quoteData = sbiQuickQuoteData;
      } else if (companyName == "icici") {
        quoteData = iciciQuote;
      } else if (companyName == "digit") {
        quoteData = digitQuote;
      } else if (companyName == "acko") {
        quoteData = ackoQuote;
      } else if (companyName == "shreeram") {
        quoteData = shreeRamQuote;
      }
      //setCompany quoteData
      let bodyData = {
        companyName: companyName,
        bodyData: getBodyData,
        quoteData,
        kycData: { // only for icici as of now
          iciciKycAutoData,
          iciciKycManualData
        },
        type: "created",
      };
      let payResp = await dispatch(paymentSendURLAction(bodyData));
      setLoading(false);
      if (payResp?.payload?.data?.transactionId) {
        showToast();
        navigator.clipboard.writeText(
          `${process.env.REACT_APP_WEB_LINK}/#/${getBodyData?.data?.vehicleType == "2W" ? "Propsal-Form-Bike" : "Propsal-Form"}?transaction_id=${payResp?.payload?.data?.transactionId}`
        );
      } else {
        toast.error("Something went Wrong, try after sometime!", {
          position: "top-center",
          autoClose: 25000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went Wrong, try after sometime!", {
        position: "top-center",
        autoClose: 25000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  });

  const payNowAckoHandle = async () => {
    // setLoading(true);
    //Call fullquote
    try {
      let addOnDetails = getBodyData?.data?.vehicleType == "2W" ?
        bikeDetailsforProposal(companyName, ackoQuote, getBodyData) :
        carDetailsforProposal(companyName, ackoQuote, getBodyData)
        ;
      setLoading(true);
      let proposalData = {
        proposal: getBodyData.data,
        bodyData: ackoQuote?.data?.data?.body,
        qouteResPayLoad: ackoQuote?.data?.data?.quote,
        planDetails: addOnDetails,
        transactionId: transaction_url_id
      };
      let fullBodyDetails = await dispatch(ackoProposalAction(proposalData));
      if (fullBodyDetails?.payload?.data?.proposal) {
        //open payment gateway 
        let duePremium = fullBodyDetails?.payload?.data?.proposal?.insured[0]?.plan.total_price?.gross_premium;

        //generate device fp and ip address
        let deviceId = await getFingerprint();
        let ipAddress = await getIpAddress();

        if (fullBodyDetails?.payload?.data?.isPgFlagN) {
          //alert whether to proceed or not if yes 
          let bodyData = {
            companyname: companyName,
            transactionid: fullBodyDetails?.payload?.data?.proposal?.insured[0].proposal_ekey,
            paymentTxnCode: transaction_url_id,
            bodydata: {
              acko: fullBodyDetails?.payload,
            },
            type: "initiate",
            fingerPrintDeviceId: deviceId,
            ipAddress: ipAddress
          };
          await dispatch(paymentWebhookAction(bodyData));
          // adding response in the database when successfull payment
          await fetch(
            `${process.env.REACT_APP_PAYMENT_WEBHOOK_URL}?companyName=${companyName}&acko_id=${fullBodyDetails?.payload?.data?.proposal?.insured[0].proposal_ekey}`,
            {
              method: "POST",
              body: JSON.stringify({
                uuid: transaction_url_id,
                amount: duePremium
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          ).then(response => response.json())
            .then(async (response) => {
              if (response?.data?.proposal_status == "policy_ready") {
                setTimeout(async () => {
                  const pdfData = await dispatch(
                    ackopdfDownloadAction({
                      data: {
                        proposal_id: fullBodyDetails?.payload?.data?.proposal?.insured[0].proposal_ekey,
                      },
                    })
                  );
                  if (pdfData?.payload?.data?.pdfBase64) {
                    setLoading(false);
                    // payment download page
                    navigate(
                      `/Payment-Redirect?companyName=acko&proposal_id=${fullBodyDetails?.payload?.data?.proposal?.insured[0].proposal_ekey}&policy_number=${pdfData?.payload?.data.policyNo}`
                    );
                  } else {
                    setLoading(false);
                    navigate(
                      `/Payment-Redirect?companyName=acko&proposal_id=${fullBodyDetails?.payload?.data?.proposal?.insured[0].proposal_ekey}`
                    );
                  }
                }, 8000);
              } else {
                let statusData = {
                  transactionid: fullBodyDetails?.payload?.data?.proposal?.insured[0].proposal_ekey,
                  type: "getpaymentdata",
                };
                const paymentResponse = await dispatch(
                  paymentWebhookAction(statusData)
                );
                const { paymentinfo } = paymentResponse?.payload?.data;

                const { acko } = JSON.parse(paymentinfo[0]?.body_data);
                if (paymentinfo[0]?.status == "success") {
                  //check for checkout api and redirected to kyc or payment downlod page
                  // /Kyc-Form"
                  navigate(
                    `/Kyc-Form?id=${transaction_url_id}&proposal_id=${fullBodyDetails?.payload?.data?.proposal?.insured[0].proposal_ekey}`
                  );
                }

              }
            });
        } else {
          //check if cashback is applicable on this vehicle
          let getCashbackDetails = await dispatch(getVehicleCashbackApiAction(fullBodyDetails?.payload?.data?.proposal?.insured[0]?.asset_details?.asset_id));
          if (getCashbackDetails?.payload?.data?.length) {
            duePremium = duePremium - getCashbackDetails?.payload?.data[0]?.cb_amount
          }

          let data = {
            amount: duePremium * 100, //sbiFullQuoteData?.DuePremium * 100,
            currency: "INR"
          };
          let razorOrderResponse = await dispatch(razorAckoCreateOrderAction(data));
          const { id } = razorOrderResponse?.payload?.data;

          let bodyData = {
            companyname: companyName,
            transactionid: fullBodyDetails?.payload?.data?.proposal?.insured[0].proposal_ekey,
            paymentTxnCode: transaction_url_id,
            bodydata: {
              acko: fullBodyDetails?.payload,
            },
            type: "initiate",
            orderDetails: razorOrderResponse?.payload?.data,
            fingerPrintDeviceId: deviceId,
            ipAddress: ipAddress
          };
          await dispatch(paymentWebhookAction(bodyData));

          const options = {
            key: process.env.REACT_APP_ACKO_RAZORPAY_KEY,
            amount: duePremium * 100, //sbiFullQuoteData?.DuePremium * 100,
            currency: "INR",
            name: "Acko General Insurance",
            description: "Policy Payment",
            image: "https://bookmyinsurance-images.s3.ap-south-1.amazonaws.com/acko.jpg",
            order_id: id,
            modal: {
              ondismiss: function () {
                document.body.style.overflow = 'auto'
              },
            },
            handler: async (res) => {
              try {
                setLoading(true);
                // adding response in the database when successfull payment
                // Production Url
                await fetch(
                  `${process.env.REACT_APP_PAYMENT_WEBHOOK_URL}?companyName=${companyName}&transaction_id=${id}&acko_id=${fullBodyDetails?.payload?.data?.proposal?.insured[0].proposal_ekey}`,
                  {
                    method: "POST",
                    body: JSON.stringify(res),
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                ).then(response => response.json())
                  .then(async (response) => {
                    if (response?.data?.proposal_status == "policy_ready") {
                      setTimeout(async () => {
                        const pdfData = await dispatch(
                          ackopdfDownloadAction({
                            data: {
                              proposal_id: fullBodyDetails?.payload?.data?.proposal?.insured[0].proposal_ekey,
                            },
                          })
                        );
                        if (pdfData?.payload?.data?.pdfBase64) {
                          setLoading(false);
                          // payment download page
                          navigate(
                            `/Payment-Redirect?companyName=acko&proposal_id=${fullBodyDetails?.payload?.data?.proposal?.insured[0].proposal_ekey}&policy_number=${pdfData?.payload?.data.policyNo}`
                          );
                        } else {
                          setLoading(false);
                          navigate(
                            `/Payment-Redirect?companyName=acko&proposal_id=${fullBodyDetails?.payload?.data?.proposal?.insured[0].proposal_ekey}`
                          );
                        }
                      }, 8000);
                    } else {
                      let statusData = {
                        transactionid: fullBodyDetails?.payload?.data?.proposal?.insured[0].proposal_ekey,
                        type: "getpaymentdata",
                      };
                      const paymentResponse = await dispatch(
                        paymentWebhookAction(statusData)
                      );
                      const { paymentinfo } = paymentResponse?.payload?.data;

                      const { acko } = JSON.parse(paymentinfo[0]?.body_data);
                      if (paymentinfo[0]?.status == "success") {
                        //check for checkout api and redirected to kyc or payment downlod page
                        // /Kyc-Form"
                        navigate(
                          `/Kyc-Form?id=${transaction_url_id}&proposal_id=${fullBodyDetails?.payload?.data?.proposal?.insured[0].proposal_ekey}`
                        );
                      }

                    }
                  });
              } catch (rejectedValueOrSerializedError) {
                setLoading(false);
                alert(" Something went wrong !");
              }
            },
            prefill: {
              name: fullBodyDetails?.payload?.data?.proposal?.insured[0]?.user?.name || "",
              email: fullBodyDetails?.payload?.data?.proposal?.insured[0]?.user?.email || "",
              contact: fullBodyDetails?.payload?.data?.proposal?.insured[0]?.user?.phone || "", //"9999999999",
            },
            notes: {
              address: fullBodyDetails?.payload?.data?.proposal?.insured[0]?.user?.address || "Razorpay Corporate Office",
              proposalId: fullBodyDetails?.payload?.data?.proposal?.insured[0].proposal_ekey,
              discount: getCashbackDetails?.payload?.data?.length ? getCashbackDetails?.payload?.data[0]?.cb_amount : 0,
              vehicleNumber: fullBodyDetails?.payload?.data?.proposal?.insured[0]?.asset_details?.asset_id
            },
            theme: {
              color: "#2C5ECD",
            },
          }
          const rzpay = new Razorpay(options);
          rzpay.open();
          setLoading(false);
          // dispatch(ackoProposalReducer.actions.resetackoProposal());
          // window.location.replace(fullBodyDetails?.data?.data?.checkoutURL);

        }
      } else if (fullBodyDetails?.data?.error) {
        dispatch(ackoProposalReducer.actions.resetackoProposal());
        setLoading(false);
        toast.error(fullBodyDetails?.data?.error?.error_message ? fullBodyDetails?.data?.error?.error_message : "Something went wrong !", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong, Please try again !", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const HandleCheckBox = ((e) => {
    let val = e.target.checked
    setagreeCheck(val)
  })
  const handlePayment = useCallback(async () => {
    if (companyName == "acko") {
      payNowAckoHandle();
      return;
    } else if (companyName != "sbi" && companyName != "acko") {
      payNowHandle();
      return;
    }
    // else if (companyName=="hdfc"){
    //   setLoading(false);
    //   sethdfcTermsPdf(true);
    //   return;
    // }
    // setLoading(true);
    //Call fullquote
    try {
      let fullBodyDetails = await dispatch(
        sbiFullQuoteAction({
          data: {
            ...sbiBodyData?.data,
            requestId:
              sbiQuickQuoteData?.data?.body?.data?.body?.RequestHeader
                ?.requestID,
          },
        }) // unique ID
      );
      if (fullBodyDetails?.payload?.body?.data?.quote?.ValidateResult) {
        toast.error(
          fullBodyDetails?.payload?.body?.data?.quote?.ValidateResult.message,
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setLoading(false);
      } else {
        let duePremium =
          fullBodyDetails?.payload?.body?.data?.quote?.PolicyObject?.DuePremium;
        let data = {
          amount: duePremium * 100, //sbiFullQuoteData?.DuePremium * 100,
          currency: "INR",
          receipt: "123ffdfdffd",
        };
        let razorOrderResponse = await dispatch(razorCreateOrderAction(data));
        const { id } = razorOrderResponse?.payload?.body?.response;
        let bodyData = {
          companyname: companyName,
          transactionid: id,
          paymentTxnCode: transaction_url_id,
          bodydata: {
            sbi: fullBodyDetails?.payload?.body?.data?.quote?.PolicyObject,
          },
          type: "initiate",
        };
        await dispatch(paymentWebhookAction(bodyData));

        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          amount: duePremium * 100, //sbiFullQuoteData?.DuePremium * 100,
          currency: "INR",
          name: companyName
            ? companyName?.toUpperCase() + " General Insurance"
            : "Book My Insurance",
          description: "Policy Payment",
          image: sbiImage,
          order_id: id,
          handler: async (res) => {
            try {
              setLoading(true);
              // adding response in the database when successfull payment
              // Production Url
              await fetch(
                `${process.env.REACT_APP_PAYMENT_WEBHOOK_URL}?companyName=${companyName}&transaction_id=` +
                id,
                {
                  method: "POST",
                  body: JSON.stringify(res),
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
              let PolicyNumber = "";
              if (companyName == "sbi") {
                let statusData = {
                  transactionid: id,
                  type: "getpaymentdata",
                };
                const paymentResponse = await dispatch(
                  paymentWebhookAction(statusData)
                );
                const { paymentinfo } = paymentResponse?.payload?.data;

                const { sbi } = JSON.parse(paymentinfo[0]?.body_data);
                if (paymentinfo[0]?.status == "captured") {
                  let bodyData = {
                    data: {
                      quotationNo: sbi?.QuotationNo, // PolicyNo
                      amount: sbi?.DuePremium,
                      requestId:
                        sbiQuickQuoteData?.data?.body?.data?.body?.RequestHeader
                          ?.requestID,
                      kycData: fullBodyDetails?.payload?.body?.data?.body?.RequestBody?.PolicyCustomerList[0],
                    },
                  };
                  const policyGeneratedData = await dispatch(
                    sbiPolicyGenerateAction(bodyData)
                  );
                  if (
                    policyGeneratedData?.payload?.body?.data?.quote
                      ?.ValidateResult
                  ) {
                    toast.error(
                      policyGeneratedData?.payload?.body?.data?.quote
                        ?.ValidateResult.message,
                      {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      }
                    );
                    setLoading(false);
                  } else {
                    PolicyNumber =
                      policyGeneratedData?.payload?.body?.data?.quote?.PolicyNo;
                    if (PolicyNumber) {
                      let KYCPolicyNumber = {
                        PolicyNumber: PolicyNumber,
                      };
                      //calling KYC API for storing policy number
                      if (sbiBodyData?.data?.kycAadharImg || sbiBodyData?.data?.CertificateOfIncorporationNumber) {
                        //manual KYC
                        await dispatch(
                          sbiKyc({
                            data: {
                              ...sbiBodyData?.data,
                              ...KYCPolicyNumber,
                              ...{
                                requestId:
                                  sbiQuickQuoteData?.data?.body?.data?.body
                                    ?.RequestHeader?.requestID,
                              },
                            },
                          })
                        );
                      } else {
                        //AUTO Kyc
                        await dispatch(
                          sbiKycAuto({
                            data: {
                              ...sbiBodyData?.data,
                              ...KYCPolicyNumber,
                              ...{
                                requestId:
                                  sbiQuickQuoteData?.data?.body?.data?.body
                                    ?.RequestHeader?.requestID,
                              },
                            },
                          })
                        );
                      }

                      if (transaction_url_id && PolicyNumber) {
                        //policyNo store
                        let bodyData = {
                          policyNo: PolicyNumber,
                          policytxnId: id,
                          status: "update_payment",
                          uuid: transaction_url_id
                        };
                        await dispatch(paymentSendURLAction(bodyData));
                      }

                      //adding loader
                      setLoading(true);
                      setTimeout(() => {
                        setLoading(false);
                        navigate(
                          `/Payment-Redirect?companyName=${companyName}&transaction_id=${id}&PolicyNumber=${PolicyNumber}`
                        );
                      }, 15000);
                    }
                  }
                }
              }
            } catch (rejectedValueOrSerializedError) {
              setLoading(false);
              alert(" Something went wrong !");
            }
          },
          prefill: {
            name: sbiBodyData?.firstName || "",
            email: sbiBodyData?.email || "",
            contact: sbiBodyData?.phoneNumber || "", //"9999999999",
          },
          notes: {
            address: sbiBodyData?.addressOne || "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };
        const rzpay = new Razorpay(options);
        rzpay.open();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong, Please try again !", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [Razorpay]);

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [loading]);

  const DownloadHdfcPdf = (() => {
    if (hdfcProposalDownload?.data?.data?.quote?.Resp_Policy_Document?.PDF_BYTES) {
      const linkSource = `data:application/pdf;base64,${hdfcProposalDownload?.data?.data?.quote?.Resp_Policy_Document?.PDF_BYTES}`;
      const downloadLink = document.createElement("a");
      const fileName = `${"hdfcproposaldocument"}.pdf`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      setIsDownloaded(true);
    }
  })

  return (
    <div className="main-collapsible">
      <ToastContainer />
      <div id="cover-spin" style={{ display: loading ? "" : "none" }}>
        <img src={loader2} className="gifLoader"></img>
      </div>

      {/* hdfc design */}
      <Modal isOpen={hdfcTermsPdf} style={customStyles}>
        <div id="hdfcTermspdf">
          <div>
            <img src={hdfctermsicon} alt="hdfctermsicon" />
            <h1>Terms and Conditions</h1>
          </div>

          <hr />

          <div>
            <p>
              Before proceeding to the payment, please ensure that you have reviewed the Proposal Document.
              It contains important information about your insurance coverage and terms.
              <br /><br />
              If you haven't read it yet, please download the document and review it thoroughly before continuing.
            </p>
          </div>

          <button
            onClick={() => {
              DownloadHdfcPdf()
            }}
            id="dgjdshjhdsfkgh"
            className="button btn_pForm_hdfc secondry_btn_hdfc"
          >
            Download Proposal Document
          </button>

          <p>
            By clicking "I Agree," you confirm that you have read and understood the Proposal Document
            and agree to our Terms and Conditions and Privacy Policy.
          </p>

          <div className="checkbox_container checkbox_container-modalinp">
            <CheckBox
              label="I have read and accept the Terms and Conditions and Privacy Policy."
              className="small_text small_textModal checkBox-label"
              type="checkbox"
              checked={true}
              changeEvent={(e) => HandleCheckBox(e)}
            />
          </div>

          <section className="btnContainer_pform">
            <button
              onClick={HandleCancelhdfcTermsPdf}
              className="button btn_pForm secondry_btn"
            >
              Cancel
            </button>

            {hdfcChecksumData?.isLoading ?
              <ButtonLoader className="btn_pForm" />
              : <button
                onClick={updateHdfcPayment}
                className="button btn_pForm"
                disabled={isDownloaded ? false : true} // Disable button until document is downloaded
              >
                I Agree
              </button>
            }
          </section>
        </div>
      </Modal>

      <div className="collapsible_container">
        {/* owner details */}

        <div className="collapsible_head_content">
          <div
            className={
              showDetailsOf === "ownerDetails"
                ? "collapsible_head collapsible_head_withBorder "
                : "collapsible_head"
            }
            onClick={(e) => toggleColapsible("ownerDetails")}
          >
            <div className="title-editbtn-container-collapsible">
              <p className="collapsible_head_title">
                {data.carOwner == "individualOwner"
                  ? "Owner Details"
                  : "Company Details"}
              </p>
              {
                showDetailsOf === "ownerDetails" && (
                  <Botton
                    icons={edit}
                    text="Edit"
                    event={(e) => HandleEditProposalDetails("ownerDetails")}
                    className="edit_btn edit_btn_pform-mobile"
                  />
                )
              }
            </div>
            <img
              src={arrow}
              className={
                showDetailsOf === "ownerDetails"
                  ? "arrow-collapsible-down arrow-collapsible-up"
                  : "arrow-collapsible-down"
              }
              alt=""
              srcSet=""
            />
          </div >
          <div
            className={
              showDetailsOf === "ownerDetails"
                ? "collapsible_content show_collapsible_content "
                : "collapsible_content"
            }
          >{!flagbutton &&
            <Botton
              icons={edit}
              text="Edit"
              event={(e) => {
                HandleEditProposalDetails("ownerDetails")
                localStorage.setItem('stepperValue', 'ownerDetail')
              }}
              className="edit_btn edit_btn_pform"
            />
            }

            <div className="collapsible_content_group">
              {data.carOwner == "individualOwner" ? (
                <div className="collapsibleTab">
                  <p className="collapsibleHead">Owner Name</p>
                  <p className="collapsibleDesc">
                    {data?.firstName}&nbsp;{data?.lastName}
                  </p>
                </div>
              ) : (
                <div className="collapsibleTab">
                  <p className="collapsibleHead">Company Name</p>
                  <p className="collapsibleDesc">{data?.companyName}</p>
                </div>
              )}
              <div className="collapsibleTab">
                <p className="collapsibleHead">Email ID</p>
                <p className="collapsibleDesc">{data?.email}</p>
              </div>
            </div>
            <div className="collapsible_content_group">
              <div className="collapsibleTab">
                <p className="collapsibleHead">Phone Number</p>
                <p className="collapsibleDesc">{data?.phoneNumber}</p>
              </div>
              {
                data?.kycPanNumber && (
                  <div className="collapsibleTab">
                    <p className="collapsibleHead">PAN Number</p>
                    <p className="collapsibleDesc">{data?.kycPanNumber?.toUpperCase()}</p>
                  </div>
                )
              }

            </div>
            {
              data.carOwner !== "individualOwner" && (
                <div className="collapsible_content_group">
                  {companyName !== 'acko' &&
                    <div className="collapsibleTab">
                      <p className="collapsibleHead">Date Of Incorporation</p>
                      <p className="collapsibleDesc">{companyName === "hdfc" ? hdfcKycDate ? hdfcKycDate : formattedDate : formattedDate}</p>
                    </div>
                  }

                  <div className="collapsibleTab">
                  </div>
                </div>
              )
            }
          </div>
        </div >

        {/* PERSONAL DETAILS */}

        {
          data.carOwner === "individualOwner" && companyName != "acko" && (
            <div className="collapsible_head_content">
              <div
                className={
                  showDetailsOf === "personalDetails"
                    ? "collapsible_head collapsible_head_withBorder"
                    : "collapsible_head"
                }
                onClick={(e) => toggleColapsible("personalDetails")}
              >
                <div className="title-editbtn-container-collapsible">
                  <p className="collapsible_head_title">Personal Details</p>
                  {
                    showDetailsOf === "personalDetails" && (
                      <Botton
                        icons={edit}
                        text="Edit"
                        event={(e) => HandleEditProposalDetails("personalDetails")}
                        className="edit_btn edit_btn_pform-mobile"
                      />
                    )
                  }
                </div>
                <img
                  src={arrow}
                  className={
                    showDetailsOf === "personalDetails"
                      ? "arrow-collapsible-down arrow-collapsible-up"
                      : "arrow-collapsible-down"
                  }
                  alt=""
                  srcSet=""
                />
              </div>
              <div
                className={
                  showDetailsOf === "personalDetails"
                    ? "collapsible_content show_collapsible_content "
                    : "collapsible_content"
                }
              >{!flagbutton &&
                <Botton
                  icons={edit}
                  text="Edit"
                  event={(e) => HandleEditProposalDetails("personalDetails")}
                  className="edit_btn edit_btn_pform"
                />
                }

                <div className="collapsible_content_group">
                  <div className="collapsibleTab">
                    <p className="collapsibleHead">Gender</p>
                    <p className="collapsibleDesc">{data?.gender}</p>
                  </div>
                  <div className="collapsibleTab">
                    <p className="collapsibleHead">Maritial Status</p>
                    <p className="collapsibleDesc">{data?.maritalStatus}</p>
                  </div>
                </div>
                <div className="collapsible_content_group">
                  <div className="collapsibleTab">
                    <p className="collapsibleHead">Date of Birth</p>
                    <p className="collapsibleDesc">{companyName === "hdfc" ? hdfcKycDate ? hdfcKycDate : formattedDate : formattedDate}</p>
                  </div>
                  <div className="collapsibleTab">
                    <p className="collapsibleHead">Nominee Name</p>
                    <p className="collapsibleDesc">{data?.nomineeName}</p>
                  </div>
                </div>
                <div className="collapsible_content_group">
                  <div className="collapsibleTab">
                    <p className="collapsibleHead">Age</p>
                    <p className="collapsibleDesc">{data?.nomineeAge}</p>
                  </div>
                  <div className="collapsibleTab">
                    <p className="collapsibleHead">Relation</p>
                    <p className="collapsibleDesc">{data?.nomineeRelation}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

        {/* ADDRESS DETAILS */}

        <div className="collapsible_head_content">
          <div
            className={
              showDetailsOf === "addressDetails"
                ? "collapsible_head collapsible_head_withBorder "
                : "collapsible_head"
            }
            onClick={(e) => toggleColapsible("addressDetails")}
          >
            <div className="title-editbtn-container-collapsible">
              <p className="collapsible_head_title">Address Details</p>
              {(sbiAutoKycData?.kycStatus !== "CKYCSuccess" && showDetailsOf === "addressDetails") && (
                <>
                  <Botton
                    icons={edit}
                    text="Edit"
                    event={(e) => HandleEditProposalDetails("addressDetails")}
                    className="edit_btn edit_btn_pform-mobile"
                  />
                </>
              )}
            </div>
            <img
              src={arrow}
              className={
                showDetailsOf === "addressDetails"
                  ? "arrow-collapsible-down arrow-collapsible-up"
                  : "arrow-collapsible-down"
              }
              alt=""
              srcSet=""
            />
          </div>
          <div
            className={
              showDetailsOf === "addressDetails"
                ? "collapsible_content show_collapsible_content "
                : "collapsible_content"
            }
          >
            {sbiAutoKycData?.kycStatus !== "CKYCSuccess" && (
              <>
                {!flagbutton &&
                  <Botton
                    icons={edit}
                    text="Edit"
                    event={(e) => HandleEditProposalDetails("addressDetails")}
                    className={`edit_btn edit_btn_pform`}
                  // isDisabledButton={(companyName == "hdfc" && ishdfcKycVariefied == 1) ? true : false}
                  />
                }
              </>
            )}

            <div className="collapsible_content_group">
              <div className="collapsibleTab collapsibleTab-column">
                <p className="collapsibleHead">Address Line 1</p>
                <p className="collapsibleDesc">{data?.addressOne}</p>
              </div>
              <div className="collapsibleTab collapsibleTab-column">
                <p className="collapsibleHead">Address Line 2</p>
                <p className="collapsibleDesc">{data?.addressTwo}</p>
              </div>
            </div>
            <div className="collapsible_content_group">
              <div className="collapsibleTab">
                <p className="collapsibleHead">State</p>
                <p className="collapsibleDesc">{data?.state}</p>
              </div>
              <div className="collapsibleTab">
                <p className="collapsibleHead">City</p>
                <p className="collapsibleDesc">{data?.city}</p>
              </div>
            </div>
            <div className="collapsible_content_group">
              <div className="collapsibleTab">
                <p className="collapsibleHead">Pin Code</p>
                <p className="collapsibleDesc">{data?.pincode}</p>
              </div>
              <div className="collapsibleTab"></div>
            </div>
          </div>
        </div>

        {/* VEHICLE DETAILS */}

        <div className="collapsible_head_content">
          <div
            className={
              showDetailsOf === "vehicleDetails"
                ? "collapsible_head collapsible_head_withBorder "
                : "collapsible_head"
            }
            onClick={(e) => toggleColapsible("vehicleDetails")}
          >
            <div className="title-editbtn-container-collapsible">
              <p className="collapsible_head_title">Vehicle Details</p>
              {
                showDetailsOf === "vehicleDetails" && (
                  <Botton
                    icons={edit}
                    text="Edit"
                    event={(e) => HandleEditProposalDetails("vehicleDetails")}
                    className="edit_btn edit_btn_pform-mobile"
                  />
                )
              }
            </div>
            <img
              src={arrow}
              className={
                showDetailsOf === "vehicleDetails"
                  ? "arrow-collapsible-down arrow-collapsible-up"
                  : "arrow-collapsible-down"
              }
              alt=""
              srcSet=""
            />
          </div>
          <div
            className={
              showDetailsOf === "vehicleDetails"
                ? "collapsible_content show_collapsible_content "
                : "collapsible_content"
            }
          >
            {!flagbutton &&
              <Botton
                icons={edit}
                text="Edit"
                event={(e) => HandleEditProposalDetails("vehicleDetails")}
                className="edit_btn edit_btn_pform"
              />
            }
            {
              mappingAllData[0]?.typeOfBusiness != "New" && (
                <div className="collapsible_content_group">
                  <div className="collapsibleTab">
                    <p className="collapsibleHead">Previous Policy Number</p>
                    <p className="collapsibleDesc">{data?.prevPolicyNumber}</p>
                  </div>
                  <div className="collapsibleTab">
                    <p className="collapsibleHead">Previous Policy Insurer</p>
                    <p className="collapsibleDesc">{data?.prevPolicyNameInsurer}</p>
                  </div>
                </div>
              )
            }
            <div className="collapsible_content_group">
              <div className="collapsibleTab">
                <p className="collapsibleHead">Chassis Number</p>
                <p className="collapsibleDesc">{data?.chassisNumber?.toUpperCase()}</p>
              </div>
              <div className="collapsibleTab">
                <p className="collapsibleHead">Engine Number</p>
                <p className="collapsibleDesc">{data?.engineNumber?.toUpperCase()}</p>
              </div>
            </div>
            <div className="collapsible_content_group">
              <div className="collapsibleTab">
                <p className="collapsibleHead">Car is on Loan?</p>
                <p className="collapsibleDesc">{data?.carIsInLoan}</p>
              </div>
              <div className="collapsibleTab">
                {data?.carIsInLoan === "Yes" && (
                  <>
                    <p className="collapsibleHead">Car Hypothecation</p>
                    <p className="collapsibleDesc">
                      {data?.carHypothecationCompany}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="btnContainer_pform btnContainer_pform_collapsible ">
          <Botton
            text="Back"
            className="button btn_pForm  secondry_btn hideInMobile"
            event={(e) => handleBackBtn("FormDetails")}
          />
          <Botton
            text={getBtnLoadingStatus == false || companyName == "hdfc" ? "Yes, Pay Now" : "Loading...."}
            className="button btn_pForm"
            isDisabledButton={
              false
              //  ( getBtnLoadingStatus == false  || companyName=="hdfc") ? getBtnLoadingStatus : true
            }
            event={handlePayment}
          />
        </div>
      </div>

      <form
        action="https://novaapi.shriramgi.com/Novapymt/MyDefaultCC.aspx" //PROD
        //action="https://novaapiuat.shriramgi.com/uatnovapymt/mydefaultcc.aspx" //UAT
        method="post"
        hidden
      >
        <input name="createdBy" value="LC331" />
        <input type="hidden" name="createdBy" value="LC331" />
        <input type="hidden" name="paymentFrom" value="CCAVENUE" />
        <input type="hidden" name="prodCode" value={shreeramProdCode} />
        <input type="hidden" name="QuoteId" value={shreeramPolicyid} />
        <input type="hidden" name="amount" value={shreeramPremium} />
        <input type="hidden" name="sourceUrl" value="" />
        <input type="hidden" name="DbFrom" value="NOVA" />
        <input type="hidden" name="application" value="ViaanshIns" />
        <input type="hidden" name="prodName" value="MOTOR" />
        <input type="submit" id="invisibleSubmitButton" value="Submit" />
      </form>
      {/*hidden form for hdfc*/}
      <form
        action="https://heapp21.hdfcergo.com/UAT/OnlineProducts/CCPGISUBSCRIBER/MakePayment.aspx"
        method="post"
        hidden
      >
        <input type="hidden" name="Trnsno" value={hdfcChecksumRes?.data?.data?.body?.TransactionNo} />
        <input type="hidden" name="Amt" value={hdfcChecksumRes?.data?.data?.body?.TotalAmount} />
        <input type="hidden" name="Appid" value={hdfcChecksumRes?.data?.data?.body?.AppID} />
        <input type="hidden" name="Subid" value={hdfcChecksumRes?.data?.data?.body?.SubscriptionID} />
        <input type="hidden" name="Surl" value={hdfcChecksumRes?.data?.data?.body?.SuccessUrl} />
        <input type="hidden" name="Furl" value={hdfcChecksumRes?.data?.data?.body?.FailureUrl} />
        <input type="hidden" name="Src" value={hdfcChecksumRes?.data?.data?.body?.Source} />
        <input type="hidden" name="Chksum" value={hdfcChecksumRes?.data?.data?.quote} />
        <input type="submit" id="invisibleSubmitButtonhdfc" value="Submit" />
      </form>
      {/* hidden form for zuno */}
      <form
        action={process.env.REACT_APP_ZUNO_BILLDESK_URL}
        method="post"
        hidden
      >
        <input type="hidden" name="msg" value="" />
        <input type="submit" id="invisibleSubmitButtonZuno" value="Submit" />
      </form>

      {/* hidden form for tata */}
      <form
        id="myForm"
        action=""
        method="post"
        hidden
      >
        <input type="hidden" name="pgiRequest" value="" />
        <input type="submit" id="invisibleSubmitButtonTata" value="Submit" />
      </form>

      {/* hidden form for national */}
      <form
        action={process.env.REACT_APP_NATIONAL_BILLDESK_URL}
        method="post"
        hidden
      >
        <input type="hidden" id="msgNational" name="msg" value="" />
        <input type="submit" id="invisibleSubmitButtonNational" value="Submit" />
      </form>

    </div>
  );
};

export default Collapsible;
