import React, { useState, useEffect } from "react";
import InputFields from "../InputFields/InputFields";
import Botton from "../Button/Button";
import DropDown from "../DropDown/DropDown";
import grayArrow from "../../Assets/Images/grayArrow.svg";
import { getStateAction } from "../../redux/Actions/stateDetails";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link, useNavigate, useHistory } from "react-router-dom";
import SearchSelect from "../DropDown/Search Select/SearchSelect";
import backIcon from "../../Assets/Images/backIcon.svg";
import { ACKO, TATA } from "../../utils/commonUtils";
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader";

const PropsalThree = (props) => {
  const dispatch = useDispatch();
  const [ShowStateDropdown, setShowStateDropdown] = useState(false);

  const {
    handleCityDropdown,
    addressOne,
    addressTwo,
    state,
    stateList,
    cityList,
    city,
    pincode,
    HandleInputChange,
    handleSubmitForm,
    ShowCityDropdown,
    handleBackBtn,
    handleCityValue,
    handleStateValue,
    formData,
    bodyData,
    Edit,
    errors,
    companyName,
    type,
    setFormData,
    hdfcKycData,
    sethdfcKycData
  } = props;

  console.log(companyName, "companyName")

  const indianStates = [
    { code: "AN", name: "ANDAMAN AND NICOBAR ISLANDS" },
    { code: "AP", name: "ANDHRA PRADESH" },
    { code: "AR", name: "ARUNACHAL PRADESH" },
    { code: "AS", name: "ASSAM" },
    { code: "BR", name: "BIHAR" },
    { code: "CH", name: "CHANDIGARH" },
    { code: "CT", name: "CHHATTISGARH" },
    { code: "DL", name: "DELHI" },
    { code: "GA", name: "GOA" },
    { code: "GJ", name: "GUJARAT" },
    { code: "HR", name: "HARYANA" },
    { code: "HP", name: "HIMACHAL PRADESH" },
    { code: "JK", name: "JAMMU AND KASHMIR" },
    { code: "JH", name: "JHARKHAND" },
    { code: "KA", name: "KARNATAKA" },
    { code: "KL", name: "KERALA" },
    { code: "LD", name: "LAKSHADWEEP" },
    { code: "MP", name: "MADHYA PRADESH" },
    { code: "MH", name: "MAHARASHTRA" },
    { code: "MN", name: "MANIPUR" },
    { code: "ML", name: "MEGHALAYA" },
    { code: "MZ", name: "MIZORAM" },
    { code: "NL", name: "NAGALAND" },
    { code: "OD", name: "ODISHA" },
    { code: "PB", name: "PUNJAB" },
    { code: "RJ", name: "RAJASTHAN" },
    { code: "SK", name: "SIKKIM" },
    { code: "TN", name: "TAMIL NADU" },
    { code: "TS", name: "TELANGANA" },
    { code: "TR", name: "TRIPURA" },
    { code: "UP", name: "UTTAR PRADESH" },
    { code: "UT", name: "UTTARAKHAND" },
    { code: "WB", name: "WEST BENGAL" }
  ];

  useEffect(() => {
    let dealer_code = localStorage.getItem('dealer_code')
    dealer_code && indianStates?.map((e) => {
      if (e.code == formData.state) {
        setFormData((prev) => ({
          ...prev,
          state: e.name
        }))
      }
    })
  }, [])


  const navigate = useNavigate();


  const handlePopUps = () => {
    if (ShowStateDropdown) {
      setShowStateDropdown(false);
    }
  };

  let vehicleType = bodyData?.data?.vehicleType;
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  let transactionStatus = useSelector((state) => {
    return state.getTransactionStatus;
  });

  let ishdfcKycVariefied = hdfcKycData?.data?.iskycVerified;
  // console.log(ishdfcKycVariefied,"hdfcKycDatahdfcKycData")

  return (
    <div>

      <div className="titleContainer titleContainer_pform ">
        <p>Address Details</p>
        <p>All fields are mandatory</p>
      </div>

      <form className="formSection-pform" action="" onClick={handlePopUps} onSubmit={(e) => { e.preventDefault(); }}>
        <div className="input_group_pform">
          <div className="inputGropOne_form inputGrop ">
            <InputFields
              value={addressOne}
              placeholder="Address Line 1"
              id="addressOne"
              type="text"
              name="addressOne"
              errState={errors?.addressOne ? true : false}
              errMsg={errors?.addressOne}
              errClassName="input-err"
              onChangeEvent={(e) => HandleInputChange(e)}
            // isDisabled={(companyName === "hdfc" && ishdfcKycVariefied===1 ) ? true : false}
            />
          </div>
          <div className="inputGropOne_form inputGrop ">
            <InputFields
              value={addressTwo}
              placeholder="Address Line 2"
              id="addressTwo"
              type="text"
              name="addressTwo"
              errState={errors?.addressTwo ? true : false}
              errMsg={errors?.addressTwo}
              errClassName="input-err"
              onChangeEvent={(e) => HandleInputChange(e)}
            // isDisabled={(companyName === "hdfc" && ishdfcKycVariefied===1 ) ? true : false}
            />
          </div>
        </div>

        <div className="input_group_pform">
          <div className="inputGropOne_form inputGrop ">
            <SearchSelect
              optionList={stateList}
              handleSelect={handleStateValue}
              value={state}
              ManualInpValue={formData}
              placeholder="State"
              label="State"
              name="state"
              errState={errors?.state ? true : false}
              errMsg={errors?.state}
              errClassName="input-err"
            // isDisabled={(companyName === "hdfc" && ishdfcKycVariefied===1 ) ? true : false}
            />
          </div>
          <div className="inputGropOne_form inputGrop ">
            <SearchSelect
              optionList={cityList}
              handleSelect={handleCityValue}
              value={city}
              ManualInpValue={formData}
              placeholder="City"
              label="City"
              name="city"
              errState={errors?.city ? true : false}
              errMsg={errors?.city}
              errClassName="input-err"
              isDisabled={(!formData?.state) ? true : false}
              id="stopdrop"
            />
          </div>
        </div>

        <div className="input_group_pform">
          <div className="inputGropOne_form inputGrop ">
            <InputFields
              value={pincode}
              placeholder="Pin Code"
              id="pincode"
              type="number"
              name="pincode"
              errState={errors?.pincode ? true : false}
              errMsg={errors?.pincode}
              errClassName="input-err"
              onChangeEvent={(e) => HandleInputChange(e)}
              isDisabled={companyName === TATA || companyName === ACKO ? true : false}
            />
          </div>
          <div className="inputGropOne_form inputGrop "></div>
        </div>

        <section className="btnContainer_pform btnContainer_pform_collapsible">
          <Botton
            text="Back"
            className="button btn_pForm  secondry_btn"
            event={() => handleBackBtn("AddressDetails")}
          />

          {transactionStatus?.isLoading ?
            <ButtonLoader className="btn_pForm" />
            : <Botton
              text="Save"
              type="submit"
              className="button btn_pForm  "
              event={(e) =>
                handleSubmitForm(Edit ? "kycDetails" : "AddressDetails", e, "editAddressDetails")
              }
            />}
        </section>
      </form>
    </div>
  );
};

export default PropsalThree;
