import React, { useState, useEffect, useRef, useCallback } from "react";
import "./ViewPlans.css";
import Testimonials from "../Testimonials/Testimonials";
import Partners from "../Partners/Partners";
import Accordian from "../Accordian/Accordian";
import Paper from "./Paper";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CheckBox from "../Checkbox/CheckBox";
import AddonContainer from "./AddonContainer";
import QuotesContainer from "../QuotesContainer/QuotesContainer";
import Footer from "../Footer/Footer";
import moment from "moment";
import PlancardError from "../QuotesContainer/PlancardError";
import { transactionURLAction } from "../../redux/Actions/TransactionAction";
import { commonVehicleFetchApiAction } from '../../redux/Actions/commonVehicleFetchApiAction'
import { updateVehicleInfoAction } from "../../redux/Reducers/commonVehicleFetchApiReducer";
import {
  calculatePrevNcbNewNcb,
  getPolicyType,
  getPolicyExpiryDate,
  calculateDate,
  FINDMYCAR,
} from "../../utils/commonUtils";
import {
  getModelAction,
  getFuelTypeAction,
  getVariantAction,
  getCarBrandAction,
} from "../../redux/Actions/mmvAction";
import { allBodyDataAction } from "../../redux/Reducers/bodyDataReducer";
import ToolTip from "./ToolTip";
import { ROLLOVER, NEW } from "../../utils/commonUtils";
//zuno 4W
import {
  zunoFullQuoteAction,
  zunoQuickQuoteAction,
} from "../../redux/Actions/zunoApiAction";
import { shreeRamQuickQuoteAction } from "../../redux/Actions/shreeRamApiAction";
import { digitQuickQuoteAction, digitQuickQuoteAction1, digitQuickQuoteAction2, digitQuickQuoteAction3, digitQuickQuoteAction4, digitQuickQuoteAction5, digitQuickQuoteAction6, digitQuickQuoteAction7, digitQuickQuoteAction8, digitQuickQuoteAction9, digitQuickQuoteAction10, digitQuickQuoteAction11 } from "../../redux/Actions/digitApiAction";
import {
  sbiQuickQuoteAction,
} from "../../redux/Actions/sbiApiAction";
import { iciciQuickQuoteAction, iciciQuickQuoteAction1, iciciQuickQuoteAction2, iciciQuickQuoteAction3, iciciQuickQuoteAction4, iciciQuickQuoteAction5, iciciQuickQuoteAction6, iciciQuickQuoteAction7, iciciQuickQuoteAction8, iciciQuickQuoteAction9, iciciQuickQuoteAction10, iciciQuickQuoteAction11 } from "../../redux/Actions/iciciApiAction";
import {
  ackoQuickQuoteAction,
} from "../../redux/Actions/ackoApiAction";
// TATA 4W
import {
  tataQuickQuoteAction,
  tataQuickQuoteActionWithAddOn
} from "../../redux/Actions/tataApiAction";
// united 4W
import {
  unitedQuickQuoteAction
} from "../../redux/Actions/unitedApiAction";
// nationl
import {
  nationalQuickQuoteAction
} from "../../redux/Actions/nationalApiAction";
import { hdfcQuickQuoteAction } from "../../redux/Actions/hdfcApiAction";
import { alladdOnDataDataAction } from "../../redux/Reducers/addOnsDataReducer";
import Navbar from "../Navbar/Navbar";
import Botton from "../Button/Button";
import edit from "../../Assets/Images/document.svg";
import arrow from "../../Assets/Images/arrow.svg";
// import hdfctermsicon from "../../Assets/Images/hdfctermsicon.png";
import info from "../../Assets/Images/info.svg";
import close from "../../Assets/Images/Close.svg";
import EditDetails from "./EditDetails";
import { format } from "date-fns-tz";
import AOS from "aos";
import "aos/dist/aos.css";
import CustomCalendar from "../InputFields/Calendar/Calendar";
import Modal from "react-modal";
import CustomDateField from "../InputFields/CustomDateField/CustomDateField";
import loader2 from "../../Assets/Loader/Loader.gif";
import { isInitialAction } from "../../redux/Reducers/inspectionReducer";
import { isViewPlanFlowAction } from "../../redux/Reducers/isViewPlanFlowCompleted";
import CallWidget from "../Widgets/CallWidget";
import IdvTab from "./IdvTab";
import rightArrow from "../../Assets/Images/arrRight.svg"
import SkeletonLoader from "../Pages/Loader/Skeleton loader/SkeletonLoader";
import { phonevalidationAction } from "../../redux/Actions/shareLinkAction";

const ViewPlans = (props) => {
  const {
    firstLot,
    secondLot,
    carQueries,
    bikeQueries,
    healthQueries,
    taxiQueries,
    testimonials,
    sbiQUickQuoteData,
    setCalendarReveal,
    CompanyName,
    prevPolicyODexpDate,
  } = props;

  useEffect(() => {
    AOS.init({
      duration: 200,
    });
    AOS.refresh();
  }, []);

  const [MakeList, setMakeList] = useState([]);
  const [DigitAddon, setDigitAddon] = useState(false)
  const search = useLocation().search;
  const transactionId = new URLSearchParams(search).get("id");
  const dealerCode = new URLSearchParams(search).get("Franchise_ID");
  const [digitAddonsplan, setdigitAddonsplan] = useState({})
  const [NoRespforCom, setNoREspforCom] = useState(false);
  // const [hdfcTermsPdf, sethdfcTermsPdf] = useState(true)

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  let storageVehicleNumber = localStorage.getItem("vehicleNumber");
  let storageBusinessType = localStorage.getItem("businessType");
  let storageisFindMyCar = localStorage.getItem("isFindMyCar");
  let mobileNo = localStorage.getItem("mobileNo");
  let pincode = localStorage.getItem("pincode");
  const [DigitPlan, setDigitPlan] = useState([]);
  const [ICICIPlan, setICICIPlan] = useState([]);
  let [updateCarDetails, setUpdateCarDetails] = useState(false);
  const [upDateCarDetailsConfirm, setUpdateCarDetailsConfirm] = useState(false)
  // const [stopApi, setstopApi] = useState(false);

  // const customStyles = {
  //   content: {
  //     top: '50%',
  //     left: '50%',
  //     right: 'auto',
  //     bottom: 'auto',
  //     marginRight: '-50%',
  //     transform: 'translate(-50%, -50%)',
  //     width: window.innerWidth > 610 ? '34%' : '90%', 
  //   },
  // };

  useEffect(() => {
    const asyncFn = async () => {
      let bikeBrand = await dispatch(getCarBrandAction());
      setMakeList(bikeBrand?.payload?.data?.vehicelinfo);
    };
    asyncFn();
  }, []);

  let findmycar = localStorage.getItem('findmycar');

  let [businessType, setBusinessType] = useState(
    findmycar ? "FindMyCar" : location?.state?.businessType
      ? location?.state?.businessType
      : storageBusinessType
  );

  let vehicleNumber = location?.state?.vehicleNumber?.length > 4
    ? location?.state?.vehicleNumber
    : storageVehicleNumber || "";
  let isFindMyCar = location?.state?.isFindMyCar
    ? location?.state?.isFindMyCar
    : storageisFindMyCar
      ? JSON.parse(storageisFindMyCar)
      : "";

  let policyTypeList = [];

  const vehicleData = useSelector((state) => {
    return businessType == ROLLOVER
      ? state?.getVehicleData?.data?.data
        ? state?.getVehicleData?.data?.data.hasOwnProperty("data")
          ? state?.getVehicleData?.data?.data?.data
          : state?.getVehicleData?.data?.data
        : state?.getVehicleData?.data
      : state?.getVehicleData?.data?.data?.data;
  });

  const [ncbCopy, setncbCopy] = useState({
    newNcb: 0,
    prevNcb: 0,
  });

  const [ncbData, setncbData] = useState(
    !ncbCopy?.newNcb
      ? calculatePrevNcbNewNcb(
        vehicleData?.regn_dt,
        vehicleData?.isClaimPrevPolicy,
        businessType,
        vehicleData?.prevPolicyExpLastMonth
      )
      : {
        newNcb: 0,
        prevNcb: 0,
      }
  );

  const [showInsDropDown, setshowInsDropDown] = useState(false);
  const [showPolicyDropdown, setshowPolicyDropdown] = useState(false);
  const [showAddonMenu, setshowAddonMenu] = useState(false);
  const [IdvValue, setIdvValue] = useState();
  const [minIdvValue, setminIdvValue] = useState({
    sbi: 0,
    digit: 0,
    zuno: 0,
    shreeram: 0,
    icici: 0,
    hdfc: 0,
    acko: 0,
  });
  const [maxIdvValue, setmaxIdvValue] = useState({
    sbi: 0,
    digit: 0,
    zuno: 0,
    shreeram: 0,
    icici: 0,
    hdfc: 0,
    acko: 0,
  });
  const [allIDVValues, setAllIDVValues] = useState({
    min: 0,
    max: 0,
  });
  const [onBLurIdbValue, setOnBLurIdbValue] = useState(1000000);
  const [PolicyTypeValue, setPolicyTypeValue] = useState(
    getPolicyType(
      vehicleData?.regn_dt,
      businessType !== NEW
        ? new Date(
          getPolicyExpiryDate(
            vehicleData?.isPrevPolicyExp,
            vehicleData?.prevPolicyExpLastMonth
          )
        )
        : new Date()
    )
  );
  const [ShowPassengerAmtList, setShowPassengerAmtList] = useState(false);
  const [ShowAccesoriesField, setShowAccesoriesField] = useState(false);
  const [ShowPcSumInsuredField, setShowPcSumInsuredField] = useState(false);
  const [MinDateValue, setMinDateValue] = useState("");
  const [MaxDateValue, setMaxDateValue] = useState("");
  const [minRegDate, setminRegDate] = useState("");
  const [maxRegDate, setmaxRegDate] = useState("");
  const [notWorkingAddons, setNotWorkingAddons] = useState([]);
  const [PlancardErrorData, setPlanCardErrorData] = useState([]);
  const [CalendarDeactiveFlag, setCalendarDeactiveFlag] = useState(false);
  const getPreviousAddonData = useSelector((state) => {
    return state?.addOnData?.data;
  });
  const [ElectricalAmt, setElectricalAmt] = useState(null);
  const [NonElectricalAmt, setNonElectricalAmt] = useState(null);
  const [PassengerCoverAmt, setPassengerCoverAmt] = useState(null);
  const [policyTypeOption, setPolicyTypeOption] = useState([
    { label: "Comprehensive", value: "Comprehensive", desc: "Own Damage + Third Party" },
    { label: "Third Party", value: "Third Party", desc: "Third Party Only" },
  ]);
  const [EditMmvMobile, setEditMmvMobile] = useState(false);
  const [EditPolicyTypeMobile, setEditPolicyTypeMobile] = useState(false);
  const [EditNcbMobile, setEditNcbMobile] = useState(false);
  const [AddonsForMobile, setAddonsForMobile] = useState(false);
  const [ShowBottomTab, setShowBottomTab] = useState(true);
  const [ShowIdvTab, setShowIdvTab] = useState(false);
  const [ShowRegDateCalendar, setShowRegDateCalendar] = useState(false);
  const [ShowRegDateCalendarModal, setShowRegDateCalendarModal] = useState(false);
  const [ShowExpDateCalendar, setShowExpDateCalendar] = useState(false);
  const [ShowExpDateCalendarModal, setShowExpDateCalendarModal] = useState(false);
  const [ShowOdExpDateCalendar, setShowOdExpDateCalendar] = useState(false);
  const [PrevPolicyTypeList, setPrevPolicyTypeList] = useState(false);
  const [ShowOdExpDateCalendarModal, setShowOdExpDateCalendarModal] = useState(false);
  const [RegDateClone, setRegDateClone] = useState(null);
  const [OdExpDateClone, setOdExpDateClone] = useState(null);
  const [ExpDateClone, setExpDateClone] = useState(null);
  const [ShowLoader, setShowLoader] = useState(false);
  const [LoaderFromConfirmPg, setLoaderFromConfirmPg] = useState(false);
  const [CompanyWiseLoader, setCompanyWiseLoader] = useState(false);
  const [ShowFlowExpCalendar, setShowFlowExpCalendar] = useState(false);
  const [ShowFlowOdCalendar, setShowFlowOdCalendar] = useState(false);
  const [ShowAlternateExpOptions, setShowAlternateExpOptions] = useState(false);
  const [SelectedAlternateExpOption, setSelectedAlternateExpOption] = useState(null);
  const [ShowNumber, setShowNumber] = useState(false);
  const [ShowSkeleton, setShowSkeleton] = useState(false);
  const [ShowNilDepModal, setShowNilDepModal] = useState(false);
  const [ShowEngProtectModal, setShowEngProtectModal] = useState(false);
  const [ShowRtiModal, setShowRtiModal] = useState(false);
  const [IsRegDateActive, setIsRegDateActive] = useState(false);
  const [ShowPackageModal, setShowPackageModal] = useState(false);
  const [ShowDigPackageModal, setShowDigPackageModal] = useState(false);
  const [ShowICICIPackageModal, setShowICICIPackageModal] = useState(false);
  const [IsActiveFromPlanCard, setIsActiveFromPlanCard] = useState(false);
  const [addonsfordigit, setaddonsfordigit] = useState(null)
  const [addonsforIcici, setaddonsforIcici] = useState(null)
  const [ShowMmvVerifyTab, setShowMmvVerifyTab] = useState(false);
  const [RenderEditFields, setRenderEditFields] = useState(null);

  // get shreeRamAddon Data from store
  const shreeRamQuote = useSelector((state) => {
    return state?.shreeRamQuickQuoteReducerWithAddon;
  });

  let carsAge = "";
  let width = window.innerWidth;

  const policyTypeOptionForNew = [
    {
      label: "Bundled",
      value: "Bundled",
    },
  ];

  // getting inspection details
  const inspectDetails = useSelector((state) => {
    return state?.inspectDetails?.data?.isInspectionReq;
  });

  // GETTING THE STATUS OF THE VIEWPLANS FLOW POPUP
  const getviewPlanFlowStatus = useSelector((state) => {
    return state?.getviewPlanFlowStatus?.data?.isViewPlanFlowCompleted;
  });

  // logic for loader as per the company name :->
  let getCompanyName = useSelector((state) => {
    return state?.companyName?.data?.companyName;
  });

  let sbiQuickQuoteStatus = useSelector((state) => {
    return state?.sbiQuickQuote?.isLoading;
  });

  let digitQuickQuouteStatus = useSelector((state) => {
    return state?.digitQuickQuote?.isLoading;
  });

  let iciciQuickQuoteStatus = useSelector((state) => {
    return state?.iciciQuickQuote?.isLoading;
  });

  let hdfcQuickQuoteStatus = useSelector((state) => {
    return state?.hdfcQuickQuote?.isLoading
  });

  let ackoQuickQuoteStatus = useSelector((state) => {
    return state?.ackoQuickQuote?.isLoading;
  });

  let shreeramQuickQuoteStatus = useSelector((state) => {
    return state?.shreeRamQuickQuote?.isLoading;
  });

  let zunoQuickQuoteStatus = useSelector((state) => {
    return state?.zunoQuickQuote?.isLoading;
  });

  let PlansData = useSelector((state) => {
    return state?.digitQuickQuote12;
  })



  useEffect(() => {
    switch (getCompanyName) {
      case "sbi":
        setCompanyWiseLoader(sbiQuickQuoteStatus);
        break;
      case "digit":
        setCompanyWiseLoader(digitQuickQuouteStatus);
        break;
      case "icici":
        setCompanyWiseLoader(iciciQuickQuoteStatus);
        break;
      case "hdfc":
        setCompanyWiseLoader(hdfcQuickQuoteStatus);
        break;
      case "zuno":
        setCompanyWiseLoader(zunoQuickQuoteStatus);
        break;
      case "acko":
        setCompanyWiseLoader(ackoQuickQuoteStatus);
        break;
      case "shreeram":
        setCompanyWiseLoader(shreeramQuickQuoteStatus);
        break;
      default:
        break;
    }
  }, [
    getCompanyName,
    sbiQuickQuoteStatus,
    digitQuickQuouteStatus,
    iciciQuickQuoteStatus,
    hdfcQuickQuoteStatus,
    ackoQuickQuoteStatus,
    shreeramQuickQuoteStatus,
    zunoQuickQuoteStatus,
  ]);

  // insurer list
  let insurerList = [
    {
      label: "Acko",
      value: "Acko",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Bajaj Allianz",
      value: "Bajaj Allianz",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Chola MS",
      value: "Chola MS",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Digit",
      value: "Digit",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Edelweiss",
      value: "Edelweiss",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Future Generali",
      value: "Future Generali",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "HDFC Ergo",
      value: "HDFC Ergo",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Iffco Tokio",
      value: "Iffco Tokio",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "ICICI Lombard",
      value: "ICICI Lombard",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Kotak",
      value: "Kotak",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Magma HDI",
      value: "Magma HDI",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "National Insurance",
      value: "National Insurance",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Navi Insurance",
      value: "Navi Insurance",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Oriental Insurance",
      value: "Oriental Insurance",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Royal Sundaram",
      value: "Royal Sundaram",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Raheja Qbe",
      value: "Raheja Qbe",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Reliance",
      value: "Reliance",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "SBI General",
      value: "SBI General",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Sompo Insurance",
      value: "Sompo Insurance",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Tata AIG",
      value: "Tata AIG",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "The New India Assurance",
      value: "The New India Assurance",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "United India",
      value: "United India",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Liberty",
      value: "Liberty",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
  ];

  // addons list
  let data = [
    {
      id: "checkbox1",
      label: "Mandate by IRDAI",
      name: "paCover",
      value: "PA Cover",
      className: "small_text small_textModal ",
      classNameTwo: "viewPlan_checkbox",
      tooltip: `A compulsory personal accident or PA cover is an add-on cover that provides protection against any accidental injuries to the owner-driver of an insured car. It provides compensation in case the owner-driver of the car suffers from bodily injuries, permanent disability or death following the car accident.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox2",
      label: "Zero Depreciation",
      name: "zeroDep",
      value: "Zero Dep",
      className: "small_text small_textModal ",
      classNameTwo: "viewPlan_checkbox",
      tooltip: `Zero depreciation cover is also known as bumper-to-bumper or nil depreciation cover. With zero depreciation coverage, the policyholder does not have to pay the depreciation value of the damaged or replaced parts and can claim the full amount under their comprehensive car insurance.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox3",
      label: "Consumables",
      name: "consumables",
      value: "Consumables",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `Covers expenses incurred towards Consumables (nut and bolt, screw, washers, grease, lubricants clip, ac gas, bearings, distilled water, engine oil, oil filter, fuel filter, break oil and the like) arising out of damage to the vehicle.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox4",
      label: "24/7 Road Assistance",
      name: "roadAssistance",
      value: "24/7 Road Assistance",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `Road side assistance provides support for basic on-road breakdown situations like tyre change, battery jump start, emergency fuel, medical assistance etc which are not covered under Insurance. As the price is very nominal, it is a good to have add-on.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox5",
      label: "Engine Protection",
      name: "engineProtection",
      value: "Engine Protection",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `When the Engine of the car is submerged in a water logged area, using or cranking the engine can result in engine ceasing. This is not covered under regular Insurance. Engine protector covers such non-accidental exclusions related to your engine. It is a must buy for luxury cars where engine is very costly & is placed at low ground clearance.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox6",
      label: "Invoice Cover",
      name: "invoiceCover",
      value: "Invoice Cover",
      className: "small_text",
      classNameTwo: "",
      tooltip: `In case of theft or total damage to your car, you are only eligible for reimbursement up to the Insured declared value of your car, which will be very less than the Invoice value. In case of such an event, selecting Invoice cover makes you eligible for full Invoice amount reimbursement.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox7",
      label: "Key and Lock Replacement",
      name: "keyLockReplacement",
      value: "Key and Lock Replacement",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `In case your keys are lost or stolen, you have to request a new one from the manufacturer. In most cases, you may even need to replace the locks, which is another added expenditure. Key and Lock Replacement Cover covers the expenses incurred for procuring a new key. It is a must buy for high end and luxury cars as the new set of keys can be very expensive.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox8",
      label: "NCB Protection",
      name: "ncbProtection",
      value: "NCB Protection",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `An NCB Protect cover gives you extra cushioning. It keeps the bonus component intact even if some claims (depending upon the terms and conditions) are raised during the policy period. You can opt for NCB protection cover to benefit from the renewal discount irrespective of a claim (or claims) during the policy period`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox10",
      label: "Driver Cover",
      name: "driverCover",
      value: "Driver Cover",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `It covers medical costs incurred in the treatment of the injured driver and passengers. It gives financial aid in case of the death of owner-driver/passengers. It provides disability liability cover to the owner-driver and passengers of an insured car.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox11",
      label: "Passenger Cover",
      name: "passengerCover",
      value: "Passenger Cover",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `Passenger cover in car insurance is financial coverage for passengers who are travelling in the insured car. While it is necessary to have the personal accident cover for the owner-driver of the car, covering passengers is not mandatory.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox12",
      label: "Accessories",
      name: "accessories",
      value: "Accessories",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `Cover your car extra fitted electrical and non-electrical accessories.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox13",
      label: "Tyre Protection",
      name: "tyreProtection",
      value: "tyreProtection",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `A tyre protection cover is a car insurance add-on cover that gives coverage for the repair and replacement cost of tyres. This add-on cover provides insurance cover for accidental loss and damage or cuts to the tyres in addition to the labour charges incurred during the repair or replacement of tyres.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox14",
      label: "Motor Protection",
      name: "shreeramProtect",
      value: "shreeramProtect",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `In case of any motor accident arising out of the use of the Insured Vehicle, We will provide you an advocate to guide and/or defend you from police and proceedings in the court of judicial magistrate only.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    // {
    //   id: "checkbox14",
    //   label: "EMI Protection",
    //   name: "emiProtection",
    //   value: "emiProtection",
    //   className: "small_text ",
    //   classNameTwo: "",
    //   tooltip: `EMI Protection Cover: UIN 1RDAN123A0002V01201718 In consideration of payment of additional premium, the insured will be paid regular Equated Monthly Instalment (EMI) payable to the financier of the vehicle recorded in our books due to an accident involving the insured vehicle.`,
    //   checked: false,
    //   changeEvent: (e) => handleCheckboxChange(e),
    // },
    {
      id: "checkbox15",
      label: "Loss of Personal Belongings",
      name: "lossofPersonalBelongings",
      value: "lossofPersonalBelongings",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `Personal belongings add on provides you coverage against such belongings kept in your car. While theft of a car is covered, theft of the items kept in the car is not. Personal belongings might include items such as laptop, smartphone, camera, musical instruments, etc.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox16",
      label: "Daily Allowance",
      name: "dailyAllowance",
      value: "dailyAllowance",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `The daily allowance add-on cover for car insurance can be purchased with a maximum coverage duration. It basically means that the insurance company will pay you the compensation for a fixed number of days that is mentioned in the policy document or till the time your car gets repaired, whichever happens earlier.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
  ];

  if (getPreviousAddonData?.coverage?.length) {
    data = getPreviousAddonData?.coverage[0];
  }

  // set Reg Date seven days more from current date
  function DateFun(x) {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 7);
    currentDate.setFullYear(x);
    return currentDate
  }

  let getCalculatedDate = calculateDate(
    PolicyTypeValue,
    businessType !== NEW
      ? new Date(
        getPolicyExpiryDate(
          vehicleData?.isPrevPolicyExp,
          vehicleData?.prevPolicyExpLastMonth
        )
      )
      : "",
    businessType == FINDMYCAR ? ROLLOVER : businessType,
    businessType !== NEW
      ? DateFun(vehicleData?.regn_dt)
      : vehicleData?.policyStartDate
  );

  const [ExpiryDate, setExpiryDate] = useState(
    businessType !== NEW
      ? new Date(
        getPolicyExpiryDate(
          vehicleData?.isPrevPolicyExp,
          vehicleData?.prevPolicyExpLastMonth
        )
      )
      : null
  );

  let testDateFormatRegDate = new Date(vehicleData?.policyStartDate);

  const formatDate = (date) => {
    if (date) {
      return format(date, "yyyy-MM-dd'T'HH:mm:ssXXX", {
        timeZone: "Asia/Kolkata",
      });
    }
  };

  const [test, setTest] = useState(data);
  const [CoverageClone, setCoverageClone] = useState(data);
  const [test2, setTest2] = useState(data);

  let obj = test.map((item) => {
    if (item.name !== 'passengerCover' && item.name !== "accessories") {
      return ({ ...item, checked: true })
    } else {
      return item
    }
  });

  async function phonevalidation() {
    dispatch(
      phonevalidationAction({
        data: {
          phoneNumber: vehicleData?.mobileNo ? vehicleData?.mobileNo : mobileNo,
          email: null
        }

      })
    );

  }

  useEffect(() => {
    phonevalidation()
  }, [])

  const [carDetails, setCarDetails] = useState();

  const [formattedRegDate, setformattedRegDate] = useState(new Date()?.toISOString().split('T')[0]);
  const [formattedODexpDate, setformattedODexpDate] = useState(new Date()?.toISOString().split('T')[0]);
  const [formattedpolicyExpDate, setformattedpolicyExpDate] = useState(new Date()?.toISOString().split('T')[0]);


  let count = test.filter((data) => {
    return data.checked == true;
  });

  const getModelValue = useSelector((state) => {
    return state?.getModel;
  });

  const ModelList = getModelValue?.data?.data?.vehicelinfo;

  const ModelListArray = ModelList?.map((item, key) => ({
    label: item.Model,
    value: item.Model,
  }));

  const FuelList = useSelector((state) => {
    return state?.getFuel?.data?.data?.vehicelinfo;
  });

  const variantListResponse = useSelector((state) => {
    return state?.getVariant?.data?.data?.vehicelinfo;
  });

  const FuelTypeList = FuelList?.map((item, key) => ({
    label: item.Fuel_Type,
    value: item.Fuel_Type,
  }));

  const VarientList = variantListResponse?.map((item, key) => ({
    label: item.Variant + " " + "(" + item.Cubic_Capacity + " cc" + ")",
    value: item.Variant,
  }));

  const ccListD = variantListResponse?.map((item, key) => ({
    label: item.Cubic_Capacity,
    value: item.Cubic_Capacity,
  }));

  const uniqueSet = new Set();

  ccListD?.forEach((item) => {
    if (item.label === item.value) {
      uniqueSet.add(JSON.stringify(item));
    }
  });

  const ccList = Array.from(uniqueSet, (item) => JSON.parse(item));

  const carDetailsCopy = {
    vehicleNumberCopy: carDetails?.vehicleNumber?.toUpperCase() || "",
    makeCopy: carDetails?.make || "",
    modelCopy: carDetails?.model || "",
    varientCopy: carDetails?.varient || "",
    fuelTypeCopy: carDetails?.fuelType || "",
    ccCopy: carDetails?.cc || "",
    regDateCopy: carDetails?.policyRegDate || "",
    prevPolicyTypeCopy: carDetails?.prevPolicyType || "",
    policyExpCopy: carDetails?.policyExpDate || "",
    odPolicyExpCopy: carDetails?.prevPolicyODexpDate || "",
    tpPolicyExpCopy: carDetails?.prevPolicyTPexpDate || "",
    isClaimPrevPolicyCopy: carDetails?.isClaimPrevPolicy,
    prevNcbCopy: carDetails?.prevNcb,
    newNcbCopy: carDetails?.newNcb,
    electricalAccesoriesCopy: carDetails?.electricalAccesories || "",
    nonElectricalAccesoriesCopy: carDetails?.nonElectricalAccesories || "",
    policyTypeValueCopy: carDetails?.PolicyTypeValue || "",
    idvValueCopy: carDetails?.idvValue || "",
    quotePackageNameCopy: carDetails?.quotePackageName || "",
    quotePackageNameDigitCopy: carDetails?.quotePackageNameDigit || "",
    quotePackageNameICICICopy: carDetails?.quotePackageNameICICI || "",
    cngKitValuecopy: carDetails?.cngKitValue || "",
    isCngLpgVehicleTypecopy: carDetails?.isCngLpgVehicleType || false,

  };


  const [carDetailsCopyState, setcarDetailsCopyState] = useState(carDetailsCopy);


  useEffect(() => {
    setRegDateClone(carDetailsCopyState?.regDateCopy);
  }, [carDetailsCopyState?.regDateCopy]);

  useEffect(() => {
    setOdExpDateClone(carDetailsCopyState?.odPolicyExpCopy);
  }, [carDetailsCopyState?.odPolicyExpCopy]);

  useEffect(() => {
    setExpDateClone(carDetailsCopyState?.policyExpCopy);
  }, [carDetailsCopyState?.policyExpCopy]);

  const [errors, setErrors] = useState({});

  const handleApplyClick = (e, fieldName) => {
    if (fieldName === "Accessories") {
      if (!ElectricalAmt && !NonElectricalAmt) {
        setErrors({
          ...errors,
          accesories: "Please select at least one accessory",
        });
      } else {
        let checked = true;

        setCarDetails((prevData) => ({
          ...prevData,
          electricalAccesories: ElectricalAmt,
          nonElectricalAccesories: NonElectricalAmt,
        }));
        setShowAccesoriesField(false);
        if (ShowAccesoriesField) {
          setTest((prevCheckboxes) =>
            prevCheckboxes.map((checkbox) =>
              checkbox.id === "checkbox12" ? { ...checkbox, checked } : checkbox
            )
          );
        }
      }
    } else if (fieldName === "PassengerCover") {
      // Check if PassengerCoverAmt is not null or undefined
      if (PassengerCoverAmt !== null && PassengerCoverAmt !== undefined) {
        let checked = true;
        setCarDetails((prevData) => ({
          ...prevData,
          pcCoverSumInsured: PassengerCoverAmt,
        }));
        setShowPcSumInsuredField(false);
        if (ShowPcSumInsuredField) {
          setTest((prevCheckboxes) =>
            prevCheckboxes.map((checkbox) =>
              checkbox.id === "checkbox11" ? { ...checkbox, checked } : checkbox
            )
          );
        }
      } else {
        // Handle the case where PassengerCoverAmt is null or undefined
        setErrors({
          ...errors,
          passengerCover: "Passenger amount is required",
        });
      }
    } else {
      console.log("jsjsjs");
    }
  };

  const handleCancelPassenger = () => {
    setShowPcSumInsuredField(false);
    setPassengerCoverAmt(null);
  };

  const cancelAccessoriesField = () => {
    setShowAccesoriesField(false);
    setElectricalAmt(null);
    setNonElectricalAmt(null);
  };

  useEffect(() => {
    const makeObj = {
      responseMakeName: carDetailsCopyState?.makeCopy,
      id: null,
      modelName: carDetailsCopyState?.modelCopy,
      selectedFuel: carDetailsCopyState?.fuelTypeCopy,
    };

    const matchingCar = MakeList?.find(
      (car) =>
        car?.make?.toLowerCase() === makeObj?.responseMakeName?.toLowerCase()
    );
    if (matchingCar) {
      makeObj.id = matchingCar?.make;
    }
    dispatch(getModelAction(makeObj?.id));
    dispatch(getFuelTypeAction(makeObj));
    dispatch(getVariantAction(makeObj));
  }, [
    // duplicate keyWords
    MakeList,
    carDetailsCopyState?.makeCopy,
    carDetailsCopyState?.modelCopy,
    carDetailsCopyState?.fuelTypeCopy,
    carDetailsCopyState?.varientCopy,
  ]);

  const handleMakeSelect = (e) => {
    setcarDetailsCopyState((prevItems) => ({
      ...prevItems,
      makeCopy: e.value,
      modelCopy: null,
      varientCopy: null,
      fuelTypeCopy: null,
      ccCopy: null,
    }));
  };

  const handleModelSelect = (e) => {
    setcarDetailsCopyState((prevItems) => ({
      ...prevItems,
      modelCopy: e.value,
      varientCopy: null,
      fuelTypeCopy: null,
      ccCopy: null,
    }));
  };

  const handleFuelSelect = (e) => {
    setcarDetailsCopyState((prevItems) => ({
      ...prevItems,
      fuelTypeCopy: e.value,
      varientCopy: null,
      ccCopy: null,
    }));
  };

  const handleVariantSelect = (e) => {
    let getCC = variantListResponse?.find(
      (variantList) => variantList?.Variant === e.value
    );
    setcarDetailsCopyState((prevItems) => ({
      ...prevItems,
      varientCopy: e?.value,
      ccCopy: getCC?.Cubic_Capacity,
    }));
  };

  const handleCCSelect = (e) => {
    setcarDetailsCopyState((prevItems) => ({
      ...prevItems,
      ccCopy: e.value,
    }));
  };

  const handleCopiedDetails = (e, constName, newNcb) => {
    // if (e) {
    //   e.preventDefault()
    // }

    const { name, id, value } = e?.target || {};
    if (name === "makeCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        makeCopy: value,
        modelCopy: null,
        varientCopy: null,
        fuelTypeCopy: null,
        ccCopy: null,
      }));
    } else if (name === "modelCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        modelCopy: value,
        varientCopy: null,
        fuelTypeCopy: null,
        ccCopy: null,
      }));
    } else if (name === "varientCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        varientCopy: value,
        ccCopy: null,
      }));
    } else if (name === "fuelTypeCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        fuelTypeCopy: value,
        varientCopy: null,
        ccCopy: null,
      }));
    } else if (name === "ccCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        ccCopy: value,
      }));

    } else if (name === "vehicleNumberCopy") {
      if (value?.length <= 11) {
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          vehicleNumberCopy: value.toUpperCase(),
        }));
      }

    } else if (name === "isClaimPrevPolicyCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        isClaimPrevPolicyCopy: value === "Yes" ? true : false,
      }));
      if (value === "Yes") {
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          prevNcbCopy: "0",
          newNcbCopy: "0",
        }));
      } else if (value === "No") {

        let data = calculatePrevNcbNewNcb(
          carDetails?.policyRegDate,
          value === "No" ? false : true,
          carDetails?.typeOfBusiness,
          carDetails?.prevPolicyExpLastMonth)

        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          prevNcbCopy: data?.prevNcb,
          newNcbCopy: data?.newNcb,
        }));
      }

    } else if (constName === "prevNcbCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        prevNcbCopy: e,
        newNcbCopy: newNcb,
      }));
    } else if (constName === "regDateCopy") {
      setUpdateCarDetailsConfirm(true)
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        regDateCopy: e,
      }));
    } else if (constName === "odPolicyExpCopy") {
      setUpdateCarDetailsConfirm(true)
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        odPolicyExpCopy: e,
      }));
    } else if (constName === "policyExpCopy") {
      setUpdateCarDetailsConfirm(true)
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        policyExpCopy: e,
      }));
    }


    else if (name === "prevPolicyType" || name === "PolicyTypeValue" || name == 'prevPolicyTypeCopy') {
      if (name === "prevPolicyType" || name == 'prevPolicyTypeCopy') {

        if (value === "Third Party") {
          setcarDetailsCopyState((prevItems) => ({
            ...prevItems,
            prevPolicyTypeCopy: value,
            newNcbCopy: "0",
            prevNcbCopy: "0",
          }));

        } else {
          setcarDetailsCopyState((prevItems) => ({
            ...prevItems,
            prevPolicyTypeCopy: value,
            ncbCopy: ncbData?.newNcb,
            prevNcbCopy: ncbData?.prevNcb
          }));
        }
      } else if (name === "PolicyTypeValue") {
        setShowLoader(true);
        setUpdateCarDetailsConfirm(true)
        if (value === "Third Party") {
          setcarDetailsCopyState((prevItems) => ({
            ...prevItems,
            policyTypeValueCopy: value,
            newNcbCopy: "0",
            prevNcbCopy: "0",
          }));
        } else {

          setcarDetailsCopyState((prevItems) => ({
            ...prevItems,
            policyTypeValueCopy: value,
            newNcbCopy: carDetails?.newNcb,
            prevNcbCopy: carDetails?.prevNcb,
          }));
        }
      }
    } else if (name === "isCngLpgVehicleTypecopy") {
      setcarDetailsCopyState({
        ...carDetailsCopyState,
        [name]: value === "Yes" ? true : false,
      });
    } else if (name === "cngKitValuecopy") {
      if (value <= 100000) {
        setcarDetailsCopyState({
          ...carDetailsCopyState,
          cngKitValuecopy: value,
        });
      }
    } else {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        [name]: value,
      }));
    }
  };

  const calculateMinAndMaxDate = () => {
    const currentDate = new Date();
    if (
      carDetails?.isPrevPolicyExp === false ||
      SelectedAlternateExpOption === "Policy has not expired yet."
    ) {
      const futureDate = new Date(currentDate);
      futureDate.setDate(currentDate.getDate() + 45);

      setMinDateValue(currentDate);
      setMaxDateValue(futureDate);
    } else if (carDetails?.prevPolicyExpLastMonth === true) {
      const yesterdayDate = new Date(currentDate);
      const ninetyDaysBackDate = new Date(currentDate);

      yesterdayDate.setDate(currentDate.getDate() - 180);
      ninetyDaysBackDate.setDate(currentDate.getDate() - 90);

      setMaxDateValue(ninetyDaysBackDate);
      setMinDateValue(yesterdayDate);
    } else if (
      carDetails?.isPrevPolicyExp === true ||
      SelectedAlternateExpOption === "Policy expired in last 90 days."
    ) {
      const yesterdayDate = new Date(currentDate);
      const ninetyDaysBackDate = new Date(currentDate);

      yesterdayDate.setDate(currentDate.getDate() - 1);
      ninetyDaysBackDate.setDate(currentDate.getDate() - 90);

      setMaxDateValue(yesterdayDate);
      setMinDateValue(ninetyDaysBackDate);
    } else if (
      SelectedAlternateExpOption === "Policy expired more than 90 days." ||
      SelectedAlternateExpOption === "I purchased a used car."
    ) {
      const yesterdayDate = new Date(currentDate);
      const ninetyDaysBackDate = new Date(currentDate);

      yesterdayDate.setDate(currentDate.getDate() - 180);
      ninetyDaysBackDate.setDate(currentDate.getDate() - 90);

      setMaxDateValue(ninetyDaysBackDate);
      setMinDateValue(yesterdayDate);
    }
  };

  // Here logic for future date grayout
  const calculateRegMinMax = () => {
    if (businessType == NEW) {
      setminRegDate(new Date(carDetails?.policyRegDate));
      setmaxRegDate(moment(minRegDate).add(500, 'day').toDate())
      console.log(setminRegDate(new Date(carDetails?.policyRegDate)), "jdhfjhdsgh")
    } else {
      setmaxRegDate(new Date(carDetails?.policyRegDate));
      setminRegDate(moment(maxRegDate).subtract(500, 'day').toDate())
    }
  }

  useEffect(() => {
    calculateRegMinMax();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    calculateMinAndMaxDate();
  }, [vehicleData]);

  if (businessType == "FindMyCar") {
    localStorage.setItem("findmycar", true)
  }

  carsAge = moment().diff(carDetails?.policyRegDate, "years", true).toFixed(1);

  useEffect(() => {
    if (carDetails?.prevPolicyType === "Third Party") {
      setPolicyTypeOption([
        { label: "Comprehensive", value: "Comprehensive", desc: "Own Damage + Third Party" },
        { label: "Third Party", value: "Third Party", desc: "Third Party Only" },
      ]);
    } else if (carDetails?.prevPolicyType === "Comprehensive") {
      setPolicyTypeOption([
        { label: "Comprehensive", value: "Comprehensive", desc: "Own Damage + Third Party" },
        { label: "Third Party", value: "Third Party", desc: "Third Party Only" },
      ]);
    } else if (
      (carDetails?.prevPolicyType === "Bundled (3Y TP + 1Y OD)" ||
        carDetails?.prevPolicyType === "Bundled") &&
      carsAge <= 2.6
    ) {
      setCarDetails((prevItems) => ({
        ...prevItems,
        PolicyTypeValue: "Own Damage",
      }));
      setPolicyTypeOption([{ label: "Own Damage", value: "Own Damage", desc: "Own Damage Only" }]);
    } else if (
      (carDetails?.prevPolicyType === "Bundled (3Y TP + 1Y OD)" ||
        carDetails?.prevPolicyType === "Bundled") &&
      carsAge > 2.6
    ) {
      setPolicyTypeOption([
        { label: "Comprehensive", value: "Comprehensive", desc: "Own Damage + Third Party" },
        { label: "Third Party", value: "Third Party", desc: "Third Party Only" },
      ]);
    } else if (businessType === NEW) {
      setPolicyTypeOption([{ label: "Bundled", value: "Bundled", desc: "1 Yr Own Damage + 3 Yr Third Party" }]);
    } else {
      console.log("Previous Policy Type useEffect()");
    }

    let presentDay = new Date();
    presentDay.setHours(0, 0, 0, 0);

    let policyExpDate = new Date(carDetails?.policyExpDate);
    policyExpDate.setHours(0, 0, 0, 0);

    if (
      carDetails?.prevPolicyType === "Third Party" && carDetails?.PolicyTypeValue === "Comprehensive") {
      if (carDetails?.typeOfBusiness != "New" && carDetails?.PolicyTypeValue !== "Third Party") {
        dispatch(
          isInitialAction({
            isInspectionReq: true,
          })
        );
        setCarDetails((prevItems) => ({
          ...prevItems,
          newNcb: "0",
          prevNcb: "0",
        }));
        setcarDetailsCopyState({
          ...carDetailsCopyState,
          prevNcbCopy: "0",
          newNcbCopy: "0",
        });
      }
    } else {
      if ((policyExpDate.getTime() < presentDay.getTime() && carDetails?.typeOfBusiness != "New" && carDetails?.PolicyTypeValue !== "Third Party")) {
        dispatch(
          isInitialAction({
            isInspectionReq: true,
          })
        );

      } else {
        dispatch(
          isInitialAction({
            isInspectionReq: false,
          })
        );

      }

      // setCarDetails((prevItems) => ({
      //   ...prevItems,
      //   newNcb: ncbData?.newNcb,
      //   prevNcb: ncbData?.prevNcb,
      // }));
      setcarDetailsCopyState({
        ...carDetailsCopyState,
        prevNcbCopy: ncbData?.prevNcb,
        newNcbCopy: ncbData?.newNcb,
      });
    }

    if (carDetails?.policyRegDate) {
      let x = new Date(carDetails?.policyRegDate)
      if (x !== "Invalid Date" && !isNaN(x) && x) {
        let newData = x?.toISOString()?.split("T")[0]
        setformattedRegDate(newData)
      }
    }
  }, [carDetails?.prevPolicyType, carDetails?.policyRegDate, carDetails?.PolicyTypeValue]);


  useEffect(() => {
    if (carDetails?.prevPolicyODexpDate) {
      let x = new Date(carDetails?.prevPolicyODexpDate)
      if (x !== "Invalid Date" && !isNaN(x) && x) {
        let newData = x?.toISOString()?.split("T")[0]
        setformattedODexpDate(newData)
      }
    }
  }, [carDetails?.prevPolicyODexpDate, carDetails?.prevPolicyType])


  useEffect(() => {
    if (carDetails?.policyExpDate) {
      let x = new Date(carDetails?.policyExpDate)
      if (x !== "Invalid Date" && !isNaN(x) && x) {
        let newData = x?.toISOString()?.split("T")[0]
        setformattedpolicyExpDate(newData)
      }
    }
  }, [carDetails?.policyExpDate, carDetails?.prevPolicyType])


  useEffect(() => {
    dispatch(
      allBodyDataAction({
        ...carDetails,
        ...{ coverage: [test] },
        ...getCalculatedDate,
      })
    );
  }, [getCalculatedDate, carDetails]);

  useEffect(() => {
    setCarDetails((prevItems) => ({
      ...prevItems,
      idvValue: IdvValue,
    }));
  }, [IdvValue]);

  //setting min max in IDV range
  useEffect(() => {
    let minArr = Object.values(minIdvValue);
    let maxArr = Object.values(maxIdvValue);
    minArr = minArr.filter((val) => val !== 0);

    setAllIDVValues((prevItems) => ({
      ...prevItems,
      min: Math.min(...minArr).toFixed(2),
      max: Math.max(...maxArr).toFixed(2),
    }));
  }, [minIdvValue, maxIdvValue]);

  const handleCheckboxChange = (e) => {
    const { id, checked, value, name } = e?.target;
    if (id === "checkbox12" && checked === true) {
      return setShowAccesoriesField(true);
    }
    if (id === "checkbox12" && checked === false) {
      setCarDetails((prevData) => ({
        ...prevData,
        electricalAccesories: null,
        nonElectricalAccesories: null,
      }));
      setNonElectricalAmt(null);
      setElectricalAmt(null);
    }
    if (id === "checkbox11" && checked === true) {
      return setShowPcSumInsuredField(true);
    }
    if (id === "checkbox11" && checked === false) {
      setCarDetails((prevData) => ({
        ...prevData,
        pcCoverSumInsured: null,
      }));
      setPassengerCoverAmt(null);
      setShowPcSumInsuredField(false);
    }
    if (id === "checkbox2" && checked === true) {
      if (carDetails?.PolicyTypeValue !== "Bundled") {
        setShowNilDepModal(true);
      }
    }
    if (id === "checkbox5" && checked === true) {
      if (carDetails?.PolicyTypeValue !== "Bundled") {
        setShowEngProtectModal(true);
      }
    }
    if (id === "checkbox6" && checked === true) {
      if (carDetails?.PolicyTypeValue !== "Bundled") {
        setShowRtiModal(true);
      }
    }
    setTest((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) => (
        checkbox.id === id ? { ...checkbox, checked } : checkbox
      ))
    );
  };

  const updateCoveragesClone = (e) => {
    const { id, checked, value, name } = e?.target;
    if (id === "checkbox12" && checked === true) {
      return setShowAccesoriesField(true);
    }
    if (id === "checkbox12" && checked === false) {
      setCarDetails((prevData) => ({
        ...prevData,
        electricalAccesories: null,
        nonElectricalAccesories: null,
      }));
      setNonElectricalAmt(null);
      setElectricalAmt(null);
    }
    if (id === "checkbox11" && checked === true) {
      return setShowPcSumInsuredField(true);
    }
    if (id === "checkbox11" && checked === false) {
      setCarDetails((prevData) => ({
        ...prevData,
        pcCoverSumInsured: null,
      }));
      setPassengerCoverAmt(null);
      setShowPcSumInsuredField(false);
    }
    setCoverageClone((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) => (
        checkbox.id === id ? { ...checkbox, checked } : checkbox
      ))
    );
  }

  const handleNcbValue = (prevPolicyType, currentPolicyType) => {
    if (businessType !== "New") {
      if (
        prevPolicyType === "Third Party" ||
        currentPolicyType === "Third Party"
      ) {
        setCarDetails((prevItems) => ({
          ...prevItems,
          newNcb: "0",
          prevNcb: "0",
        }));
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          newNcbCopy: "0",
          prevNcbCopy: "0",
        }));
      }
      else {
        // if ((carDetails?.prevPolicyType == "Comprehensive" || carDetails?.prevPolicyType == "Bundled")
        //   && carDetails?.newNcb !== 0
        //   && carDetailsCopyState?.newNcbCopy !== 0
        //   && carDetails?.PolicyTypeValue !== "Third Party") {
        //   setncbData(calculatePrevNcbNewNcb(
        //     carDetails?.policyRegDate,
        //     carDetails?.isClaimPrevPolicy,
        //     carDetails?.typeOfBusiness,
        //     carDetails?.prevPolicyExpLastMonth))
        //   setcarDetailsCopyState((prev) => ({
        //     ...prev,
        //     ncbCopy: ncbData?.newNcb,
        //     prevNcbCopy: ncbData?.prevNcb
        //   }))
        //   setCarDetails((prev) => ({
        //     ...prev,
        //     newNcb: ncbData?.newNcb,
        //     prevNcb: ncbData?.prevNcb
        //   }))
        // }
      }
    }
    else {
      setCarDetails((prevItems) => ({
        ...prevItems,
        newNcb: "0",
        prevNcb: "0",
      }));
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        newNcbCopy: "0",
        prevNcbCopy: "0",
      }));
    }
  };

  let addoOns = [];

  useEffect(() => {
    // setCarDetails((prev) => ({
    //   ...prev,
    //   quotePackageNameICICI: "",
    //   quotePackageNameDigit: ""
    // }))

    // setcarDetailsCopyState((prev) => ({
    //   ...prev,
    //   quotePackageNameDigitCopy: "",
    //   quotePackageNameICICICopy: ""
    // }))

    if (shreeRamQuote?.data?.data?.quote?.GetQuotResult) {
      setminIdvValue((prevItems) => ({
        ...prevItems,
        shreeram: shreeRamQuote?.data?.data?.quote?.GetQuotResult?.minIDV
          ? shreeRamQuote?.data?.data?.quote?.GetQuotResult?.minIDV
          : 0,
      }));
      setmaxIdvValue((prevItems) => ({
        ...prevItems,
        shreeram: shreeRamQuote?.data?.data?.quote?.GetQuotResult?.maxIDV
          ? shreeRamQuote?.data?.data?.quote?.GetQuotResult?.maxIDV
          : 0,
      }));
    }

    async function checkNotWrokingAddOn() {
      let checkedValue = test.filter((addonCheckBox) => {
        return addonCheckBox.checked === true;
      });

      addoOns.push(...checkedValue);
      const result = addoOns.filter(
        (element) => !notWorkingAddons.includes(element)
      );

      if (!carDetails?.isPrevPolicyExp) {
        let sbiResponseData = await dispatch(
          sbiQuickQuoteAction({
            data: {
              ...carDetails,
              ...{ coverage: result?.length ? [result] : [obj] },
              // ...getCalculatedDate,
            },
          })
        );

        if (
          sbiResponseData?.payload?.body?.data?.quote?.PolicyLobList?.[0]
            ?.PolicyRiskList[0]?.IDV_Suggested
        ) {
          setminIdvValue((prevItems) => ({
            ...prevItems,
            sbi: sbiResponseData?.payload?.body?.data?.quote?.PolicyLobList?.[0]
              ?.PolicyRiskList[0]?.MinIDV_Suggested
              ? sbiResponseData?.payload?.body?.data?.quote?.PolicyLobList?.[0]
                ?.PolicyRiskList[0]?.MinIDV_Suggested
              : 0,
          }));
          setmaxIdvValue((prevItems) => ({
            ...prevItems,
            sbi: sbiResponseData?.payload?.body?.data?.quote?.PolicyLobList?.[0]
              ?.PolicyRiskList[0]?.MaxIDV_Suggested
              ? sbiResponseData?.payload?.body?.data?.quote?.PolicyLobList?.[0]
                ?.PolicyRiskList[0]?.MaxIDV_Suggested
              : 0,
          }));
        }

        if (sbiResponseData?.payload?.body?.data?.quote?.code) {
          let tempNotWorking = addoOns.pop();
          setNotWorkingAddons([...notWorkingAddons, tempNotWorking]);
        }
      }
    }

    let DigitPalnAddons = test2.map((item) => {
      if (item.name == "paCover" || item.name == "driverCover") {
        return ({ ...item, checked: true })
      } else {
        return ({ ...item, checked: false })
      }
    })

    // after 3 second 
    setShowLoader(false);
    setShowSkeleton(false);

    if (carDetails?.PolicyTypeValue) {
      // let SetTimer = setTimeout(() => {
      let GetDataPromiseAll = Promise.all([
        checkNotWrokingAddOn(),
        setCalendarDeactiveFlag(true),
        dispatch(
          zunoQuickQuoteAction({
            data: {
              ...carDetails,
              ...{ coverage: [obj] },
            },
          })
        ),
        dispatch(
          digitQuickQuoteAction({
            data: {
              ...carDetails,
              ...{ MMVPlans: PlansData?.data?.data?.mmv?.digit_acko_car_master[0] },
              ...{ isPlanRequired: true },
              ...{ coverage: [DigitPalnAddons] },
            },
          })
        ),
        dispatch(
          iciciQuickQuoteAction({
            data: {
              ...carDetails,
              ...{ VehicleMaster: PlansData?.data?.mmv?.icici_acko_car_master },
              ...{ isPlanRequired: true },
              ...{ coverage: [obj] },
            },
          })
        ),
        dispatch(
          shreeRamQuickQuoteAction({
            data: {
              ...carDetails,
              ...{ coverage: [obj] },
            },
          })
        ),
        dispatch(
          ackoQuickQuoteAction({
            data: {
              ...carDetails,
              ...{ coverage: [obj] },
            },
          })
        ),
        dispatch(
          hdfcQuickQuoteAction({
            data: {
              ...carDetails,
              ...{ coverage: [obj] },
            },
          })
        ),
        dispatch(
          tataQuickQuoteAction({
            data: {
              ...carDetails,
              ...{ coverage: [obj] },
            },
          })
        ), dispatch(
          unitedQuickQuoteAction({
            data: {
              ...carDetails,
              ...{ coverage: [obj] },
            },
          })
        ), dispatch(
          nationalQuickQuoteAction({
            data: {
              ...carDetails,
              ...{ coverage: [obj] },
            },
          })
        ),
      ]).then((values) => {
        // handle Zuno idv response
        if (values[2]?.payload?.data?.quote) {
          setminIdvValue((prevItems) => ({
            ...prevItems,
            zuno: values[2]?.payload?.data?.minIdv
              ? values[2]?.payload?.data?.minIdv
              : 0,
          }));
          setmaxIdvValue((prevItems) => ({
            ...prevItems,
            zuno: values[2]?.payload?.data?.maxIdv
              ? values[2]?.payload?.data?.maxIdv
              : 0,
          }));
        }
        //handle digit idv response ( change array position when uat merged)
        if (values[3]?.payload?.data?.quote) {
          setminIdvValue((prevItems) => ({
            ...prevItems,
            digit: values[3]?.payload?.data?.quote?.["Motor-Quick Quote"]
              ?.vehicle?.vehicleIDV?.minimumIdv
              ? values[3]?.payload?.data?.quote?.["Motor-Quick Quote"]?.vehicle
                ?.vehicleIDV?.minimumIdv
              : 0,
          }));
          setmaxIdvValue((prevItems) => ({
            ...prevItems,
            digit: values[3]?.payload?.data?.quote?.["Motor-Quick Quote"]
              ?.vehicle?.vehicleIDV?.maximumIdv
              ? values[3]?.payload?.data?.quote?.["Motor-Quick Quote"]?.vehicle
                ?.vehicleIDV?.maximumIdv
              : 0,
          }));
        }
        //handle icici idv response ( change array position when uat merged)
        if (values[4]?.payload?.data?.idv) {
          setminIdvValue((prevItems) => ({
            ...prevItems,
            icici: values[4]?.payload?.data?.idv.min
              ? values[4]?.payload?.data?.idv.min
              : 0,
          }));
          setmaxIdvValue((prevItems) => ({
            ...prevItems,
            icici: values[4]?.payload?.data?.idv.max
              ? values[4]?.payload?.data?.idv.max
              : 0,
          }));
        }
        //handle shreeram idv response
        if (values[5]?.payload?.data?.quote?.GetQuotResult) {
          setminIdvValue((prevItems) => ({
            ...prevItems,
            shreeram: values[5]?.payload?.data?.quote?.GetQuotResult?.minIDV
              ? values[5]?.payload?.data?.quote?.GetQuotResult?.minIDV
              : 0,
          }));
          setmaxIdvValue((prevItems) => ({
            ...prevItems,
            shreeram: values[5]?.payload?.data?.quote?.GetQuotResult?.maxIDV
              ? values[5]?.payload?.data?.quote?.GetQuotResult?.maxIDV
              : 0,
          }));
        }
        //handle acko idv response
        if (values[6]?.payload?.data?.quote?.insured?.length) {
          setminIdvValue((prevItems) => ({
            ...prevItems,
            acko: values[6]?.payload?.data?.quote?.insured[0].idv.values[1]
              .idv_amount
              ? values[6]?.payload?.data?.quote?.insured[0].idv.values[1]
                .idv_amount
              : 0
          }));
          setmaxIdvValue((prevItems) => ({
            ...prevItems,
            acko: values[6]?.payload?.data?.quote?.insured[0].idv.values[2]
              .idv_amount
              ? values[6]?.payload?.data?.quote?.insured[0].idv.values[2]
                .idv_amount
              : 0,
          }));
        }
        //handle Hdfc IDV response
        if (values[7]?.payload?.data?.quote) {
          setminIdvValue((prevItems) => ({
            ...prevItems,
            hdfc: values[7]?.payload?.data?.minIdv
              ? values[7]?.payload?.data?.minIdv
              : 0,
          }));
          setmaxIdvValue((prevItems) => ({
            ...prevItems,
            hdfc: values[7]?.payload?.data?.maxIdv
              ? values[7]?.payload?.data?.maxIdv
              : 0,
          }));
        }
        //handle TATA 4W IDV response
        if (values[8]?.payload?.data?.quote) {
          setminIdvValue((prevItems) => ({
            ...prevItems,
            tata: values[8]?.payload?.data?.minIdv
              ? values[8]?.payload?.data?.minIdv
              : 0,
          }));
          setmaxIdvValue((prevItems) => ({
            ...prevItems,
            tata: values[8]?.payload?.data?.maxIdv
              ? values[8]?.payload?.data?.maxIdv
              : 0,
          }));
        }
        //handle UNITED 4W IDV response
        if (values[9]?.payload?.data?.quote) {
          setminIdvValue((prevItems) => ({
            ...prevItems,
            united: values[9]?.payload?.data?.minIdv
              ? values[9]?.payload?.data?.minIdv
              : 0,
          }));
          setmaxIdvValue((prevItems) => ({
            ...prevItems,
            united: values[9]?.payload?.data?.maxIdv
              ? values[9]?.payload?.data?.maxIdv
              : 0,
          }));
        }
        //handle NATIONAL 4W IDV response
        if (values[10]?.payload?.data?.quote) {
          setminIdvValue((prevItems) => ({
            ...prevItems,
            national: values[10]?.payload?.data?.minIdv
              ? values[10]?.payload?.data?.minIdv
              : 0,
          }));
          setmaxIdvValue((prevItems) => ({
            ...prevItems,
            national: values[10]?.payload?.data?.maxIdv
              ? values[10]?.payload?.data?.maxIdv
              : 0,
          }));
        }
        setCalendarDeactiveFlag(false)
      });
      // }, 2000)
    }
  }, [test[9]?.checked, test[10]?.checked, carDetails?.PolicyTypeValue, carDetails?.idvValue, carDetails?.policyExpDate, carDetails?.fuelType, carDetails?.prevPolicyODexpDate,
  carDetails?.make, carDetails?.varient, carDetails?.prevNcb, carDetails?.fuel, carDetails?.varient, carDetails?.cc, carDetails?.prevPolicyType,
  carDetails?.policyRegDate, carDetails?.numberOfClaim, carDetails?.prevPolicyODexpDate, PlansData?.data, carDetails?.newNcb, carDetails?.isClaimPrevPolicy]);

  useEffect(() => {
    async function addonsFunc() {
      if (test.length > 0) {
        let RSAplan = test?.map((item) => {
          if (item.name == 'roadAssistance') {
            return ({ ...item, checked: true })
          }
          else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })

        let PROplan = test?.map((item) => {
          if (item.name == 'roadAssistance' || item.name == "lossofPersonalBelongings" || item.name == "keyLockReplacement") {
            return ({ ...item, checked: true })
          }
          else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })

        let DProplan = test?.map((item) => {
          if (item.name == 'zeroDep' || item.name == 'roadAssistance' || item.name == "lossofPersonalBelongings" || item.name == "keyLockReplacement") {
            return ({ ...item, checked: true })
          }

          else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })

        let DCProplan = test?.map((item) => {
          if (item.name == 'consumables' || item.name == "roadAssistance" || item.name == "zeroDep" || item.name == "lossofPersonalBelongings" || item.name == "keyLockReplacement") {
            return ({ ...item, checked: true })
          }
          else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })

        let DCEProplan = test?.map((item) => {
          if (item.name == 'consumables' || item.name == "roadAssistance" || item.name == "zeroDep" || item.name == "lossofPersonalBelongings" || item.name == "keyLockReplacement" || item.name == 'engineProtection') {
            return ({ ...item, checked: true })
          }
          else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })


        let DCTProplan = test?.map((item) => {
          if (item.name == 'tyreProtection' || item.name == 'consumables' || item.name == "roadAssistance" || item.name == "zeroDep" || item.name == "lossofPersonalBelongings" || item.name == "keyLockReplacement") {
            return ({ ...item, checked: true })
          }

          else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })

        let DCETProplan = test?.map((item) => {
          if (item.name == 'engineProtection' || item.name == 'tyreProtection' || item.name == 'consumables' || item.name == "roadAssistance" || item.name == "zeroDep" || item.name == "lossofPersonalBelongings" || item.name == "keyLockReplacement") {
            return ({ ...item, checked: true })
          }
          else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })

        let DCRTIProplan = test?.map((item) => {
          if (item.name == "roadAssistance" || item.name == "zeroDep" || item.name == 'consumables' || item.name == 'invoiceCover' || item.name == "lossofPersonalBelongings" || item.name == "keyLockReplacement" || item.name == "zeroDep") {
            return ({ ...item, checked: true })
          }

          else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })


        let DCERTIProPlan = test?.map((item) => {
          if (item.name == 'engineProtection' || item.name == 'consumables' || item.name == "roadAssistance" || item.name == "zeroDep" || item.name == "lossofPersonalBelongings" || item.name == "keyLockReplacement" || item.name == "invoiceCover") {
            return ({ ...item, checked: true })
          }
          else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })

        let DCTRTIProPlan = test?.map((item) => {
          if (item.name == 'consumables' || item.name == "roadAssistance" || item.name == "zeroDep" || item.name == "lossofPersonalBelongings" || item.name == "keyLockReplacement" || item.name == "invoiceCover" || item.name == 'tyreProtection') {
            return ({ ...item, checked: true })
          }
          else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })
        let DCETRTIProPlan = test?.map((item) => {
          if (item.name == 'consumables' || item.name == "roadAssistance" || item.name == "zeroDep" || item.name == "lossofPersonalBelongings" || item.name == "keyLockReplacement" || item.name == "invoiceCover" || item.name == 'tyreProtection' || item.name == 'engineProtection') {
            return ({ ...item, checked: true })
          }
          else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })

        // Addons Plan for ICICI
        let ZDCRSA = test?.map((item) => {
          if (item.name == "zeroDep" || item.name == "consumables" || item.name == "roadAssistance" || item.name == "keyLockReplacement") {
            return ({ ...item, checked: true })
          }
          else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })
        let ZDCERSA = test?.map((item) => {
          if (item.name == "zeroDep" || item.name == "consumables" || item.name == "roadAssistance" || item.name == 'engineProtection' || item.name == "keyLockReplacement") {
            return ({ ...item, checked: true })
          } else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })
        let ZDCTRSA = test?.map((item) => {
          if (item.name == "zeroDep" || item.name == "consumables" || item.name == "roadAssistance" || item.name == 'tyreProtection' || item.name == "keyLockReplacement") {
            return ({ ...item, checked: true })
          } else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })
        let ZDCETRSA = test?.map((item) => {
          if (item.name == "zeroDep" || item.name == "consumables" || item.name == "roadAssistance" || item.name == 'tyreProtection' || item.name == 'engineProtection' || item.name == "keyLockReplacement") {
            return ({ ...item, checked: true })
          } else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })
        let ZDCETRTIRSA = test?.map((item) => {
          if (item.name == "zeroDep" || item.name == "consumables" || item.name == "roadAssistance" || item.name == 'tyreProtection' || item.name == 'engineProtection' || item.name == "invoiceCover" || item.name == "keyLockReplacement") {
            return ({ ...item, checked: true })
          } else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })
        let ZDCRSALOBPKey = test?.map((item) => {
          if (item.name == "zeroDep" || item.name == "consumables" || item.name == "roadAssistance" || item.name == "lossofPersonalBelongings" || item.name == "keyLockReplacement") {
            return ({ ...item, checked: true })
          } else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })
        let ZDCERSALOBPKey = test?.map((item) => {
          if (item.name == "zeroDep" || item.name == "consumables" || item.name == "roadAssistance" || item.name == 'engineProtection' || item.name == "lossofPersonalBelongings" || item.name == "keyLockReplacement") {
            return ({ ...item, checked: true })
          } else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })
        let ZDCTRSALOBPKey = test?.map((item) => {
          if (item.name == "zeroDep" || item.name == "consumables" || item.name == "roadAssistance" || item.name == 'tyreProtection' || item.name == "lossofPersonalBelongings" || item.name == "keyLockReplacement") {
            return ({ ...item, checked: true })
          } else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })
        let ZDCETRSALOBPKey = test?.map((item) => {
          if (item.name == "zeroDep" || item.name == "consumables" || item.name == "roadAssistance" || item.name == 'tyreProtection' || item.name == 'engineProtection' || item.name == "lossofPersonalBelongings" || item.name == "keyLockReplacement") {
            return ({ ...item, checked: true })
          } else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })
        let ZDCETRTIRSALOBPKey = test?.map((item) => {
          if (item.name == "zeroDep" || item.name == "consumables" || item.name == "roadAssistance" || item.name == 'tyreProtection' || item.name == 'engineProtection' || item.name == "invoiceCover" || item.name == "lossofPersonalBelongings" || item.name == "keyLockReplacement") {
            return ({ ...item, checked: true })
          } else {
            if (item.name !== "paCover" && item.name !== "passengerCover" && item.name !== "driverCover" && item.name !== "accessories") {
              return ({ ...item, checked: false })
            } else {
              return item
            }
          }
        })

        if (carDetailsCopyState?.quotePackageNameICICICopy == "RSA With Key 1") {

          if (carDetailsCopyState?.quotePackageNameICICICopy) {
            // addonsfordigit=RSAplan
            setaddonsforIcici(ZDCRSA)
          }

        }
        else if (carDetailsCopyState?.quotePackageNameICICICopy == "RSA With Key 2") {

          if (carDetailsCopyState?.quotePackageNameICICICopy) {
            // addonsforIcici=ZDCERSA
            setaddonsforIcici(ZDCERSA)
          }
        }
        else if (carDetailsCopyState?.quotePackageNameICICICopy == "RSA With Key 3") {

          if (carDetailsCopyState?.quotePackageNameICICICopy) {
            // addonsforIcici=ZDCTRSA
            setaddonsforIcici(ZDCTRSA)

          }
        }
        else if (carDetailsCopyState?.quotePackageNameICICICopy == "RSA With Key 4") {

          if (carDetailsCopyState?.quotePackageNameICICICopy) {
            // addonsforIcici=ZDCETRSA
            setaddonsforIcici(ZDCETRSA)
          }
        }
        else if (carDetailsCopyState?.quotePackageNameICICICopy == "RSA With Key 5") {

          if (carDetailsCopyState?.quotePackageNameICICICopy) {
            // addonsforIcici=ZDCETRTIRSA
            setaddonsforIcici(ZDCETRTIRSA)

          }
        }
        else if (carDetailsCopyState?.quotePackageNameICICICopy == "RSA Plus 1") {

          if (carDetailsCopyState?.quotePackageNameICICICopy) {
            // addonsforIcici=ZDCRSALOBPKey
            setaddonsforIcici(ZDCRSALOBPKey)
          }
        }
        else if (carDetailsCopyState?.quotePackageNameICICICopy == "RSA Plus 2") {
          if (carDetailsCopyState?.quotePackageNameICICICopy) {
            // addonsforIcici=ZDCERSALOBPKey
            setaddonsforIcici(ZDCERSALOBPKey)
          }
        }
        else if (carDetailsCopyState?.quotePackageNameICICICopy == "RSA Plus 3") {
          if (carDetailsCopyState?.quotePackageNameICICICopy) {
            // addonsforIcici=ZDCTRSALOBPKey
            setaddonsforIcici(ZDCTRSALOBPKey)
          }
        }
        else if (carDetailsCopyState?.quotePackageNameICICICopy == "RSA Plus 4") {
          if (carDetailsCopyState?.quotePackageNameICICICopy) {
            // addonsforIcici=ZDCETRSALOBPKey
            setaddonsforIcici(ZDCETRSALOBPKey)
          }
        }
        else if (carDetailsCopyState?.quotePackageNameICICICopy == "RSA Plus 5") {
          if (carDetailsCopyState?.quotePackageNameICICICopy) {
            // addonsforIcici=ZDCETRTIRSALOBPKey
            setaddonsforIcici(ZDCETRTIRSALOBPKey)
          }
        }

        else {
          // addonsfordigit=test

          setaddonsforIcici(test)
        }

        // =============================================================================================
        if (carDetailsCopyState?.quotePackageNameDigitCopy == "RSA") {

          if (carDetailsCopyState?.quotePackageNameDigitCopy) {
            // addonsfordigit=RSAplan
            setaddonsfordigit(RSAplan)
          }



        }
        else if (carDetailsCopyState?.quotePackageNameDigitCopy == "Pro") {

          if (carDetailsCopyState?.quotePackageNameDigitCopy) {
            // addonsfordigit=PROplan
            setaddonsfordigit(PROplan)
          }

        }
        else if (carDetailsCopyState?.quotePackageNameDigitCopy == "D Pro") {

          setaddonsfordigit(DProplan)
        }
        else if (carDetailsCopyState?.quotePackageNameDigitCopy == "DC Pro") {

          setaddonsfordigit(DCProplan)
        }
        else if (carDetailsCopyState?.quotePackageNameDigitCopy == "DCE Pro") {

          setaddonsfordigit(DCEProplan)
        }
        else if (carDetailsCopyState?.quotePackageNameDigitCopy == "DCT Pro") {

          setaddonsfordigit(DCTProplan)
        }
        else if (carDetailsCopyState?.quotePackageNameDigitCopy == "DCET Pro") {
          setaddonsfordigit(DCETProplan)
        }
        else if (carDetailsCopyState?.quotePackageNameDigitCopy == "DCRTI Pro") {
          setaddonsfordigit(DCRTIProplan)
        }
        else if (carDetailsCopyState?.quotePackageNameDigitCopy == "DCERTI Pro") {
          setaddonsfordigit(DCERTIProPlan)
        }
        else if (carDetailsCopyState?.quotePackageNameDigitCopy == "DCTRTI Pro") {
          setaddonsfordigit(DCTRTIProPlan)
        }
        else if (carDetailsCopyState?.quotePackageNameDigitCopy == "DCETRTI Pro") {
          // addonsfordigit=DCETRTIProPlan
          setaddonsfordigit(DCETRTIProPlan)
        }
        else {
          // addonsfordigit=test
          setaddonsfordigit(test)

        }
      }
    }
    addonsFunc();
  }, [carDetailsCopyState?.quotePackageNameICICICopy, carDetailsCopyState?.quotePackageNameDigitCopy, test[0], test[8], test[9], test[10]])

  useEffect(() => {

    let id = setTimeout(() => {
      if (transactionId && updateCarDetails) {
        (async function () {
          let data = {
            uuid: transactionId,
            bodyData: {
              ...carDetails,
              ...{ coverage: [test] },
              ...{ NewcoveragePlan: [addonsforIcici] },
              ...{ NewcoveragePlanDig: [addonsfordigit] },
              ...getCalculatedDate,
            },
            vehicleNumber,
            businessType,
            isFindMyCar,
            vehicleData: vehicleData,
            carDetails,
            type: "update",
            addOnData: test,
          };
          await dispatch(transactionURLAction(data));
        })();
      }
    }, 1000)
    setcarDetailsCopyState({
      vehicleNumberCopy: carDetails?.vehicleNumber?.toUpperCase() || "",
      makeCopy: carDetails?.make || "",
      modelCopy: carDetails?.model || "",
      varientCopy: carDetails?.varient || "",
      fuelTypeCopy: carDetails?.fuelType || "",
      ccCopy: carDetails?.cc || "",
      regDateCopy: carDetails?.policyRegDate || "",
      prevPolicyTypeCopy: carDetails?.prevPolicyType || "",
      policyExpCopy: carDetails?.policyExpDate || "",
      odPolicyExpCopy: carDetails?.prevPolicyODexpDate || "",
      tpPolicyExpCopy: carDetails?.prevPolicyTPexpDate || "",
      isClaimPrevPolicyCopy: carDetails?.isClaimPrevPolicy,
      prevNcbCopy: carDetails?.prevNcb,
      newNcbCopy: carDetails?.newNcb,
      electricalAccesoriesCopy: carDetails?.electricalAccesories || "",
      nonElectricalAccesoriesCopy: carDetails?.nonElectricalAccesories || "",
      policyTypeValueCopy: carDetails?.PolicyTypeValue || "",
      idvValueCopy: carDetails?.idvValue || "",
      quotePackageNameCopy: carDetails?.quotePackageName || "",
      quotePackageNameDigitCopy: carDetails?.quotePackageNameDigit || "",
      quotePackageNameICICICopy: carDetails?.quotePackageNameICICI || "",
      cngKitValuecopy: carDetails?.cngKitValue || "",
      isCngLpgVehicleTypecopy: carDetails?.isCngLpgVehicleTypecopy || ""
    });
    return (() => {
      clearTimeout(id)
    })

  }, [test, carDetails])

  useEffect(() => {
    handleNcbValue(carDetails?.prevPolicyType, carDetails?.PolicyTypeValue);
  }, [carDetails?.PolicyTypeValue]);

  function calculateExpDate(regDateStr) {
    if (
      new Date(carDetails?.policyExpDate).getFullYear() -
      new Date(regDateStr).getFullYear() <=
      3
    ) {
      const regDate = new Date(regDateStr);
      const expDate = new Date(carDetails?.policyExpDate);
      expDate.setFullYear(regDate.getFullYear() + 3);
      const expDateStr = new Date(expDate);
      return expDateStr;
    }
  }

  // main handler for carDetails object
  const handleInputCarDetails = (e, constantName, const1) => {

    console.log(e, constantName, const1, "e, constantName, const1");


    const { name, value, label, checked } = e?.target || {};
    if (name === "isClaimPrevPolicy" || constantName === "isClaimPrevPolicy") {
      // debugger;

      let valVariable = constantName ? e : value;

      setCarDetails({
        ...carDetails,
        [name]: valVariable === "Yes" ? true : false,
      });
      if (valVariable === "Yes") {
        setCarDetails((prevItems) => ({
          ...prevItems,
          prevNcb: "0",
          newNcb: "0",
        }));
        ncbData.newNcb = "0";
        ncbData.prevNcb = "0";
        setcarDetailsCopyState({
          ...carDetailsCopyState,
          isClaimPrevPolicyCopy: true,
          prevNcbCopy: "0",
          newNcbCopy: "0",
        });
      } else {
        setCarDetails((prevItems) => ({
          ...prevItems,
          prevNcb: ncbData?.newNcb,
          newNcb: ncbData?.prevNcb,
        }));
        setcarDetailsCopyState({
          ...carDetailsCopyState,
          isClaimPrevPolicyCopy: false,
          prevNcbCopy: ncbData?.prevNcb,
          newNcbCopy: ncbData?.newNcb,
        });
      }
    } else if (name === "isCngLpgVehicleType") {
      setCarDetails({
        ...carDetails,
        [name]: value === "Yes" ? true : false,
      });
    } else if (name === "vehicleNumber") {
      if (value?.length <= 12) {
        setCarDetails({
          ...carDetails,
          vehicleNumber: value?.toUpperCase(),
        });
      }
    } else if (constantName === "prevNcb") {
      setCarDetails({
        ...carDetails,
        prevNcb: e,
        newNcb: const1,
      });
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        prevNcbCopy: e,
        newNcbCopy: const1,
      }));
    } else if (constantName === "policyRegDate" || name === "policyRegDate") {

      let regDateValue = constantName ? e : value;
      setCarDetails({
        ...carDetails,
        policyRegDate: regDateValue,
      });
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        regDateCopy: regDateValue,
      }));
    } else if (constantName === "policyExpDate") {
      setCarDetails({
        ...carDetails,
        policyExpDate: e,
      });
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        policyExpCopy: e,
      }));
      if (const1 === "makeNcbModalTrue") {
        if (carDetails?.prevPolicyType !== "Third Party") {
          setEditNcbMobile(true);
        }
      }
    } else if (constantName === "prevPolicyODexpDate") {
      setCarDetails({
        ...carDetails,
        prevPolicyODexpDate: e,
      });
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        odPolicyExpCopy: e,
      }));
      if (const1 === "makeNcbModalTrue") {
        if (carDetails?.prevPolicyType !== "Third Party") {
          setEditNcbMobile(true);
        }
      }
    } else if (name === "prevPolicyType" || name === "PolicyTypeValue") {
      if (name === "prevPolicyType") {
        if (value === "Third Party") {
          setCarDetails({
            ...carDetails,
            prevPolicyType: value,
            newNcb: "0",
            prevNcb: "0",
          });
          setcarDetailsCopyState((prevItems) => ({
            ...prevItems,
            newNcbCopy: "0",
            prevNcbCopy: "0",
          }));
        } else {
          setCarDetails({
            ...carDetails,
            prevPolicyType: value,
            newNcb: ncbData?.newNcb,
            prevNcb: ncbData?.prevNcb,
          });
          setcarDetailsCopyState((prevItems) => ({
            ...prevItems,
            newNcbCopy: carDetails?.newNcb,
            prevNcbCopy: carDetails?.prevNcb,
          }));
        }
      } else if (name === "PolicyTypeValue") {
        setShowLoader(true);
        if (value === "Third Party") {
          setCarDetails({
            ...carDetails,
            PolicyTypeValue: value,
            newNcb: "0",
            prevNcb: "0",
          });
          setcarDetailsCopyState((prevItems) => ({
            ...prevItems,
            newNcbCopy: "0",
            prevNcbCopy: "0",
          }));
        } else {
          setCarDetails({
            ...carDetails,
            PolicyTypeValue: value,
            newNcb: ncbData?.newNcb,
            prevNcb: ncbData?.prevNcb,
          });
          setcarDetailsCopyState((prevItems) => ({
            ...prevItems,
            newNcbCopy: carDetails?.newNcb,
            prevNcbCopy: carDetails?.prevNcb,
          }));
        }
      }
    } else {
      setCarDetails({
        ...carDetails,
        [name]: value,
      });
    }
  };

  let vehicleRTOCode = location?.state?.vehicleNumber?.substring(0, 4);
  let compareRTOcode = vehicleData?.rto?.split("-")[0] === vehicleRTOCode;

  let carDetailsobject = {
    cngKitValue: vehicleData?.cngKitValue ? vehicleData?.cngKitValue : null,
    vehicleNumber:
      businessType !== NEW
        ? businessType == "FindMyCar" && !compareRTOcode
          ? vehicleData?.rto?.split("-")[0]
          : vehicleNumber?.toUpperCase()
        : vehicleData?.rto?.split("-")[0],
    // policyRegDate -------> carRegDate hai
    policyRegDate:
      businessType !== NEW
        ? businessType == "FindMyCar" ? DateFun(vehicleData?.regn_dt) : new Date(vehicleData?.regn_dt)
        : formatDate(new Date(vehicleData?.policyStartDate)),
    policyExpDate:
      PolicyTypeValue == "Bundled" && businessType !== NEW
        ? getCalculatedDate.prevoiusPolicyTPEndDate
        : ExpiryDate,
    fuelType:
      businessType == ROLLOVER
        ? vehicleData?.fuelType
          ? vehicleData?.fuelType
          : vehicleData?.model_details?.[0]?.fuelType
        : vehicleData?.fuelType,
    make:
      businessType == ROLLOVER
        ? vehicleData?.make
          ? vehicleData?.make
          : vehicleData?.model_details?.[0]?.Brand
        : vehicleData?.make,
    model:
      businessType == ROLLOVER
        ? vehicleData?.model
          ? vehicleData?.model
          : vehicleData?.model_details?.[0]?.Model
        : vehicleData?.model,
    varient:
      businessType == ROLLOVER
        ? vehicleData?.varient
          ? vehicleData?.varient
          : vehicleData?.model_details?.[0]?.Variant_Name
        : vehicleData?.varient,
    cc:
      businessType == ROLLOVER
        ? vehicleData?.cc
          ? vehicleData?.cc
          : vehicleData?.model_details?.[0]?.displacement
        : vehicleData?.cc,
    isClaimPrevPolicy:
      businessType !== NEW
        ? ncbData?.newNcb != "0"
          ? false
          : vehicleData?.isClaimPrevPolicy
        : "",
    newNcb: businessType !== NEW ? ncbData?.newNcb : "0",
    prevNcb: businessType !== NEW ? ncbData?.prevNcb : "0",
    isPrevPolicyExp: businessType !== NEW ? vehicleData?.isPrevPolicyExp : "",
    prevPolicyExpLastMonth:
      businessType !== NEW
        ? vehicleData?.isPrevPolicyExp === false
          ? false
          : vehicleData?.prevPolicyExpLastMonth
        : "",
    prevPolicyType: businessType !== NEW ? PolicyTypeValue : "",
    prevPolicyODexpDate:
      businessType !== NEW && PolicyTypeValue != "Comprehensive"
        ? new Date(
          getPolicyExpiryDate(
            vehicleData?.isPrevPolicyExp,
            vehicleData?.prevPolicyExpLastMonth
          )
        )
        : "",
    isCngLpgVehicleType: vehicleData?.isCngLpgVehicleType,
    odInternalCngLpg:
      vehicleData?.fuelType?.toUpperCase() == "INTERNAL_LPG_CNG".toUpperCase()
        ? true
        : false,
    tpInternalCngLpg:
      vehicleData?.fuelType?.toUpperCase() == "INTERNAL_LPG_CNG".toUpperCase()
        ? true
        : false,
    typeOfBusiness: businessType == FINDMYCAR ? ROLLOVER : businessType,
    PolicyTypeValue:
      businessType !== NEW
        ? PolicyTypeValue === "Bundled" && carsAge <= 2.5
          ? "Own Damage"
          : "Comprehensive"
        : "Bundled",
    onBLurIdbValue: onBLurIdbValue,
    idvValue: null,
    chassisNumber: businessType == ROLLOVER ? vehicleData?.chassisnumber : "",
    engineNumber: businessType == ROLLOVER ? vehicleData?.enginenumber : "",
    seatingCapacity:
      businessType == ROLLOVER
        ? vehicleData?.model_details?.[0]?.seating_capacity
        : "",
    mfgDate: businessType == ROLLOVER ? new Date(vehicleData?.regn_dt) : "",
    isNilDepInPrevPolicy: "",
    isEngProtectInPrevPolicy: "",
    isRtiInPrevPolicy: "",
    isInvoiceCoverInPrevPolicy: "",
    posp: vehicleData?.isPospSale,
    quotePackageName: "",
    quotePackageNameDigit: "",
    quotePackageNameICICI: "",
    numberOfClaim: "2",
    ownDamge: null,
    totalAddonsPrice: null,
    totalTPprice: null,
    paidDriverAmt: null,
    netPayable: null,
    gstPrice: null,
    grossPremium: null,
    basicTPprice: null,
    paCover: null,
    basicOwnDamge: null,
    ncbDiscount: null,
    ownerName: null,
    email: null,
    phoneNumber: null,
    gstNumber: null,
    companyName: null,
    carOwner: null,
    gender: "male",
    maritalStatus: "single",
    dob: null,
    nomineeName: null,
    nomineeAge: null,
    nomineeRelation: null,
    addressOne: null,
    addressTwo: null,
    state: null,
    city: null,
    pincode: vehicleData?.pincode ? vehicleData?.pincode : pincode,
    mobileNo: vehicleData?.mobileNo ? vehicleData?.mobileNo : mobileNo,
    prevPolicyNumber: null,
    prevPolicyNameInsurer: null,
    carIsInLoan: null,
    carHypothecationCompany: null,
    pcCoverSumInsured: getPreviousAddonData?.pcCoverSumInsured
      ? getPreviousAddonData?.pcCoverSumInsured
      : null,
    electricalAccesories: getPreviousAddonData?.electricalAccesories
      ? getPreviousAddonData?.electricalAccesories
      : null,
    nonElectricalAccesories: getPreviousAddonData?.nonElectricalAccesories
      ? getPreviousAddonData?.nonElectricalAccesories
      : null,
    isFindMyCar: isFindMyCar,
  }

  useEffect(() => {
    if (updateCarDetails) {
      let regDate = carDetails?.policyRegDate;
      let getPolicyTypeDetails = getPolicyType(
        carDetails?.policyRegDate,
        carDetails?.policyExpDate,
        carDetails?.PolicyTypeValue,
      );

      let expDate = calculateExpDate(regDate);

      setCarDetails({
        ...carDetails,
        policyRegDate: regDate,
        prevPolicyType: businessType !== NEW ? getPolicyTypeDetails : "",
        PolicyTypeValue:
          businessType !== NEW
            ? getPolicyTypeDetails === "Bundled" && carsAge <= 2.6
              ? "Own Damage"
              : getPolicyTypeDetails === "Bundled" && carsAge > 2.6
                ? "Comprehensive"
                : getPolicyTypeDetails
            : "Bundled",
        prevPolicyODexpDate:
          businessType != NEW ? (carDetails?.prevPolicyODexpDate ? carDetails?.prevPolicyODexpDate : (expDate ? ExpiryDate : "")) : ExpiryDate,
      });
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        regDateCopy: regDate,
        prevPolicyTypeCopy: businessType !== NEW ? getPolicyTypeDetails : "",
      }));
    }
  }, [carDetails?.policyRegDate]);

  useEffect(() => {
    let presentDay = new Date();
    presentDay.setHours(0, 0, 0, 0);
    let policyExpDate = new Date(carDetails?.policyExpDate);
    policyExpDate.setHours(0, 0, 0, 0);
    if (policyExpDate.getTime() < presentDay.getTime()) {
      if (carDetails?.prevPolicyExpLastMonth !== false) {
        setCarDetails((prevItems) => ({
          ...prevItems,
          newNcb: "0",
          prevNcb: "0",
        }));
        setcarDetailsCopyState({
          ...carDetailsCopyState,
          prevNcbCopy: "0",
          newNcbCopy: "0",
        });
      }
      if (carDetails?.typeOfBusiness != "New") {
        dispatch(
          isInitialAction({
            isInspectionReq: true,
          })
        );
      }
    }
    else {
      setCarDetails((prevItems) => ({
        ...prevItems,
        isPrevPolicyExp: false,
        prevPolicyExpLastMonth: false,
      }));
      setcarDetailsCopyState({
        ...carDetailsCopyState,
        prevNcbCopy: ncbData?.prevNcb,
        newNcbCopy: ncbData?.newNcb,
      });
    }
  }, [carDetails?.policyExpDate]);

  const cancelCalendarValue = (name) => {
    switch (name) {
      case "regDateCopy":
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          regDateCopy: carDetails?.policyRegDate,
        }));
        setShowRegDateCalendar(false);
        break;

      case "odPolicyExpCopy":
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          odPolicyExpCopy: carDetails?.prevPolicyODexpDate,
        }));
        setShowOdExpDateCalendar(false);
        break;

      case "policyExpCopy":
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          policyExpCopy: carDetails?.policyExpDate,
        }));
        setShowExpDateCalendar(false);
        break;

      default:
        break;
    }
  };

  const cancelCalendarValueFlow = (name) => {
    if (name === "odPolicyExpCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        odPolicyExpCopy: carDetails?.prevPolicyODexpDate,
      }));
      setShowFlowOdCalendar(false);
      setPrevPolicyTypeList(true);
    } else if (name === "policyExpCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        policyExpCopy: carDetails?.policyExpDate,
      }));
      navigate(-1)
    } else {
      console.log("kdkdkd");
    }
  };

  const saveCalendarValue = (name) => {

    switch (name) {
      case "regDateCopy":
        handleInputCarDetails(
          carDetailsCopyState?.regDateCopy,
          "policyRegDate",
          ""
        );
        setShowRegDateCalendar(false);
        break;

      case "odPolicyExpCopy":
        handleInputCarDetails(
          carDetailsCopyState?.odPolicyExpCopy,
          "prevPolicyODexpDate",
          ""
        );
        setShowOdExpDateCalendar(false);
        break;

      case "policyExpCopy":
        handleInputCarDetails(
          carDetailsCopyState?.policyExpCopy,
          "policyExpDate",
          ""
        );
        setShowExpDateCalendar(false);
        break;

      default:
        break;
    }
  };

  const saveCalendarValueExpFlow = (name) => {
    switch (name) {
      case "odPolicyExpCopy":
        setShowFlowOdCalendar(false);
        setPrevPolicyTypeList(true);
        break;

      case "policyExpCopy":
        setShowFlowExpCalendar(false);
        setPrevPolicyTypeList(true);
        break;

      default:
        break;
    }
  };

  const saveCalendarValueModal = (name) => {
    switch (name) {
      case "regDateCopy":
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          regDateCopy: RegDateClone,
        }));
        setShowRegDateCalendarModal(false);
        break;

      case "odPolicyExpCopy":
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          odPolicyExpCopy: OdExpDateClone,
        }));
        setShowOdExpDateCalendarModal(false);
        break;

      case "policyExpCopy":
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          policyExpCopy: ExpDateClone,
        }));
        setShowExpDateCalendarModal(false);
        break;

      default:
        break;
    }
  };

  const cancelCalendarValueModal = (name) => {
    switch (name) {
      case "regDateCopy":
        setRegDateClone(carDetailsCopyState?.regDateCopy);
        setShowRegDateCalendarModal(false);
        break;

      case "odPolicyExpCopy":
        setOdExpDateClone(carDetailsCopyState?.odPolicyExpCopy);
        setShowOdExpDateCalendarModal(false);
        break;

      case "policyExpCopy":
        setExpDateClone(carDetailsCopyState?.policyExpCopy);
        setShowExpDateCalendarModal(false);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if ((carDetailsCopyState?.prevPolicyTypeCopy == "Comprehensive" || carDetailsCopyState?.prevPolicyTypeCopy == "Bundled")
      && carDetails?.newNcb == 0
      && carDetailsCopyState?.newNcbCopy == 0
      && carDetails?.PolicyTypeValue !== "Third Party") {
      setncbData(calculatePrevNcbNewNcb(
        carDetails?.policyRegDate,
        carDetails?.isClaimPrevPolicy,
        carDetails?.typeOfBusiness,
        carDetails?.prevPolicyExpLastMonth))
      setcarDetailsCopyState((prev) => ({
        ...prev,
        ncbCopy: ncbData?.newNcb,
        prevNcbCopy: ncbData?.prevNcb
      }))
      setCarDetails((prev) => ({
        ...prev,
        newNcb: ncbData?.newNcb,
        prevNcb: ncbData?.prevNcb
      }))
    }

  }, [carDetails?.prevPolicyType, carDetails?.PolicyTypeValue, CalendarDeactiveFlag, carDetails?.policyRegDate])

  useEffect(() => {
    if ((carDetailsCopyState?.prevPolicyTypeCopy == "Comprehensive" || carDetailsCopyState?.prevPolicyTypeCopy == "Bundled")
      && carDetails?.newNcb == 0
      && carDetailsCopyState?.newNcbCopy == 0
      && carDetails?.PolicyTypeValue !== "Third Party") {
      setncbData(calculatePrevNcbNewNcb(
        carDetails?.policyRegDate,
        carDetails?.isClaimPrevPolicy,
        carDetails?.typeOfBusiness,
        carDetails?.prevPolicyExpLastMonth))
      setcarDetailsCopyState((prev) => ({
        ...prev,
        ncbCopy: ncbData?.newNcb,
        prevNcbCopy: ncbData?.prevNcb
      }))
    }

  }, [carDetailsCopyState?.prevPolicyTypeCopy])

  let newCheckBoxData = [];

  if (
    AddonsForMobile === true &&
    carDetails?.PolicyTypeValue === "Third Party"
  ) {
    newCheckBoxData = test.filter((data) => {
      return (
        data.id === "checkbox10" ||
        data.id === "checkbox11" ||
        data.id === "checkbox1"
      );
    });
  } else if (
    AddonsForMobile === true &&
    carDetails?.PolicyTypeValue === "Own Damage"
  ) {
    newCheckBoxData = test.filter((data) => {
      return (
        data.id != "checkbox1" &&
        data.id != "checkbox10" &&
        data.id != "checkbox11"
      );
    });
  } else if (carDetails?.PolicyTypeValue === "Third Party") {
    newCheckBoxData = test.filter((data) => {
      return (
        data.id === "checkbox10" ||
        data.id === "checkbox11" ||
        data.id === "checkbox1"
      );
    });
  } else if (carDetails?.PolicyTypeValue === "Own Damage") {
    newCheckBoxData = test.filter((data) => {
      return (
        data.id != "checkbox1" &&
        // data.id != "checkbox2" &&
        data.id != "checkbox10" &&
        data.id != "checkbox11"
      );
    });
  } else if (AddonsForMobile) {
    newCheckBoxData = test.filter((data) => {
      return data;
    });
  } else {
    newCheckBoxData = test.filter((data) => {
      return data;
    });
  }

  const handleIDVinput = (e) => {
    const inputValue = e?.target?.value;
    const minValue = allIDVValues.min === Infinity ? 0 : allIDVValues.min;
    setCarDetails((prevItems) => ({
      ...prevItems,
      idvValue: inputValue !== minValue ? inputValue : minValue,
    }));
  };

  useEffect(() => {
    let presentDay = new Date();
    presentDay.setHours(0, 0, 0, 0);
    let policyExpDate = new Date(carDetails?.policyExpDate);
    policyExpDate.setHours(0, 0, 0, 0);
    if (transactionId) {
      (async function () {
        setShowLoader(true);
        let data = {
          uuid: transactionId,
          bodyData: {
            ...carDetails,
            ...{ coverage: [test] },
            ...getCalculatedDate,
          },
          vehicleNumber,
          businessType,
          isFindMyCar,
          vehicleData: vehicleData,
          carDetails,
          type: "fetch",
          addOnData: test,
          dealerCode
        };
        let resp = await dispatch(transactionURLAction(data));
        setTimeout(() => {
          setUpdateCarDetails(true);
        }, 4000);
        if (Array.isArray(resp?.payload?.data?.txninfo)) {
          if (
            resp?.payload?.data?.txninfo[0].car_details_obj &&
            resp?.payload?.data?.txninfo[0].vehicle_data
          ) {
            setShowFlowExpCalendar(false);
            setShowFlowOdCalendar(false);
            await dispatch(
              isViewPlanFlowAction({
                isViewPlanFlowCompleted: true,
              })
            );
            let carDetailsObj = JSON.parse(
              resp?.payload?.data?.txninfo[0].car_details_obj
            );

            let bodyData = JSON.parse(
              resp?.payload?.data?.txninfo[0].body_data
            );
            await dispatch(allBodyDataAction(bodyData));
            // if (bodyData) {
            //   setstopApi(true)
            // }

            setTest(bodyData?.coverage[0]);
            vehicleNumber = resp?.payload?.data?.txninfo[0].vehicle_number;
            setBusinessType(resp?.payload?.data?.txninfo[0].business_type);
            setCarDetails(carDetailsObj);
            carDetailsObj.makeCopy = carDetailsObj?.make;
            carDetailsObj.modelCopy = carDetailsObj?.model;
            carDetailsObj.fuelTypeCopy = carDetailsObj?.fuelType;
            carDetailsObj.varientCopy = carDetailsObj?.varient;
            setCarDetails((prev) => ({
              ...prev,
              quotePackageNameICICI: "",
              quotePackageNameDigit: ""
            }))

            setcarDetailsCopyState((prev) => ({
              ...prev,
              quotePackageNameDigitCopy: "",
              quotePackageNameICICICopy: ""
            }))
            setcarDetailsCopyState(carDetailsObj);
            setncbData({
              newNcb: carDetailsObj?.newNcb,
              prevNcb: carDetailsObj?.prevNcb,
            });
            setncbCopy({
              newNcb: carDetailsObj?.newNcb,
              prevNcb: carDetailsObj?.prevNcb,
            });

            //for inspection tag
            if ((policyExpDate.getTime() > presentDay.getTime()) && carDetails?.prevPolicyExpLastMonth == false) {
              if (
                carDetailsObj?.prevPolicyType === "Third Party" &&
                carDetailsObj?.PolicyTypeValue === "Comprehensive"

              ) {

                if (carDetailsObj.typeOfBusiness != "New" && carDetailsObj?.PolicyTypeValue === !"Third Party") {
                  await dispatch(
                    isInitialAction({
                      isInspectionReq: true,
                    })
                  );
                }

              }
              else {
                await dispatch(
                  isInitialAction({
                    isInspectionReq: false,
                  })
                );
              }

            }


            let vehDetailsObj = JSON.parse(
              resp?.payload?.data?.txninfo[0].vehicle_data
            );
            vehDetailsObj.make = carDetailsObj?.make;
            vehDetailsObj.model = carDetailsObj?.model;
            vehDetailsObj.varient = carDetailsObj?.varient;
            vehDetailsObj.cc = carDetailsObj?.cc;

            let vehicleDataObj =
              resp?.payload?.data?.txninfo[0].business_type == ROLLOVER
                ? { data: vehDetailsObj }
                : {
                  data: {
                    data: vehDetailsObj,
                  },
                };
            await dispatch(updateVehicleInfoAction({ ...vehicleDataObj }));
          } else {
            setCarDetails(carDetailsobject)

            if (businessType !== NEW && getviewPlanFlowStatus !== true && vehicleData.prevPolicyExpLastMonth !== true) {
              if (PolicyTypeValue === "Bundled" && carsAge < 2.5) {
                setShowFlowOdCalendar(true);
              } else {
                setShowFlowExpCalendar(true);
              }
            }
          }
        } else {
          setCarDetails(carDetailsobject)
        }
        setShowLoader(false);
        return () => { };
      })();
    }
  }, []);

  const HandleInsurerList = (e) => {
    console.log(e.target.value, "HandleInsurerList");
  };

  // fun for dropdown option container
  document.onclick = function (e) {
    let id = e?.target?.id;
    let parentId = e?.target?.parentNode?.id;
    if (
      id === "policyTypeHead" ||
      id === "idForArrowPD" ||
      id === "idForHeadTxtPD" ||
      id === "idForPtinp"
    ) {
      setshowPolicyDropdown(!showPolicyDropdown);
      setshowAddonMenu(false);
      setshowInsDropDown(false);
    } else if (
      id === "addonHead" ||
      id === "idForArrowAddon" ||
      id === "addonHeadTxt"
    ) {
      setshowAddonMenu(!showAddonMenu);
      setshowPolicyDropdown(false);
      setshowInsDropDown(false);
    } else if (
      id === "insHead" ||
      id === "insHeadTxt" ||
      id === "insHeadArrow"
    ) {
      setshowInsDropDown(!showInsDropDown);
      setshowPolicyDropdown(false);
      setshowAddonMenu(false);
    } else if (parentId === "insDpContainer" || parentId === "insParentDp") {
      setshowInsDropDown(true);
      setshowPolicyDropdown(false);
      setshowAddonMenu(false);
    } else if (parentId === "topAddon" || id === "addonsList") {
      setshowAddonMenu(true);
      setshowPolicyDropdown(false);
      setshowInsDropDown(false);
    } else if (id === "policyTypeList") {
      setshowPolicyDropdown(true);
      setshowInsDropDown(false);
      setshowAddonMenu(false);
    } else if (
      e.target.className === "ReactModal_Overlay ReactModal_Overlay--after-open"
    ) {
      console.log("kakakaka");
    } else {
      setshowPolicyDropdown(false);
      setshowAddonMenu(false);
      setshowInsDropDown(false);
    }
  };

  const AddonsList = newCheckBoxData.map((data) => (
    <ToolTip
      condition={ShowAccesoriesField}
      conditionTwo={ShowPcSumInsuredField}
      key={data.id}
      content={data.tooltip || "No Data"}
    >
      <CheckBox
        label={data.label === "Mandate by IRDAI" ? "PA Cover" : data.label}
        name={data.name}
        value={data.value}
        className={data.className}
        changeEvent={handleCheckboxChange}
        checked={data.checked}
        id={data.id}
      />
    </ToolTip>
  ));

  const handlePopUps = () => {
    if (ShowPassengerAmtList === true) {
      setShowPassengerAmtList(false);
    }
    if (ShowNumber) {
      setShowNumber(false);
    }
  };

  // CALCULATING PREVIOUS POLICY TYPE:

  if (carsAge < 3) {
    policyTypeList = [
      {
        value: "Bundled",
        label: "Bundled",
        desc: "1 Year OD + 3 year Third Party",
      },
    ];
  } else if (carsAge > 3 && carsAge < 3.5) {
    policyTypeList = [
      {
        value: "Comprehensive",
        label: "Comprehensive",
        desc: "1 Year OD + 1 year Third Party",
      },
      {
        value: "Third Party",
        label: "Third Party",
        desc: "Offers protection against damages to the Third-Party by the insured vehicle.",
      },
      {
        value: "Bundled",
        label: "Bundled (3Y TP + 1Y OD)",
        desc: "1 Year OD + 3 year Third Party",
      },
    ];
  } else {
    policyTypeList = [
      {
        value: "Comprehensive",
        label: "Comprehensive",
        desc: "1 Year OD + 1 year Third Party",
      },
      {
        value: "Third Party",
        label: "Third Party",
        desc: "Offers protection against damages to the Third-Party by the insured vehicle.",
      },
    ];
  }

  const savePrevPolicySection = (sectionType) => {
    if (sectionType === "newPolicyType") {
      let e = {
        target: {
          name: "PolicyTypeValue",
          value: carDetailsCopyState.policyTypeValueCopy,
        }
      }
      handleInputCarDetails(e, "", "");
      setEditPolicyTypeMobile(false);
    } else {
      setPrevPolicyTypeList(false);
      if (
        carDetailsCopyState?.prevPolicyTypeCopy !== "Third Party" &&
        carDetails?.prevPolicyExpLastMonth !== true
      ) {
        setEditNcbMobile(true);
      } else {
        if (SelectedAlternateExpOption === "Policy expired in last 90 days.") {
          setEditNcbMobile(true);
        } else {
          setCarDetails((prevItems) => ({
            ...prevItems,
            prevNcb: "0",
            newNcb: "0",
            policyExpDate: carDetailsCopyState?.policyExpCopy,
            prevPolicyODexpDate: carDetailsCopyState?.odPolicyExpCopy || "",
            prevPolicyType: carDetailsCopyState?.prevPolicyTypeCopy,
          }));
          dispatch(
            isViewPlanFlowAction({
              isViewPlanFlowCompleted: true,
            })
          );
        }
      }
    }
  };

  const cancelPrevPolicySection = (sectionType) => {
    if (sectionType === "newPolicyType") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        policyTypeValueCopy: carDetails?.PolicyTypeValue,
      }));
      setEditPolicyTypeMobile(false);
    } else {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        prevPolicyTypeCopy: carDetails?.prevPolicyType,
      }));
      setPrevPolicyTypeList(false);
    }
  };

  const handleModalDates = (value, name, emptyString) => {
    switch (name) {
      case "regDateCopy":
        setRegDateClone(value);
        break;

      case "odPolicyExpCopy":
        setOdExpDateClone(value);
        break;

      case "policyExpCopy":
        setExpDateClone(value);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (
      EditPolicyTypeMobile ||
      ShowRegDateCalendar ||
      EditMmvMobile ||
      ShowIdvTab ||
      AddonsForMobile ||
      // EditNcbMobile ||
      ShowExpDateCalendar ||
      ShowLoader ||
      (CompanyWiseLoader && LoaderFromConfirmPg) ||
      // ShowFlowExpCalendar ||
      // ShowFlowOdCalendar ||
      // PrevPolicyTypeList ||
      ShowNilDepModal ||
      ShowICICIPackageModal ||
      ShowDigPackageModal ||
      ShowPackageModal ||
      ShowMmvVerifyTab ||
      NoRespforCom
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [
    EditPolicyTypeMobile,
    ShowRegDateCalendar,
    EditMmvMobile,
    ShowIdvTab,
    AddonsForMobile,
    // EditNcbMobile,
    ShowExpDateCalendar,
    ShowLoader,
    CompanyWiseLoader,
    // ShowFlowExpCalendar,
    // ShowFlowOdCalendar,
    ShowMmvVerifyTab,
    ShowNilDepModal,
    ShowPackageModal,
    ShowDigPackageModal,
    ShowICICIPackageModal,
    NoRespforCom
    // PrevPolicyTypeList,
  ]);

  const getAlternateExpOption = (option, calendarType) => {
    setSelectedAlternateExpOption(option);
    const currentDate = new Date();
    let expirationDate;

    switch (option) {
      case "Policy has not expired yet.":
        expirationDate = new Date(
          currentDate.getTime() + 7 * 24 * 60 * 60 * 1000
        );
        setPrevPolicyTypeList(true);
        setShowFlowExpCalendar(false);
        setShowFlowOdCalendar(false);
        // prevent user to select past dates
        const futureDate = new Date(currentDate);
        futureDate.setDate(currentDate.getDate() + 45);

        setMinDateValue(currentDate);
        setMaxDateValue(futureDate);
        dispatch(
          isInitialAction({
            isInspectionReq: false,
          })
        );
        break;
      case "Policy expired in last 90 days.":
        expirationDate = new Date(
          currentDate.getTime() - 60 * 24 * 60 * 60 * 1000
        );
        dispatch(
          isInitialAction({
            isInspectionReq: true,
          })
        );
        const yesterdayDate1 = new Date(currentDate);
        const ninetyDaysBackDate1 = new Date(currentDate);

        yesterdayDate1.setDate(currentDate.getDate() - 1);
        ninetyDaysBackDate1.setDate(currentDate.getDate() - 90);

        setMaxDateValue(yesterdayDate1);
        setMinDateValue(ninetyDaysBackDate1);
        // prevent user to select future dates
        setPrevPolicyTypeList(true);
        setShowFlowExpCalendar(false);
        setShowFlowOdCalendar(false);
        break;
      case "Policy expired more than 90 days.":
        expirationDate = new Date(
          currentDate.getTime() - 90 * 24 * 60 * 60 * 1000
        );
        const yesterdayDate = new Date(currentDate);
        const ninetyDaysBackDate = new Date(currentDate);

        yesterdayDate.setDate(currentDate.getDate() - 180);
        ninetyDaysBackDate.setDate(currentDate.getDate() - 90);

        setMaxDateValue(ninetyDaysBackDate);
        setMinDateValue(yesterdayDate);
        dispatch(
          isInitialAction({
            isInspectionReq: true,
          })
        );
        // prevent user to select dates under past 90 days
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          prevNcbCopy: "0",
          newNcbCopy: "0",
        }));
        setPrevPolicyTypeList(true);
        setShowFlowExpCalendar(false);
        setShowFlowOdCalendar(false);
        break;
      case "I purchased a used car.":
        expirationDate = new Date(
          currentDate.getTime() - 90 * 24 * 60 * 60 * 1000
        );
        dispatch(
          isInitialAction({
            isInspectionReq: true,
          })
        );

        const yesterdayDate2 = new Date(currentDate);
        const ninetyDaysBackDate2 = new Date(currentDate);

        yesterdayDate2.setDate(currentDate.getDate() - 180);
        ninetyDaysBackDate2.setDate(currentDate.getDate() - 90);

        setMaxDateValue(ninetyDaysBackDate2);
        setMinDateValue(yesterdayDate2);

        // prevent user to select dates under past 90 days
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          prevNcbCopy: "0",
          newNcbCopy: "0",
        }));

        setPrevPolicyTypeList(true);
        setShowFlowExpCalendar(false);
        setShowFlowOdCalendar(false);
        break;
      default:
        console.log("Invalid option");
        return;
    }

    if (calendarType === "expDate") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        policyExpCopy: expirationDate,
      }));
    } else {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        odPolicyExpCopy: expirationDate,
      }));
    }
  };

  const showAddonsForMobile = (type) => {
    let zoomLevel;
    if (type === "show") {
      if (window.innerWidth <= 400) {
        zoomLevel = 1;
        document.body.style.zoom = zoomLevel?.toString();
      }
      setAddonsForMobile(true);
    } else if (type === "hide") {
      if (window.innerWidth <= 400) {
        zoomLevel = 0.9;
        document.body.style.zoom = zoomLevel?.toString();
      }
      setAddonsForMobile(false);
    } else {
      if (window.innerWidth <= 400) {
        zoomLevel = 1;
        document.body.style.zoom = zoomLevel?.toString();
      }
    }
  }

  const month = new Date(carDetails?.policyRegDate).toLocaleString("default", {
    month: "long",
  });

  const year = new Date(carDetails?.policyRegDate).toLocaleString("default", {
    year: "numeric",
  });

  const carDetailsContent = [
    {
      id: 1,
      heading: "Registraion Number",
      value: carDetails?.vehicleNumber,
      headingTwo: "Make",
      valueTwo: carDetails?.make,
    },
    {
      id: 2,
      heading: "Model",
      value: carDetails?.model,
      headingTwo: "Varient",
      valueTwo: carDetails?.varient,
    },
    {
      id: 3,
      heading: "CC",
      value: carDetails?.cc,
      headingTwo: "Fuel Type",
      valueTwo: carDetails?.fuelType,
    },
    {
      id: 4,
      heading: "Registration Date",
      value: formattedRegDate,
      headingTwo: "Manufacturing",
      valueTwo: month + " " + year,
    },
  ];

  const prevPolicyContent = [
    {
      id: 1,
      heading: "Previous Policy Type",
      value: carDetails?.prevPolicyType,
      headingTwo: "Claim",
      valueTwo: carDetails?.isClaimPrevPolicy == true ? "Yes" : "No",
    },
    {
      id: 2,
      heading: "Policy Expiry Date",
      value: businessType !== "New" ? (carDetails?.prevPolicyType === 'Bundled' ? formattedODexpDate : formattedpolicyExpDate) : "",
      headingTwo: "Previous NCB",
      valueTwo: carDetails?.prevNcb + "%",
    },
  ];

  const handleEditModal = (detailType) => {
    if (detailType === "carDetails") {
      setEditMmvMobile(!EditMmvMobile);
      setRenderEditFields(detailType);
    } else if (detailType === "close") {
      setEditMmvMobile(false);
      setRenderEditFields(detailType);
    } else if (detailType === "prevPolicyDetails") {
      setEditMmvMobile(!EditMmvMobile);
      setRenderEditFields(detailType);
    }
    setShowMmvVerifyTab(false);
  };
  useEffect(() => {
    setCarDetails((prev) => ({
      ...prev,
      quotePackageNameDigit: "",
      quotePackageNameICICI: ""
    }))

    setcarDetailsCopyState((prev) => ({
      ...prev,
      quotePackageNameDigitCopy: "",
      quotePackageNameICICICopy: ""
    }))

  }, [carDetails?.policyRegDate, formattedRegDate])


  return (
    <>
      <Navbar />

      {/* hdfc design */}
      {/* <Modal
        isOpen={hdfcTermsPdf}
        style={customStyles}
      >
        <div id="hdfcTermspdf">
          <div>
            <img src={hdfctermsicon} alt="Right arrow" />
            <h1>Terms and Conditions</h1>
          </div>

          <hr/>

          <div>
            <p>
              Before proceeding to the payment, please ensure that
              you have reviewed the Proposal Document. It contains
              important information about your insurance coverage
              and terms.<br /><br />
              If you haven't read it yet, please download the
              document and review it thoroughly before continuing.
            </p>
          </div>

          <Botton
            text="Download Proposal Document"
            className="button btn_pForm_hdfc secondry_btn_hdfc"
            event={(e) => console.log("Download")}
          />

          <p>
            By clicking "I Agree," you confirm that you have read and
            understood the Proposal Document and agree to our
            Terms and Conditions and Privacy Policy.
          </p>

          <div className="checkbox_container checkbox_container-modalinp">
            <CheckBox
              label="I have read and accept the Terms and Conditions 
        and Privacy Policy."
              className="small_text small_textModal checkBox-label"
              type="checkbox"
            />
          </div>

          <section className="btnContainer_pform">
            <Botton
              text="Cancel"
              className="button btn_pForm secondry_btn"
              event={(e) => console.log("Cancel")}
            />
            <Botton
              text="I Agree"
              className="button btn_pForm"
              event={(e) => console.log("I Agree")}
            />
          </section>
        </div>
      </Modal> */}


      <CallWidget revealState={ShowNumber} updateRevealState={setShowNumber} />

      {((ShowLoader || (CompanyWiseLoader && LoaderFromConfirmPg)) || !carDetails?.policyRegDate) && (
        <div id="cover-spin">
          <img src={loader2} className="gifLoader"></img>
        </div>
      )}

      <div className="vp-container" onClick={(e) => handlePopUps()}>
        <form action="" onSubmit={(e) => { e.preventDefault(); }} className="viewPlans_content_container">
          <div
            className="viewPlans_content_parent maxWidth"
            id={
              carDetails?.PolicyTypeValue === "Third Party" ||
                carDetails?.prevPolicyType === "Third Party" ||
                vehicleData?.prevPolicyExpLastMonth === true
                ? "flexStart"
                : ""
            }
          >
            <div
              className={
                businessType === "New"
                  ? "viewPlans_content_child-new "
                  : "viewPlans_content_childOne viewPlans_content_child"
              }
              id={
                carDetails?.PolicyTypeValue === "Third Party" ||
                  carDetails?.prevPolicyType === "Third Party" ||
                  vehicleData?.prevPolicyExpLastMonth === true
                  ? "noBorderVp"
                  : ""
              }
            >
              <Paper
                type="submit"
                businessType={businessType}
                mainTitle="My Car Details"
                heading="RTO Registration Number:"
                desc={
                  businessType !== NEW
                    ? carDetails?.vehicleNumber ? carDetails?.vehicleNumber?.toUpperCase() : storageVehicleNumber?.toUpperCase()
                    : vehicleData?.rto
                }
                headingTwo="Company Model:"
                HeadFour={carDetails?.PolicyTypeValue !== "Bundled" ? " RTO Registered Date:" : "Policy Started Date:"}
                classNameDate="datepicker-viewplans viewPlan_paper_child_title"
                descTwo={`${carDetails?.make || ""} ${carDetails?.model || ""
                  } ${carDetails?.varient || ""} (${carDetails?.cc || ""} CC)`}
                HeadThree="Fuel Type:"
                descThree={carDetails?.fuelType || ""}
                renderContent="editCarDetails"
                HandleRegDate={(e) => handleInputCarDetails(e)}
                value={carDetails?.policyRegDate}
                name="policyRegDate"
                carDetails={{ ...carDetails, ...getCalculatedDate }}
                handleInputCarDetails={handleInputCarDetails}
                MakeList={MakeList}
                FuelTypeList={FuelTypeList}
                ccList={ccList}
                ModelListArray={ModelListArray}
                VarientList={VarientList}
                setCarDetails={setCarDetails}
                carDetailsCopyState={carDetailsCopyState}
                handleCopiedDetails={handleCopiedDetails}
                setcarDetailsCopyState={setcarDetailsCopyState}
                handleMakeSelect={handleMakeSelect}
                handleModelSelect={handleModelSelect}
                handleFuelSelect={handleFuelSelect}
                handleVariantSelect={handleVariantSelect}
                handleCCSelect={handleCCSelect}
                CalendarDeactiveFlag={CalendarDeactiveFlag}
                componentClass={viewPlansClassNameForPaper}
                handleNcbValue={handleNcbValue}
                ShowRegDateCalendar={ShowRegDateCalendarModal}
                setShowRegDateCalendar={setShowRegDateCalendarModal}
                component={
                  <CustomDateField
                    className="customDatePicker customDatePicker-tab"
                    btnId="policyRegDateBtn"
                    textId="policyRegDateTxt"
                    imgId="policyRegDateImg"
                    CalendarDeactiveFlag={CalendarDeactiveFlag}
                    dateValue={carDetails?.policyRegDate}
                    calendarReveal={ShowRegDateCalendar}
                    setCalendarReveal={setShowRegDateCalendar}
                  />
                }
              />
            </div>
            {businessType !== "New" && (
              <>
                <div
                  className="viewPlans_content_child"
                  id={
                    carDetails?.PolicyTypeValue === "Third Party" ||
                      carDetails?.prevPolicyType === "Third Party" ||
                      vehicleData?.prevPolicyExpLastMonth === true
                      ? "noBorderLeft"
                      : ""
                  }
                >
                  <Paper
                    type="submit"
                    businessType={businessType}
                    mainTitle="My Existing Policy Details"
                    heading="Policy Type:"
                    desc={
                      carDetails?.prevPolicyType === "Bundled (3Y TP + 1Y OD)"
                        ? "Bundled"
                        : carDetails?.prevPolicyType
                    }
                    HeadThree={
                      carDetails?.PolicyTypeValue === "Own Damage"
                        ? "OD Expiry Date:"
                        : carDetails?.prevPolicyType === "Bundled" ||
                          carDetails?.prevPolicyType ===
                          "Bundled (3Y TP + 1Y OD)"
                          ? "Bundled Expiry Date:"
                          : "Policy Expiry Date:"
                    }
                    classNameDate="datepicker-viewplans viewPlan_paper_child_title"
                    renderContent="editPolDetails"
                    PolicyTypeValue={carDetails?.prevPolicyType}
                    handleInputCarDetails={handleInputCarDetails}
                    carDetails={{ ...carDetails, ...getCalculatedDate }}
                    HandleExpDate={(e) => handleInputCarDetails(e)}
                    value={carDetails?.policyExpDate}
                    name="policyExpDate"
                    MakeList={MakeList}
                    setCarDetails={setCarDetails}
                    carDetailsCopyState={carDetailsCopyState}
                    handleCopiedDetails={handleCopiedDetails}
                    setcarDetailsCopyState={setcarDetailsCopyState}
                    componentClass={viewPlansClassNameForPaper}
                    showCurrentPolicyType={false}
                    handleNcbValue={handleNcbValue}
                    CalendarDeactiveFlag={CalendarDeactiveFlag}
                    policyTypeList={policyTypeList}
                    ShowOdExpDateCalendar={ShowOdExpDateCalendarModal}
                    setShowOdExpDateCalendar={setShowOdExpDateCalendarModal}
                    ShowExpDateCalendar={ShowExpDateCalendarModal}
                    setShowExpDateCalendar={setShowExpDateCalendarModal}
                    componentTwo={
                      <>
                        {carDetails?.PolicyTypeValue === "Own Damage" ? (
                          <CustomDateField
                            className="customDatePicker customDatePicker-tab"
                            btnId="policyOdExpDateBtn"
                            textId="policyOdExpDateTxt"
                            imgId="policyOdExpDateImg"
                            CalendarDeactiveFlag={CalendarDeactiveFlag}
                            dateValue={carDetails?.prevPolicyODexpDate}
                            calendarReveal={ShowOdExpDateCalendar}
                            setCalendarReveal={setShowOdExpDateCalendar}

                          />
                        ) : (
                          <CustomDateField
                            className="customDatePicker customDatePicker-tab"
                            btnId="policyExpDateBtn"
                            textId="policyExpDateTxt"
                            imgId="policyExpDateImg"
                            dateValue={carDetails?.policyExpDate}
                            CalendarDeactiveFlag={CalendarDeactiveFlag}
                            calendarReveal={ShowExpDateCalendar}
                            setCalendarReveal={setShowExpDateCalendar}
                          />
                        )}
                      </>
                    }
                  />
                </div>
                {carDetails?.PolicyTypeValue === "Third Party" ||
                  carDetails?.prevPolicyType === "Third Party" ||
                  carDetails?.prevPolicyExpLastMonth === true ? (
                  ""
                ) : (
                  <div className="viewPlans_content_child">
                    <Paper
                      type="submit"
                      businessType={businessType}
                      mainTitle="No Claim Bonus Details"
                      heading="Claim:"
                      desc={carDetails?.isClaimPrevPolicy ? "Yes" : "No"}
                      headingTwo={
                        !carDetails?.isClaimPrevPolicy ? "New NCB:" : ""
                      }
                      descTwo={
                        !carDetails?.isClaimPrevPolicy
                          ? carDetails?.newNcb + "%" || 0 + "%"
                          : ""
                      }
                      HeadThree={
                        !carDetails?.isClaimPrevPolicy ? "Previous NCB:" : ""
                      }
                      descThree={
                        !carDetails?.isClaimPrevPolicy
                          ? carDetails?.prevNcb + "%" || 0 + "%"
                          : ""
                      }
                      isClaimPrevPolicy={carDetails?.isClaimPrevPolicy}
                      renderContent="editClaimDetails"
                      classNameDate="viewPlan_paper_child_title"
                      componentClass={viewPlansClassNameForPaper}
                      handleInputCarDetails={handleInputCarDetails}
                      carDetails={{ ...carDetails, ...getCalculatedDate }}
                      setCarDetails={setCarDetails}
                      carDetailsCopyState={carDetailsCopyState}
                      CalendarDeactiveFlag={CalendarDeactiveFlag}
                      handleCopiedDetails={handleCopiedDetails}
                      handleMakeSelect={handleMakeSelect}
                      setcarDetailsCopyState={setcarDetailsCopyState}
                      handleNcbValue={handleNcbValue}
                    // setstopApi={setstopApi}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </form>

        {/* *******************************************CALENDAR-POPUPS********************************* */}

        {/* reg date calendar */}

        {(ShowRegDateCalendar || ShowRegDateCalendarModal) && (
          <CustomCalendar
            value={
              ShowRegDateCalendarModal
                ? RegDateClone
                : carDetailsCopyState?.regDateCopy
            }
            headText={businessType === NEW ? IsRegDateActive ? "Enter your Car registration date" : "Enter your Policy Start Date" : "Enter your Car registration date"}
            name="regDateCopy"
            btnId="policyRegDateBtn"
            textId="policyRegDateTxt"
            imgId="policyRegDateImg"
            revealState={
              ShowRegDateCalendarModal
                ? ShowRegDateCalendar

                : ShowRegDateCalendar
            }
            setRevealState={
              ShowRegDateCalendarModal
                ? setShowRegDateCalendarModal
                : setShowRegDateCalendar
            }
            handleInputCarDetails={handleInputCarDetails}
            handleCopiedDetails={
              ShowRegDateCalendarModal ? handleModalDates : handleCopiedDetails
            }
            cancelCalendarValue={
              ShowRegDateCalendarModal
                ? cancelCalendarValueModal
                : cancelCalendarValue
            }
            saveCalendarValue={
              ShowRegDateCalendarModal
                ? saveCalendarValueModal
                : saveCalendarValue
            }
            maxDate={businessType == NEW ? new Date(maxRegDate) : new Date()}
            minDate={businessType == NEW ? new Date(minRegDate) : new Date(1980)}
          />
        )}

        {/* od exp date calendar */}

        {(ShowOdExpDateCalendar || ShowOdExpDateCalendarModal) && (
          <CustomCalendar
            value={
              ShowOdExpDateCalendarModal
                ? OdExpDateClone
                : carDetailsCopyState?.odPolicyExpCopy
            }
            headText="Enter Own Damage Expiry date"
            name="odPolicyExpCopy"
            btnId="policyOdExpDateBtn"
            textId="policyOdExpDateTxt"
            imgId="policyOdExpDateImg"
            revealState={
              ShowOdExpDateCalendarModal
                ? ShowOdExpDateCalendarModal
                : ShowOdExpDateCalendar
            }
            setRevealState={
              ShowOdExpDateCalendarModal
                ? setShowOdExpDateCalendarModal
                : setShowOdExpDateCalendar
            }
            handleInputCarDetails={handleInputCarDetails}
            handleCopiedDetails={
              ShowOdExpDateCalendarModal
                ? handleModalDates
                : handleCopiedDetails
            }
            cancelCalendarValue={
              ShowOdExpDateCalendarModal
                ? cancelCalendarValueModal
                : cancelCalendarValue
            }
            saveCalendarValue={
              ShowOdExpDateCalendarModal
                ? saveCalendarValueModal
                : saveCalendarValue
            }
            maxDate={
              carDetails?.prevPolicyType == "Bundled" ? MaxDateValue : ""
            }
            minDate={
              carDetails?.prevPolicyType == "Bundled" ? MinDateValue : ""
            }
          />
        )}

        {/* expiry date calendar */}

        {(ShowExpDateCalendar || ShowExpDateCalendarModal) && (
          <CustomCalendar
            value={
              ShowExpDateCalendarModal
                ? ExpDateClone
                : carDetailsCopyState?.policyExpCopy
            }
            headText="Enter Previous Policy Expiry date"
            name="policyExpCopy"
            btnId="policyExpDateBtn"
            textId="policyExpDateTxt"
            imgId="policyExpDateImg"
            revealState={
              ShowExpDateCalendarModal
                ? ShowExpDateCalendarModal
                : ShowExpDateCalendar
            }
            setRevealState={
              ShowExpDateCalendarModal
                ? setShowExpDateCalendarModal
                : setShowExpDateCalendar
            }
            handleInputCarDetails={handleInputCarDetails}
            handleCopiedDetails={
              ShowExpDateCalendarModal ? handleModalDates : handleCopiedDetails
            }
            cancelCalendarValue={
              ShowExpDateCalendarModal
                ? cancelCalendarValueModal
                : cancelCalendarValue
            }
            saveCalendarValue={
              ShowExpDateCalendarModal
                ? saveCalendarValueModal
                : saveCalendarValue
            }
            maxDate={
              carDetails?.prevPolicyType !== "Bundled" ? MaxDateValue : ""
            }
            minDate={
              carDetails?.prevPolicyType !== "Bundled" ? MinDateValue : MinDateValue
            }
          />
        )}

        {/* flow_exp_date */}

        {ShowFlowExpCalendar && (
          <CustomCalendar
            value={carDetailsCopyState?.policyExpCopy}
            headText="Select Previous Policy Expiry date"
            name="policyExpCopy"
            btnId="policyExpDateBtn"
            textId="policyExpDateTxt"
            imgId="policyExpDateImg"
            revealState={ShowFlowExpCalendar}
            setRevealState={setShowFlowExpCalendar}
            handleInputCarDetails={handleInputCarDetails}
            handleCopiedDetails={handleCopiedDetails}
            cancelCalendarValue={cancelCalendarValueFlow}
            saveCalendarValue={saveCalendarValueExpFlow}
            preventhandleClickOutside={true}
            calendarType="expDate"
            ShowAlternateExpOptions={ShowAlternateExpOptions}
            setShowAlternateExpOptions={setShowAlternateExpOptions}
            getAlternateExpOption={getAlternateExpOption}
            maxDate={
              carDetails?.prevPolicyType !== "Bundled" ? MaxDateValue : ""
            }
            minDate={
              carDetails?.prevPolicyType !== "Bundled" ? MinDateValue : ""
            }
          />
        )}

        {/* flow_od_date */}

        {ShowFlowOdCalendar && (
          <CustomCalendar
            value={carDetailsCopyState?.odPolicyExpCopy}
            headText="Select Own Damage Expiry date"
            name="odPolicyExpCopy"
            btnId="policyOdExpDateBtn"
            textId="policyOdExpDateTxt"
            imgId="policyOdExpDateImg"
            revealState={ShowFlowOdCalendar}
            setRevealState={setShowFlowOdCalendar}
            handleInputCarDetails={handleInputCarDetails}
            handleCopiedDetails={handleCopiedDetails}
            cancelCalendarValue={cancelCalendarValueFlow}
            saveCalendarValue={saveCalendarValueExpFlow}
            preventhandleClickOutside={true}
            calendarType="expDateOd"
            ShowAlternateExpOptions={ShowAlternateExpOptions}
            setShowAlternateExpOptions={setShowAlternateExpOptions}
            getAlternateExpOption={getAlternateExpOption}
            maxDate={MaxDateValue}
            minDate={MinDateValue}
          />
        )}

        {/* MOBILE -------- ADAPTIVE ------------------------------------------------------------------------------------------------------------------------------------------ */}

        <div className="mobile-top-content-container">
          <div className="leftSide_modal-mobile leftSide_modal-mobile-vp">
            <div className="leftSide_modal-mobile-mmv-container">
              <div className="head-mmv-modal">
                <p className="leftSide_modal-mm">
                  {`${carDetails?.make || ""} ${carDetails?.model || ""}`}
                </p>
                <Botton
                  icons={edit}
                  text="Edit"
                  event={(e) => setShowMmvVerifyTab(!ShowMmvVerifyTab)}
                  className="edit_btn"
                />
              </div>
              <p className="leftSide_modal-CarNum">{carDetails?.vehicleNumber ? carDetails?.vehicleNumber : storageVehicleNumber}</p>
              <div className="leftSide_modal-vfr">
                <p>
                  {`${carDetails?.varient || ""}`} <span>,</span> {`${carDetails?.fuelType || ""}`} <span>,</span> {`${formattedRegDate || ""}`}
                </p>
              </div>
            </div>
          </div>

          <div className="idv_container_tab_mob" onClick={
            carDetails?.PolicyTypeValue !== "Third Party" && allIDVValues.min !== "Infinity"
              ? (e) => setShowIdvTab(!ShowIdvTab)
              : console.log("Not Applicable")
          } >
            <p>Cover Value IDV :
              <span>
                {carDetails?.PolicyTypeValue !== "Third Party" ? (
                  <>
                    {carDetails?.idvValue
                      ? Math.round(carDetails?.idvValue)?.toLocaleString("en-IN")
                      : allIDVValues.min == Infinity
                        ? <SkeletonLoader width={100} />
                        : Math.round(allIDVValues.min)?.toLocaleString("en-IN")}
                  </>
                ) : ("Not Applicable")}
              </span>
            </p>
            <img src={rightArrow} />
          </div>

          <div className="policyTypeOption-container policyTypeOption-container-mob">
            {policyTypeOption.map((item, key) => (
              <Botton
                key={key}
                text={item.value}
                className={
                  carDetails?.PolicyTypeValue == item.value
                    ? "accordian_button active_accordian_btn"
                    : "accordian_button accordian_button_policyType"
                }
                value={item.value}
                name="PolicyTypeValue"
                event={handleInputCarDetails}
              />
            ))}
          </div>
        </div>

        <section className="plansContainer_viewPlan">
          <div className="plansContainer_viewPlan_parent maxWidth ">
            <div className="bottomContent_container_vp">
              <div className="planSelectorContainer">
                <IdvTab
                  idvValue={carDetailsCopyState.idvValueCopy}
                  minIdvValue={
                    allIDVValues.min == Infinity ? 0 : allIDVValues.min
                  }
                  maxIdvValue={allIDVValues.max}
                  handleInputCarDetails={handleCopiedDetails}
                  IDVValues={allIDVValues}
                  handleIDVinput={handleIDVinput}
                  setcarDetailsCopyState={setcarDetailsCopyState}
                  setCarDetails={setCarDetails}
                  idv={carDetails?.idvValue}
                  isDisabled={
                    carDetails?.PolicyTypeValue === "Third Party" ? true : false
                  }
                  showModal="true"
                />
                <div className="policyTypeOption-container">
                  {policyTypeOption.map((item, key) => (
                    <Botton
                      key={key}
                      text={item.value}
                      className={
                        carDetails?.PolicyTypeValue == item.value
                          ? "accordian_button active_accordian_btn"
                          : "accordian_button"
                      }
                      value={item.value}
                      name="PolicyTypeValue"
                      event={handleInputCarDetails}
                    />
                  ))}
                </div>
              </div>
              <div className="bottomContent_header_vp_two">
                <div className="section_bottomContent_vp section_bottomContent_vpOne">
                  <div
                    className={
                      showInsDropDown
                        ? " addonContainer_vp-position  addonContainer_vp"
                        : "addonContainer_vp"
                    }
                  >
                    <p className="addonContainer_vp_title">
                      Add extra coverage
                    </p>
                    <AddonContainer
                      changeEvent={(e) => handleCheckboxChange(e)}
                      dataList={AddonsList}
                      ShowAccesoriesField={ShowAccesoriesField}
                      handleAccesoriesFields={(e) =>
                        setShowAccesoriesField(!ShowAccesoriesField)
                      }
                      ShowPcSumInsuredField={ShowPcSumInsuredField}
                      handleSumInsuredFields={(e) =>
                        setShowPcSumInsuredField(!ShowPcSumInsuredField)
                      }
                      handleInputCarDetails={handleInputCarDetails}
                      handleApplyClick={handleApplyClick}
                      handleCancelPassenger={handleCancelPassenger}
                      cancelAccessoriesField={cancelAccessoriesField}
                      errors={errors}
                      handleCopiedDetails={handleCopiedDetails}
                      carDetails={carDetails}
                      setElectricalAmt={setElectricalAmt}
                      setNonElectricalAmt={setNonElectricalAmt}
                      ElectricalAmt={ElectricalAmt}
                      NonElectricalAmt={NonElectricalAmt}
                      PassengerCoverAmt={PassengerCoverAmt}
                      setPassengerCoverAmt={setPassengerCoverAmt}
                      isMobileMode={false}
                      ShowNilDepModal={ShowNilDepModal}
                      setShowRtiModal={setShowRtiModal}
                      ShowRtiModal={ShowRtiModal}
                      ShowEngProtectModal={ShowEngProtectModal}
                      setShowNilDepModal={setShowNilDepModal}
                      setShowEngProtectModal={setShowEngProtectModal}
                      setShowPackageModal={setShowPackageModal}
                      setShowDigPackageModal={setShowDigPackageModal}
                      setShowICICIPackageModal={setShowICICIPackageModal}
                      setIsActiveFromPlanCard={setIsActiveFromPlanCard}
                      IsActiveFromPlanCard={IsActiveFromPlanCard}
                      setCarDetails={setCarDetails}
                    />
                  </div>
                </div>
                <div className="section_bottomContent_vp section_bottomContent_vpTwo">
                  <QuotesContainer
                    businessType={businessType}
                    vehicleData={vehicleData}
                    PolicyPlanType={carDetails?.PolicyTypeValue}
                    carDetails={{
                      ...carDetails,
                      ...getCalculatedDate,
                      ...{ coverage: [test] },
                    }}
                    handleInputCarDetails={handleInputCarDetails}
                    ShowPcSumInsuredField={ShowPcSumInsuredField}
                    ShowAccesoriesField={ShowAccesoriesField}
                    isFindMyCar={isFindMyCar}
                    setShowBottomTab={setShowBottomTab}
                    policyTypeOption={policyTypeOption}
                    passVehicleData={vehicleData}
                    policyTypeList={policyTypeList}
                    setLoaderFromConfirmPg={setLoaderFromConfirmPg}
                    CompanyWiseLoader={CompanyWiseLoader}
                    ShowExpDateCalendar={ShowExpDateCalendarModal}
                    setShowExpDateCalendar={setShowExpDateCalendarModal}
                    ShowRegDateCalendar={ShowRegDateCalendarModal}
                    CalendarDeactiveFlag={CalendarDeactiveFlag}
                    setShowRegDateCalendar={setShowRegDateCalendarModal}
                    ShowOdExpDateCalendar={ShowOdExpDateCalendar}
                    setShowOdExpDateCalendar={setShowOdExpDateCalendar}
                    ShowSkeleton={ShowSkeleton}
                    test={test}
                    setDigitAddon={setDigitAddon}
                    DigitAddon={DigitAddon}
                    objWihtAddOn={obj}
                    DigitPlan={DigitPlan}
                    ICICIPlan={ICICIPlan}
                    setCarDetails={setCarDetails}
                    carDetailsCopyState={carDetailsCopyState}
                    setcarDetailsCopyState={setcarDetailsCopyState}
                    ShowPackageModal={ShowPackageModal}
                    ShowDigPackageModal={ShowDigPackageModal}
                    setShowDigPackageModal={setShowDigPackageModal}
                    setShowICICIPackageModal={setShowICICIPackageModal}
                    ShowICICIPackageModal={ShowICICIPackageModal}
                    setShowPackageModal={setShowPackageModal}
                    setShowNilDepModal={setShowNilDepModal}
                    setIsActiveFromPlanCard={setIsActiveFromPlanCard}
                    digitAddonsplan={digitAddonsplan}
                    setShowRtiModal={setShowRtiModal}
                    setIsRegDateActive={setIsRegDateActive}
                    NoRespforCom={NoRespforCom}
                    setNoREspforCom={setNoREspforCom}
                    // stopApi={stopApi}
                    handleCopiedDetails={handleCopiedDetails}
                    allIDVValues={allIDVValues}
                    formattedRegDate={formattedRegDate}
                    formattedODexpDate={formattedODexpDate}
                    upDateCarDetailsConfirm={upDateCarDetailsConfirm}
                    formattedpolicyExpDate={formattedpolicyExpDate}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* SELECT PREVIOUS POLICY TYPE */}

        {PrevPolicyTypeList && (
          <div
            className="edit-tabs-container-mob-height"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            <div className="active-bottom-edit-policy-tabs">
              <div className="edit-tabs-container">
                <div className="edit-tabs-container-head">
                  <p>Select your previous policy type</p>
                  <img
                    src={close}
                    alt=""
                    srcset=""
                    onClick={(e) =>
                      cancelPrevPolicySection("prevPolicyTypeTab")
                    }
                  />
                </div>
                <div className="policy-type-list-container-mob">
                  {policyTypeList?.map((data, key) => (
                    <div
                      key={key}
                      className={
                        carDetailsCopyState.prevPolicyTypeCopy === data.value
                          ? "pol-tab-rect pol-tab-rect-active"
                          : "pol-tab-rect"
                      }
                      onClick={(e) => setcarDetailsCopyState({ ...carDetailsCopyState, prevPolicyTypeCopy: data.value })}
                    >
                      <div className="fake-radio-tab-mobile">
                        <div className="circle-radio-mob">
                          <div
                            className={
                              carDetailsCopyState.prevPolicyTypeCopy ===
                                data.value
                                ? "circle-radio-mob-child circle-radio-mob-child-active"
                                : "circle-radio-mob-child"
                            }
                          ></div>
                        </div>
                        <p className="radio-label-mobile-clone">
                          {data.label === "Bundled (3Y TP + 1Y OD)"
                            ? "Bundled"
                            : data.label}
                        </p>
                      </div>
                      <p className="radio-label-mobile-clone radio-label-mobile-clone-desc">
                        {data.desc}
                      </p>
                    </div>
                  ))}
                  <section className="btnContainer_pform btnContainer_pform-edit">
                    <Botton
                      text="Cancel"
                      className="button btn_pForm  secondry_btn"
                      event={(e) => navigate(-1)}
                    />
                    <Botton
                      text="Save"
                      className="button btn_pForm"
                      event={(e) => {
                        savePrevPolicySection("prevPolicyTypeTab");
                      }}
                    />
                  </section>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* VERIFY CAR AND POLICY DETAILS */}

        {ShowMmvVerifyTab && (
          <div
            className="edit-tabs-container-mob-height"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            <div className="active-bottom-edit-IDV-tabs active-bottom-edit-mmv ">
              <div className="edit-tabs-container">
                <div className="edit-tabs-container-head edit-tabs-container-head-bg">
                  <p>Verify Your Car and Policy Details</p>
                  <img src={close} onClick={() => handleEditModal("close")} />
                </div>
                <p className="mmv-title-vp" >Car Details</p>
                {
                  carDetailsContent?.map((data, key) => (
                    <div key={key} className={carDetailsContent.length === data.id
                      ? "verify-tab-cd verify-tab-cd-last"
                      : "verify-tab-cd"}>
                      <div className="verify-tab-cd-child">
                        <p> {data.heading} </p>
                        <p> {data.value} </p>
                      </div>
                      <div className="verify-tab-cd-child">
                        <p> {data.headingTwo} </p>
                        <p> {data.valueTwo} </p>
                      </div>
                    </div>
                  ))
                }
                <div className="btn-vd-container">
                  <Botton
                    className="btn-verifyDetails-p btn-vd"
                    text="Edit Car Details"
                    event={() => handleEditModal("carDetails")}
                  />
                </div>

                {
                  businessType !== "New" ? (
                    <>
                      <p className="mmv-title-vp" style={{ marginTop: "1rem" }} >Previous Policy Details</p>
                      {
                        prevPolicyContent?.map((data, key) => (
                          <div key={key} className={prevPolicyContent?.length === data.id
                            ? "verify-tab-cd verify-tab-cd-last"
                            : "verify-tab-cd"}>
                            <div className="verify-tab-cd-child">
                              <p> {data.heading} </p>
                              <p> {data.value} </p>
                            </div>
                            <div className="verify-tab-cd-child">
                              <p> {data.headingTwo} </p>
                              <p> {data.valueTwo} </p>
                            </div>
                          </div>
                        ))
                      }
                      <div className="btn-vd-container">
                        <Botton
                          className="btn-verifyDetails-p btn-vd"
                          text="Edit Policy Details"
                          event={() => handleEditModal("prevPolicyDetails")}
                        />
                      </div>
                    </>
                  ) : null
                }

              </div>
            </div>
          </div>
        )}

        {/* EDIT Vehicle DETAILS */}

        {EditMmvMobile && (
          <div
            className="edit-tabs-container-mob-height"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            {EditMmvMobile && (
              <div className="active-bottom-edit-tabs">
                <div className="edit-tabs-container">
                  <div className="edit-tabs-container-head">
                    <p> {RenderEditFields === "carDetails" ? "Edit my car details" : "Edit Previous Policy Details"}  </p>
                    <img
                      src={close}
                      alt=""
                      srcSet=""
                      onClick={() => handleEditModal("close")}
                    />
                  </div>
                </div>
                <EditDetails
                  renderContent={EditMmvMobile ? RenderEditFields : ""}
                  businessType={businessType}
                  HandleRegDate={(e) => handleInputCarDetails(e)}
                  closeModal={(e) => setEditMmvMobile(false)}
                  value={carDetails?.policyRegDate}
                  name="policyRegDate"
                  carDetails={{ ...carDetails, ...getCalculatedDate }}
                  handleInputCarDetails={handleInputCarDetails}
                  MakeList={MakeList}
                  FuelTypeList={FuelTypeList}
                  ccList={ccList}
                  ModelListArray={ModelListArray}
                  VarientList={VarientList}
                  setCarDetails={setCarDetails}
                  carDetailsCopyState={carDetailsCopyState}
                  handleCopiedDetails={handleCopiedDetails}
                  setcarDetailsCopyState={setcarDetailsCopyState}
                  handleMakeSelect={handleMakeSelect}
                  handleModelSelect={handleModelSelect}
                  handleFuelSelect={handleFuelSelect}
                  handleVariantSelect={handleVariantSelect}
                  handleCCSelect={handleCCSelect}
                  componentClass={viewPlansClassNameForPaper}
                  handleNcbValue={handleNcbValue}
                  switchFunctionsForMobile={EditMmvMobile ? true : false}
                  setShowRegDateCalendar={setShowRegDateCalendarModal}
                  ShowRegDateCalendar={ShowRegDateCalendarModal}
                  ShowOdExpDateCalendar={ShowOdExpDateCalendar}
                  setShowOdExpDateCalendar={setShowOdExpDateCalendar}
                  ShowExpDateCalendar={ShowExpDateCalendar}
                  setShowExpDateCalendar={setShowExpDateCalendar}
                  policyTypeList={policyTypeList}
                  setncbCopy={setncbCopy}
                  setncbData={setncbData}
                  setShowMmvVerifyTab={setShowMmvVerifyTab}
                />
              </div>
            )}
          </div>
        )}

        {/* EDIT POLICY DETAILS */}

        {EditPolicyTypeMobile && (
          <div
            className="edit-tabs-container-mob-height"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            {EditPolicyTypeMobile && (
              <div className="active-bottom-edit-policy-tabs">
                <div className="edit-tabs-container">
                  <div className="edit-tabs-container-head">
                    <p>Select New Policy Type</p>
                    <img
                      src={close}
                      onClick={(e) => setEditPolicyTypeMobile(false)}
                    />
                  </div>
                </div>

                <div className="policy-type-list-container-mob" style={{ padding: "0rem" }}>
                  {policyTypeOption?.map((data, key) => (
                    <div
                      key={key}
                      className={
                        carDetailsCopyState.policyTypeValueCopy === data.value
                          ? "pol-tab-rect pol-tab-rect-active"
                          : "pol-tab-rect"
                      }
                      onClick={(e) => setcarDetailsCopyState({ ...carDetailsCopyState, policyTypeValueCopy: data.value })}
                    >
                      <div className="fake-radio-tab-mobile">
                        <div className="circle-radio-mob">
                          <div
                            className={
                              carDetailsCopyState.policyTypeValueCopy ===
                                data.value
                                ? "circle-radio-mob-child circle-radio-mob-child-active"
                                : "circle-radio-mob-child"
                            }
                          ></div>
                        </div>
                        <p className="radio-label-mobile-clone">
                          {data.label === "Bundled (3Y TP + 1Y OD)"
                            ? "Bundled"
                            : data.label}
                        </p>
                      </div>
                      <p className="radio-label-mobile-clone radio-label-mobile-clone-desc">
                        {data.desc}
                      </p>
                    </div>
                  ))}
                  <section className="btnContainer_pform btnContainer_pform-edit">
                    <Botton
                      text="Cancel45645"
                      className="button btn_pForm  secondry_btn"
                      event={(e) =>
                        cancelPrevPolicySection("newPolicyType")
                      }
                    />
                    <Botton
                      text="Save"
                      className="button btn_pForm"
                      event={(e) => {
                        savePrevPolicySection("newPolicyType");
                      }}
                    />
                  </section>
                </div>
              </div>
            )}
          </div>
        )}

        {/* EDIT IDV DETAILS */}

        {ShowIdvTab && (
          <div
            className="edit-tabs-container-mob-height"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            {ShowIdvTab && (
              <div className="active-bottom-edit-IDV-tabs mobile_idv_tab_height">
                <div className="edit-tabs-container">
                  <div className="edit-tabs-container-head edit-tabs-container-head-bg">
                    <p>Car insured value (IDV)</p>
                    <p>What the insurer pays in case of total damage/theft</p>
                  </div>
                  <IdvTab
                    idvValue={carDetailsCopyState.idvValueCopy}
                    minIdvValue={
                      allIDVValues.min == Infinity ? 0 : allIDVValues.min
                    }
                    maxIdvValue={allIDVValues.max}
                    handleInputCarDetails={handleCopiedDetails}
                    IDVValues={allIDVValues}
                    handleIDVinput={handleIDVinput}
                    setcarDetailsCopyState={setcarDetailsCopyState}
                    setCarDetails={setCarDetails}
                    idv={carDetails?.idvValue}
                    isDisabled={
                      carDetails?.PolicyTypeValue === "Third Party" ? true : false
                    }
                    showModal="false"
                    setShowIdvTab={setShowIdvTab}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        {/* ADDONS FOR MOBILE */}

        {!ShowPcSumInsuredField && !ShowAccesoriesField && !ShowNilDepModal && !ShowEngProtectModal && !ShowRtiModal ? (
          <>
            {AddonsForMobile && (
              <div
                className="edit-tabs-container-mob-height"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <div
                  className={
                    AddonsForMobile
                      ? carDetails?.PolicyTypeValue === "Third Party"
                        ? "bottom-edit-tabs active-addons-bottom-edit-tabs-tp"
                        : "bottom-edit-tabs active-addons-bottom-edit-tabs"
                      : "bottom-edit-tabs addons-bottom-edit"
                  }
                >
                  <div className="edit-tabs-container">
                    <div className="edit-tabs-container-head">
                      <p>Add extra Coverage</p>
                      <img
                        src={close}
                        onClick={(e) => showAddonsForMobile("hide")}
                      />
                    </div>
                  </div>
                  <AddonContainer
                    changeEvent={(e) => handleCheckboxChange(e)}
                    dataList={AddonsList}
                    ShowAccesoriesField={ShowAccesoriesField}
                    handleAccesoriesFields={(e) =>
                      setShowAccesoriesField(!ShowAccesoriesField)
                    }
                    ShowPcSumInsuredField={ShowPcSumInsuredField}
                    handleSumInsuredFields={(e) =>
                      setShowPcSumInsuredField(!ShowPcSumInsuredField)
                    }
                    handleInputCarDetails={handleInputCarDetails}
                    handleApplyClick={handleApplyClick}
                    handleCancelPassenger={handleCancelPassenger}
                    cancelAccessoriesField={cancelAccessoriesField}
                    errors={errors}
                    handleCopiedDetails={handleCopiedDetails}
                    carDetails={carDetails}
                    setElectricalAmt={setElectricalAmt}
                    setNonElectricalAmt={setNonElectricalAmt}
                    ElectricalAmt={ElectricalAmt}
                    NonElectricalAmt={NonElectricalAmt}
                    PassengerCoverAmt={PassengerCoverAmt}
                    setPassengerCoverAmt={setPassengerCoverAmt}
                    isMobileMode={true}
                    newCheckBoxData={newCheckBoxData}
                    handleCheckboxChange={handleCheckboxChange}
                    AddonsForMobile={AddonsForMobile}
                    updateCoveragesClone={updateCoveragesClone}
                    setAddonsForMobile={setAddonsForMobile}
                    setShowPackageModal={setShowPackageModal}
                    setCarDetails={setCarDetails}
                    showAddonsForMobile={showAddonsForMobile}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          ""
        )}

        {/* EDIT NCB DETAILS */}

        {EditNcbMobile && (
          <div
            className="edit-tabs-container-mob-height"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            {EditNcbMobile && (
              <div
                className={
                  EditNcbMobile
                    ? "bottom-edit-tabs active-bottom-edit-ncb-tabs"
                    : "bottom-edit-tabs"
                }
              >
                <div className="edit-tabs-container">
                  <div className="edit-tabs-container-head edit-tabs-container-head-ncb">
                    <p>Edit my (NCB) No Claim Bonus details</p>
                    <img
                      src={close}
                      alt=""
                      srcSet=""
                      onClick={(e) => setEditNcbMobile(false)}
                    />
                  </div>
                  <EditDetails
                    businessType={businessType}
                    isClaimPrevPolicy={carDetails?.isClaimPrevPolicy}
                    renderContent={EditNcbMobile ? "editClaimDetails" : ""}
                    classNameDate="viewPlan_paper_child_title"
                    componentClass={viewPlansClassNameForPaper}
                    handleInputCarDetails={handleInputCarDetails}
                    carDetails={{ ...carDetails, ...getCalculatedDate }}
                    setCarDetails={setCarDetails}
                    carDetailsCopyState={carDetailsCopyState}
                    handleCopiedDetails={handleCopiedDetails}
                    handleMakeSelect={handleMakeSelect}
                    setcarDetailsCopyState={setcarDetailsCopyState}
                    closeModal={(e) => setEditNcbMobile(false)}
                    switchFunctionsForMobile={EditNcbMobile ? true : false}
                    handleNcbValue={handleNcbValue}
                    hideNcbTitle={true}
                    isNcbFlowPopUp={true}
                    setncbCopy={setncbCopy}
                    setncbData={setncbData}
                  // setstopApi={setstopApi}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        {/* BOTTOM FIXED TAB */}

        {!ShowPcSumInsuredField && !ShowAccesoriesField ? (
          <>
            {ShowBottomTab && (
              <div className="bottom-tab-sticky-container">
                <div className="bottom-tab-sticky">
                  <div className="mobile-top-content-mmv-container mobile-top-content-mmv-container-copy-two mobile-top-content-mmv-container-copy">
                    <Botton
                      text="Add Addon"
                      className="button vp-btn-button"
                      event={() => showAddonsForMobile("show")}
                    />
                    <Botton
                      text="Back"
                      className="secondry_btn vp-btn-button"
                      event={() => navigate("/Find-My-Car")}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          ""
        )}

        {/* MOBILE ---- ENDING ---- ADAPTIVE --------------------------------------------------------------------------------------------------------------------------------- */}

        {/* PARTNERS SECTION */}

        <Partners
          firstLot={firstLot}
          secondLot={secondLot}
          component="viewPlans"
        />

        {/* FAQ */}

        <Accordian
          carQueries={carQueries}
          bikeQueries={bikeQueries}
          healthQueries={healthQueries}
          taxiQueries={taxiQueries}
        />

        {/* FOOTER */}

        <Footer />
      </div>
    </>
  );
};

// const greetingTitleInSpan = "We got all the best Plans for you.";

const hyperLinks = [
  {
    head: "I need help.",
    desc: "Request a call from Expert.",
  },
  {
    head: "OR Call us.",
    desc: "9811223458",
  },
];

const viewPlansClassNameForPaper = [
  {
    class1: "viewPlan_paper_container",
    class2: "viewPlan_paper_head",
    class3: "viewPlan_paper_head_txt",
    class4: "viewPlan_paper_child_container",
    class5: "viewPlan_paper_child",
    class6: "viewPlan_paper_child_head_title",
    class7: "viewPlan_paper_child_head",
    class8: "viewPlan_paper_child_title",
  },
];

export default ViewPlans;