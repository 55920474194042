import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import digit from "../../Assets/Partners_logo/BOOKMYINSURANCE/digit.jpg";
import { NEW, bikeDetailsforProposal } from "../../utils/commonUtilsFinal";
import { SBI, RAHEJA, SHREERAM, ZUNO, DIGIT, ICICI, ACKO, TATA, HDFC, NATIONAL } from "../../utils/commonUtils";
import zuno from "../../Assets/Partners_logo/zuno.png";
import shreeRam from "../../Assets/Partners_logo/shriram.png";
import icici from "../../Assets/Partners_logo/icici lombard.svg";
import hdfc from "../../Assets/Partners_logo/hdfc.png";
import acko from "../../Assets/Partners_logo/acko.svg";
import tata from "../../Assets/Partners_logo/TATA AIG.png";
import sbi from "../../Assets/Partners_logo/SBI.png";
import national from "../../Assets/Partners_logo/BOOKMYINSURANCE/national.jpg";

const PremiumContainerBike = (props) => {
  const {
    CompanyImage,
    CompanyName,
    PolicyTypeValue,
    mappingAllData,
    setZeroDepValue
  } = props;

  let companyImg = ''
  const [ShowBreakupMobile, setShowBreakupMobile] = useState(false)
  const [ShowOdBreakup, setShowOdBreakup] = useState(false);
  const [ShowTpBreakup, setShowTpBreakup] = useState(false);
  const [ShowAdaptiveBlock, setShowAdaptiveBlock] = useState(false);

  const getSbiQuickQuoteData = useSelector((state) => {
    return state?.sbiQuickQuote;
  });

  const shreeRamQuote = useSelector((state) => {
    return state?.shreeRamQuickQuote;
  });

  const digitQuote = useSelector((state) => {
    return state?.digitQuickQuote;
  });

  const iciciQuote = useSelector((state) => {
    return state?.iciciQuickQuote;
  });

  const hdfcQuote = useSelector((state) => {
    return state?.hdfcQuickQuote;
  });

  const ackoQuote = useSelector((state) => {
    return state?.ackoQuickQuote;
  });

  //tata
  const tataQuote = useSelector((state) => {
    return state?.tataQuickQuote;
  });

  // ZUNO
  const zunoQQuote = useSelector((state) => {
    return state?.zunoQuickQuote;
  });

  //national
  const nationalQQuote = useSelector((state) => {
    return state?.nationalQuickQuote;
  });

  const bodyData = useSelector((state) => {
    return state.bodyData;
  });

  let quoteData = {};

  if (CompanyName == SBI) {
    companyImg = sbi
    quoteData = getSbiQuickQuoteData;
  } else if (CompanyName == SHREERAM) {
    companyImg = shreeRam
    quoteData = shreeRamQuote;
  } else if (CompanyName == DIGIT) {
    companyImg = digit
    quoteData = digitQuote;
  } else if (CompanyName == ICICI) {
    companyImg = icici
    quoteData = iciciQuote
  } else if (CompanyName == HDFC) {
    companyImg = hdfc
    quoteData = hdfcQuote
  } else if (CompanyName == ACKO) {
    companyImg = acko
    quoteData = ackoQuote
  } else if (CompanyName == TATA) {
    companyImg = tata
    quoteData = tataQuote
  } else if (CompanyName == ZUNO) {
    companyImg = zuno
    quoteData = zunoQQuote
  } else if (CompanyName == NATIONAL) {
    companyImg = national
    quoteData = nationalQQuote
  }
  let test = []
  let vehicleStructureDetails = bikeDetailsforProposal(
    CompanyName,
    quoteData,
    bodyData,
    test,
    mappingAllData
  );


  useEffect(() => {
    if (setZeroDepValue) {
      setZeroDepValue(vehicleStructureDetails?.zeroDep || "0")
    }
  }, [vehicleStructureDetails]);

  localStorage.setItem('duePremium', vehicleStructureDetails?.DuePremium);

  function formatDate(date) {
    date = new Date(date)
    if(CompanyName=="hdfc" && mappingAllData[0].isPrevPolicyExp === true){
      date.setDate(date.getDate() + 1); // Add one day
       }
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    return `${day} ${months[month]} ${year}`;
  }

  function generatePolicyDate(previousDate, typeOfBusiness) {
    function calculateOneYearLater(date) {
      const newDate = new Date(date);
      if (typeOfBusiness == "New") {
        newDate.setFullYear(newDate.getFullYear() + 3);
      } else {
        newDate.setFullYear(newDate.getFullYear() + 1);
      }
      newDate.setDate(newDate.getDate() - 1);
      return newDate;
    }
    let previousDateFormatted = previousDate.split("/").reverse().join("-")
    const today = new Date(String(previousDateFormatted));
    const tomorrow = new Date(today);
    if (typeOfBusiness != "New") {
      tomorrow.setDate(tomorrow.getDate() + 1);
    }
    const formattedToday = formatDate(tomorrow);
    const oneYearLater = calculateOneYearLater(tomorrow);
    const formattedOneYearLater = formatDate(oneYearLater);
    return { formattedToday, formattedOneYearLater };
  }

  let policyDateObj;

  if (mappingAllData[0].typeOfBusiness !== NEW) {
    if (mappingAllData[0].PolicyTypeValue !== "Own Damage") {
      if (mappingAllData[0].policyExpDate < new Date()) {
        policyDateObj = generatePolicyDate(new Date().toISOString(), mappingAllData[0].typeOfBusiness);
      } else {
        policyDateObj = generatePolicyDate(String(mappingAllData[0].policyExpDate), mappingAllData[0].typeOfBusiness);
      }
    } else {
      if (mappingAllData[0]?.prevPolicyODexpDate < new Date()) {
        policyDateObj = generatePolicyDate(new Date().toISOString(), mappingAllData[0].typeOfBusiness);
      } else {
        policyDateObj = generatePolicyDate(String(mappingAllData[0]?.prevPolicyODexpDate), mappingAllData[0].typeOfBusiness);
      }
    }
  } else {
    policyDateObj = generatePolicyDate(new Date().toISOString(), mappingAllData[0].typeOfBusiness);
  }

  let newPolicyStartDate = formatDate(mappingAllData[0]?.policyRegDate)
  let expPolicyStartDate = formatDate(new Date);

  return (
    <div className="premiumContainer">

      {/* MMV AND POLICY DETAILS */}

      <div className="pol-details-premiumContainer">
        <div className="car-details-premContainer">
          <p>{mappingAllData[0].PolicyTypeValue}</p>
          <p>{(mappingAllData[0]?.vehicleNumber)?.toUpperCase()}</p>
        </div>
        <div className="mmv_premiumContainer">
          <p>{vehicleStructureDetails?.make || ""}</p>
          <p>{`${vehicleStructureDetails?.model || ""} ${vehicleStructureDetails?.varient || ""
            } (${vehicleStructureDetails?.cc || ""} cc)`}</p>
        </div>
        <div className="policy-det-premContent">
          <div className="policy-det-premContent-block">
            <p>Policy Start Date</p>
            {
              (mappingAllData[0].typeOfBusiness === "New") ?
                (
                  <p>
                    {newPolicyStartDate}
                  </p>
                ) :
                (
                  <p>
                    {mappingAllData[0].isPrevPolicyExp === true ? expPolicyStartDate : policyDateObj?.formattedToday}
                  </p>
                )
            }
          </div>
          <div className="policy-det-premContent-block">
            <p>New NCB</p>
            <p>
              {
                (mappingAllData[0].newNcb != 0 && mappingAllData[0].typeOfBusiness !== "New" && mappingAllData[0].isClaimPrevPolicy !== true) ? (
                  <>
                    {mappingAllData[0].newNcb} %
                  </>
                ) : (
                  <>
                    Not Applicable
                  </>
                )
              }
            </p>
          </div>
        </div>
      </div>

      {/* COMPANY DETAILS */}

      <div className="amountContainer">
        <img src={companyImg} alt="" srcSet="" />
        <div className="amt_prem">
          <p>₹ {Math.round(Number(vehicleStructureDetails?.netPayble))?.toLocaleString('en-IN') || ""}</p>
          <p>Price exclusive of GST</p>
        </div>
      </div>

      {/* *************ADAPTIVE BREAKUP FOR MOBILE************ */}

      {/* IDV - TAB */}

      <div className={ShowAdaptiveBlock ? "adaptive-breakup-proposal active-adaptive-breakup-proposal " : "adaptive-breakup-proposal"}>
        {PolicyTypeValue?.toUpperCase() !== "Third Party".toUpperCase() ?
          <div className="breakup_headValue bold-content ">
            <p>Cover Value(IDV):</p>
            <p>₹ {Math.round(vehicleStructureDetails?.idv).toLocaleString('en-IN') || ""}</p>
          </div> : ""}

        {/* ***********************OD ADDONS SECTION********************* */}

        {
          PolicyTypeValue?.toUpperCase() !== "Third Party".toUpperCase() && (
            <div className="breakup-container">
              {PolicyTypeValue?.toUpperCase() !== "Third Party".toUpperCase() ?
                <div className="breakup_headValue breakup_headValue-bold">
                  <p>Total Own Damage (A)</p>
                  <p>
                    ₹ {(CompanyName == DIGIT) ? (Math.round(vehicleStructureDetails?.OD_TotalPremium - vehicleStructureDetails?.OD_NCBAmount).toLocaleString('en-IN')) : Math.round(vehicleStructureDetails?.OD_TotalPremium).toLocaleString('en-IN') || "0"}
                  </p>
                </div>
                : ""}
              <div className={ShowOdBreakup ? "breakup-container-od breakup-container-od-extended" : "breakup-container-od"} >
                {PolicyTypeValue?.toUpperCase() !== "Third Party".toUpperCase() ?
                  <div className="breakup_headValue">
                    <p>Basic Own Damage:</p>
                    <p>
                      ₹{" "}
                      {CompanyName == DIGIT ? (Math.round(vehicleStructureDetails?.OD_After_Disc).toLocaleString('en-IN')) : (Math.round(Number(vehicleStructureDetails?.OD_After_Disc) +
                        Number(vehicleStructureDetails?.OD_NCBAmount))?.toLocaleString('en-IN') || "")}
                    </p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.OD_NCBAmount ?
                  <div className="breakup_headValue">
                    <p>New NCB Discount:</p>
                    <p>₹ {Math.round(vehicleStructureDetails?.OD_NCBAmount)?.toLocaleString('en-IN')}</p>
                  </div> : ""}

                {PolicyTypeValue?.toUpperCase() !== "Third Party".toUpperCase() ?
                  <div className="breakup_headValue bold-content ">
                    <p>OD After NCB Discount:</p>
                    <p>₹ {(CompanyName == DIGIT) ? (Math.round(vehicleStructureDetails?.OD_After_Disc - vehicleStructureDetails?.OD_NCBAmount)?.toLocaleString('en-IN')) : (Math.round(Number(vehicleStructureDetails?.OD_After_Disc))?.toLocaleString('en-IN') || "")}</p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.zeroDep ?
                  <div className="breakup_headValue">
                    <p>Zero Dep:</p>
                    <p>₹{Math.round(vehicleStructureDetails?.zeroDep)?.toLocaleString('en-IN') || "0"}</p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.shreeRamProtect ?
                  <div className="breakup_headValue">
                    <p>Motor Protection:</p>
                    <p>₹{Math.round(vehicleStructureDetails?.shreeRamProtect)?.toLocaleString('en-IN') || "0"}</p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.roadAssistance ?
                  <div className="breakup_headValue">
                    <p>Road Side Assistance:</p>
                    <p>₹{Math.round(vehicleStructureDetails?.roadAssistance)?.toLocaleString('en-IN') || "0"}</p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.consumables ?
                  <div className="breakup_headValue">
                    <p>Consumables</p>
                    <p>₹{Math.round(vehicleStructureDetails?.consumables)?.toLocaleString('en-IN') || "0"}</p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.engineProtection ?
                  <div className="breakup_headValue">
                    <p>Engine Protection</p>
                    <p>₹{Math.round(vehicleStructureDetails?.engineProtection)?.toLocaleString('en-IN') || "0"}</p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.tyreProtection ?
                  <div className="breakup_headValue">
                    <p>Tyre Protection</p>
                    <p>₹{Math.round(vehicleStructureDetails?.tyreProtection)?.toLocaleString('en-IN') || "0"}</p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.invoiceCover ?
                  <div className="breakup_headValue">
                    <p>Return to Invoice</p>
                    <p>₹{Math.round(vehicleStructureDetails?.invoiceCover)?.toLocaleString('en-IN') || "0"}</p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.keyLockReplacement ?
                  <div className="breakup_headValue">
                    <p>Key & Lock Replacement</p>
                    <p>₹{Math.round(vehicleStructureDetails?.keyLockReplacement)?.toLocaleString('en-IN') || "0"}</p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.rsaKeyProtectCombo ?
                  <div className="breakup_headValue">
                    <p>RSA & Key Protect Combo</p>
                    <p>₹{Math.round(vehicleStructureDetails?.rsaKeyProtectCombo)?.toLocaleString('en-IN') || "0"}</p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.ncbProtection ?
                  <div className="breakup_headValue">
                    <p>NCB Protection</p>
                    <p>₹{Math.round(vehicleStructureDetails?.ncbProtection)?.toLocaleString('en-IN') || "0"}</p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.dailyAllowance ?
                  <div className="breakup_headValue">
                    <p>Daily Allowance</p>
                    <p>₹{Math.round(vehicleStructureDetails?.dailyAllowance)?.toLocaleString('en-IN') || "0"}</p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.personalBelogging ?
                  <div className="breakup_headValue">
                    <p>Loss of Personal Belogings</p>
                    <p>₹{Math.round(vehicleStructureDetails?.personalBelogging)?.toLocaleString('en-IN') || "0"}</p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.emiProtection ?
                  <div className="breakup_headValue">
                    <p>EMI Protection</p>
                    <p>₹{Math.round(vehicleStructureDetails?.emiProtection)?.toLocaleString('en-IN') || "0"}</p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.odExternalCngLpg ?
                  <div className="breakup_headValue">
                    <p>External Fitted CNG</p>
                    <p>(Included)</p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.odInternalCngLpg ?
                  <div className="breakup_headValue">
                    <p>CNG Fitted</p>
                    <p>(Included)</p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.accessories ?
                  <div className="breakup_headValue">
                    <p>Accessories</p>
                    <p>(Included)</p>
                  </div>
                  : ""}
              </div>
              <p
                className="view-breakup-btn"
                onClick={(e) => setShowOdBreakup(!ShowOdBreakup)}
              >
                {ShowOdBreakup ? "Hide Breakup" : "View Breakup"}
              </p>
            </div>
          )
        }

        {/* ***********************TP ADDONS SECTION********************* */}

        {
          PolicyTypeValue?.toUpperCase() !== "OWN DAMAGE" && (
            <div className="breakup-container">
              {PolicyTypeValue?.toUpperCase() !== "OWN DAMAGE" ?
                <div className="breakup_headValue breakup_headValue-bold">
                  <p>Total Third Party (B)</p>
                  <p> ₹ {Math.round(vehicleStructureDetails?.TP_TotalPremium)?.toLocaleString('en-IN')}</p>
                </div>
                : ""}

              <div className={ShowTpBreakup ? "breakup-container-od breakup-container-od-extended" : "breakup-container-od"}>
                {PolicyTypeValue?.toUpperCase() !== "OWN DAMAGE" ?
                  <div className="breakup_headValue">
                    <p>Basic Third Party:</p>
                    <p>₹ {Math.round(vehicleStructureDetails?.basicTP)?.toLocaleString('en-IN')}</p>
                  </div> : ""}

                {vehicleStructureDetails?.paOwnerDriver ?
                  <div className="breakup_headValue">
                    <p>PA Owner Driver Cover:</p>
                    <p>₹ {Math.round(vehicleStructureDetails?.paOwnerDriver)?.toLocaleString('en-IN')}</p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.driverCover ?
                  <div className="breakup_headValue">
                    <p>Driver Cover:</p>
                    <p>₹ {Math.round(vehicleStructureDetails?.driverCover)?.toLocaleString('en-IN')}</p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.llEmployee ?
                  <div className="breakup_headValue">
                    <p>Legal Libility To Employee:</p>
                    <p>₹ {Math.round(vehicleStructureDetails?.llEmployee)?.toLocaleString('en-IN')}</p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.tpCngLpg ?
                  <div className="breakup_headValue">
                    <p>CNG Fitted:</p>
                    <p>(Included)</p>
                  </div>
                  : ""}

                {vehicleStructureDetails?.passengerCover ?
                  <div className="breakup_headValue">
                    <p>Passenger Cover:</p>
                    <p>₹ {vehicleStructureDetails?.passengerCover}</p>
                  </div>
                  : ""}
              </div>

              <p
                className="view-breakup-btn"
                onClick={(e) => setShowTpBreakup(!ShowTpBreakup)}
              >
                {ShowTpBreakup ? "Hide Breakup" : "View Breakup"}
              </p>

            </div>
          )
        }

        {/* ***********************A + B SECTION************************* */}

        <div className="breakup-container">
          <div className="breakup_headValue breakup_headValue-bold breakup_headValue-bold-border">
            <p>Total Premium</p>
            <p></p>
          </div>
          <div className="breakup_headValue">
            <p>Net Payable: (A+B)</p>
            <p>₹ {Math.round(Number(vehicleStructureDetails?.netPayble))?.toLocaleString('en-IN')}</p>
          </div>
          <div className="breakup_headValue">
            <p>GST:</p>
            <p>₹ {Math.round(Number(vehicleStructureDetails?.TGST))?.toLocaleString('en-IN')}</p>
          </div>
          <div className="breakup_headValue gross-prem-block ">
            <p>Gross Premium:</p>
            <p> ₹ {Math.round(vehicleStructureDetails?.DuePremium)?.toLocaleString('en-IN')}</p>
          </div>
        </div>
      </div>

      <p
        className="view-breakup-btn view-breakup-btn-adaptive"
        onClick={(e) => setShowAdaptiveBlock(!ShowAdaptiveBlock)}
      >
        {ShowAdaptiveBlock ? "Hide Premium Breakup" : "View Premium Breakup"}
      </p>

    </div>
  );
};

export default PremiumContainerBike;
